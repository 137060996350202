﻿var BetSlipViews = {
    BetSlip: 1,
    MyBets: 2
};

var SetTimeOutConstants = {
    AwaitBeforeCheckingOpenBets: 5000
};

var GeoLocationErrors = {
    PERMISSION_DENIED: 1,
    POSITION_UNAVAILABLE: 2,
    TIMEOUT: 3
};

$.extend(window.BetSlip, (function () {
    var viewGen = 0;
    var Selections = [];
    var Modes = [];
    var Purchases = [];
    var CurrentMode = false;
    var MyBets = null;
    var activeView = BetSlipViews.BetSlip;
    var IsInConfigMode = false;
    var PushUpdateEvents = [];
    var OnAdd = BetSlip.OnAdd || {};
    var OnItemUpdate = BetSlip.OnItemUpdate || {};
    var OnBetSend = BetSlip.OnBetSend || {};
    var OnBetStateChanged = BetSlip.OnBetStateChanged || {};
    var OnDelete = BetSlip.OnDelete || {};
    var OnSelectionRemoved = BetSlip.OnSelectionRemoved || {};
    var OnOddsChanged = BetSlip.OnOddsChanged || {};
    var OnModeChanged = BetSlip.OnModeChanged || {};
    var OnBetInComboMarked = BetSlip.OnBetInComboMarked || {};
    var OnSelectionsUpdated = BetSlip.OnSelectionsUpdated || {};
    var OnViewUpdated = BetSlip.OnViewUpdated || {};
    var OnLineClosedUpdate = BetSlip.OnLineClosedUpdate || {};

    var OnDepositChanges = BetSlip.OnDepositChanges || {};
    var OnClear = BetSlip.OnClear || {};

    var OnPurchaseAdded = BetSlip.OnPurchaseAdded || {};
    var OnPurhaseRemoved = BetSlip.OnPurhaseRemoved || {};
    var OnPurchaseUpdated = BetSlip.OnPurchaseUpdated || {};
    var OnOfferAccepted = BetSlip.OnOfferAccepted || {};
    var OnOfferDeclined = BetSlip.OnOfferDeclined || {};
    var OnAjaxDataServiceLoaded = BetSlip.OnAjaxDataServiceLoaded || {};
    var OnBetSlipStateSaved = BetSlip.OnBetSlipStateSaved || {};
    var OnSelectionAdded = BetSlip.OnSelectionAdded || {};

    // Combo Promotions
    //moved to ComboBonusProvider because of circular dependency
    //var ComboBonuses = {};
    //var RecalculatedComboBonuses = {};

    //var BonusMessages = {
    //    AllLinesGetBonus: 1,
    //    NotAllLineGetBonus: 2,
    //    AllLinesGetBonusBetween: 3,
    //    NotLinesGetBonusBetween: 4
    //}

    var preselected_state = false;

    var hasToUpdateToWinSelections = false;
    var isBetInProcess = false;
    var retainSelectionUVFlag = false;
    var showCheckBoxes = true;
    var ajaxDataServiceLoaded = false;

    var maxSelectionsNumber = window.MaxSelectionsNumber || 20;

    var EnablePrinting = false;
    var isAsianMode = false;
    var MyBetsEnabled = false;
    var declineBetsWithDifferentTypesForTheSameEvent = false;

    var bodyID = document.body ? document.body.id : "";
    var rootClassName = "";
    var rootElement = {};

    // For some business reasons maybe some seamless clients sometimes dont send the user balance through their external
    // API. This causes UserInfo.current.balance object to be empty in the browser. Since combo and system bets should be
    // checked for funds availablity in the browser this variable could prevent this check from happening and combo/system/teaser
    // bets can be placed successfully. This varaible has a corresponding configuration setting in the UniSlip CMS block.
    var SkipClientUserBalanceCheckForCombos = false;

    var SkipClientUserBalanceCheckForSingles = false;

    var KeepSingleTabForMultipleSelections = false;

    // Whether to show a bet-history link in the BetSlip header. Controlled from CMS. False by default.
    var ShowBetHistoryLink = false;

    // Whether to show a currency sign in the BetSlip. Controlled from CMS. False by default.
    var ShowCurrency = false;

    var showMyBetsTab = false;
    var EnableCashOut = false;

    var isShowingOnlyCashOutBets = false;
    var isNotLoggedInMessage = false;

    // Configured from the Domain Items. It controlles how many digits after the decimal points are allowed for the stake field in the BetSlip.
    // It is 2 digits by default. If zero points is configured then the user will be allowed to enter only whole numbers for stake.
    var StakeRoundingMethods = {
        TwoDecimalPoints: 1,
        ZeroDecimalPoints: 2
    };
    var StakeRounding = StakeRoundingMethods.TwoDecimalPoints;

    var SelectionsMasterGroups = [];
    var masterGroupViewKey = 0;
    var wholePanelClassName = 'accept-new-odds-holder';
    var panelHasSettings = 'enable-settings';

    var eventsManager = new EventsManager();

    function registerMode(modeType) {
        var state = BetSlip.createModeInstance(modeType);
        Modes[state.ID] = state;

        if (typeof CombinatorSPSlipMode != "undefined" && state instanceof CombinatorSPSlipMode) {
            BetSlip.declineBetsWithDifferentTypesForTheSameEvent = true;
        }
    }

    function createModeInstance(modeType) {
        return new modeType();
    }

    function setMaxSelections(maxSel) {
        maxSelectionsNumber = maxSel;
    }
    function init() {
        var states = Array.getValues(Modes).sort(function (s1, s2) {
            return s1.Order - s2.Order;
        });
        Facade.addSubscriber(this.subscribeToPush.bind(this), "BetSlip", PushMessageTypes.Odds, {
            SubscriberEvents: PushUpdateEvents,
            UseCustomUpdateHandler: true
        });
        for (var i in states) states[i].init();

        // Called on purpose before restoreState bacause it calls other events like OnAdded which one can subsribe to via OnBetSlipLoaded
        if (typeof OnBetSlipLoaded != "undefined") {
            executeEvents(OnBetSlipLoaded, BetSlip);
        }

        rootElement = document.getElementById("betting_slip");
        rootClassName = typeof rootElement !== "undefined" && rootElement !== null ? rootElement.className : "";

        if (BetSlip.restoreState) {
            BetSlip.restoreState();
        } else {
            restoreState();
        }

        if (typeof UserIsLoggedIn == "undefined" || UserIsLoggedIn) { // UserIsLoggedIn is not available on Mobile
            if (BetSlip.UseNewBettingStructure) {
                BetSlip.getSPPurchases();
            } else {
                BetSlip.getPurchases();
            }
        }

        Data.onOddStyleChanged["BetSlip"] = function () {
            if (!isMyBetsViewActive()) {
                BetSlip.updateView();
            } else {
                BetSlip.updateMyBetsView([], [], true);
            }
        };

        Data.onTimeZoneChanged["BetSlip"] = function () {
            BetSlip.updateView();
        };

        UserInfo.onLogout["BetSlip"] = function () {
            if (BetSlip.EnableAcceptChangingOdds) {
                BetSlip.acceptChangedOdds();
            }
            BetSlip.clear();
            BetSlip.onViewChanged(BetSlipViews.BetSlip);
            BetSlip.clearMyBets();
            if (BetSlip.EnableAcceptChangingOdds) {
                AcceptChangingOdds.clear();
                delete BetSlip.OnModeChanged["AcceptChangingOdds"];
                delete BetSlip.betOddsChanged["AcceptChangingOdds"];
            }
        };

        if (BetSlip.EnableAcceptChangingOdds) {
            UserInfo.onAcceptOddsChanged["BetSlip"] = function (newAcceptOdds) {
                BetSlip.updateConfiguration();
            };
        }


        function onLoggedIn() {
            if (!UserInfo.current) {
                return;
            }

            for (var k in Modes) {
                Modes[k].setError(false);
            }

            BetSlip.updateView();
            if (UserInfo.current.currencyCode && UserInfo.current.currencyCode != null) {
                BetSlip.UserCurrencyCode = UserInfo.current.currencyCode;
            }

            BetSlip.initMyBets();
            if (BetSlip.isMyBetsViewActive()) {
                BetSlip.onViewChanged(BetSlipViews.MyBets);
            }

            if (UpdateTaxOnLogin) {
                var Totals = BetSlip.getFooterTotals();
                BetSlip.taxesApplier(Totals);
            }

            if (BetSlip.EnableAcceptChangingOdds) {
                AcceptChangingOdds.init(Array.getLength(BetSlip.Selections) > 0);
                var isUkBetslip = typeof UKSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof UKSPSlipMode;
                var selectionsInCombo = !isUkBetslip && BetSlip.CurrentMode.getSelectionsInCombo ? BetSlip.CurrentMode.getSelectionsInCombo() : BetSlip.Selections;

                var processChangeOdds = AcceptChangingOdds.processChangeOdds.bind(AcceptChangingOdds);
                var handler = function () {
                    processChangeOdds(BetSlip.EnableAcceptChangingOdds, BetSlip.isAsianMode, BetSlip.isBetInProcess, BetSlip.Selections, selectionsInCombo);
                }

                BetSlip.OnModeChanged["AcceptChangingOdds"] = handler;
                BetSlip.betOddsChanged["AcceptChangingOdds"] = handler;
            }
        }
        UserInfo.onLogin["BetSlip"] = onLoggedIn;

        if (!BetSlip.CurrentMode) {
            BetSlip.CurrentMode = states[0];
        }
        if (rootClassName.indexOf(BetSlip.CurrentMode.TabClass) < 0) {
            typeof rootElement !== "undefined" && rootElement !== null && (rootElement.className = [rootClassName, BetSlip.CurrentMode.TabClass].join(" ").trim());
        }

        BetSlip.CurrentMode.activate();

        var hasPreloadedLines = initPreloadedLines();

        updateView();
        updateBSFooter();

        BetSlip.saveState('', hasPreloadedLines);

        if (typeof UI === 'object' && UI.utils && bodyID === 'asianskin') {
            UI.utils.addListener(window, 'resize', UI.utils.debounce(function () {
                updateScrollbar();
            }, 50));
        }

        onLoggedIn();

        if (BetSlip.config.IsPlaceBetButtonHiddenOnClearBetSlip) {
            function togglePlaceBetAndClearBtns() {
                var hasSelections = Array.getLength(BetSlip.Selections) > 0;
                var hasFinalPurchases = BetSlip.Purchases.any(function (p) {
                    return p.Final;
                });

                var displayPlaceButton = !hasSelections ? "none" : "block";
                var displayClearButton = !hasSelections && !hasFinalPurchases ? "none" : "block";

                var placeBetButton = document.getElementById("PlaceBetButton");
                var clearBetButton = document.getElementById("ClearBetButton");

                placeBetButton && (placeBetButton.style.display = displayPlaceButton);
                clearBetButton && (clearBetButton.style.display = displayClearButton);
            };

            BetSlip.OnAdd['IsPlaceBetButtonHiddenOnClearBetSlip'] = BetSlip.OnDelete['IsPlaceBetButtonHiddenOnClearBetSlip'] = BetSlip.OnPurchaseUpdated['IsPlaceBetButtonHiddenOnClearBetSlip'] = togglePlaceBetAndClearBtns;

            togglePlaceBetAndClearBtns();
        }

        if (BetSlip.EnableAcceptChangingOdds) {
            BetSlip.OnAdd['checkAcceptChangingOdds'] = BetSlip.OnDelete['checkAcceptChangingOdds'] = BetSlip.OnPurchaseUpdated['checkAcceptChangingOdds'] = function () {
                if (AcceptChangingOdds.isActive() && Array.getLength(BetSlip.Selections) === 0) {
                    acceptChangedOdds();
                }
            };
        }

        if (BetSlip.config.HideSummaryTableAfterBetIsPlaced) {
            function toggleSummaryTable() {
                var el = document.getElementById("idSummaryTable");
                el && el.classList[Array.getLength(BetSlip.Selections) == 0 ? "add" : "remove"]('isHidden');
                toggleBetSlipSummaryArea(Array.getLength(BetSlip.Selections) !== 0);
            };

            BetSlip.OnAdd['HideSummaryTableAfterBetIsPlaced'] = BetSlip.OnDelete['HideSummaryTableAfterBetIsPlaced'] = toggleSummaryTable;

            toggleSummaryTable();
        }

        if (BetSlip.ShowLoginPopup) {
            BetSlipLogin.init(BetSlip.LoginPanelCode);
        }
    }

    function getUserCurrency() {
        if (UserInfo.current && currencies.length) {
            var userCurrencyID = UserInfo.current.currencyId;
            var currency = currencies.filter(function (curr) {
                return curr.ID == userCurrencyID
            });
            return (currency.length && currency[0]) ? currency[0] : {};
        }

        return {};
    }

    function initAjaxDataService() {
        jsRequire("/async/hshandler.ashx");
        jsRequire("/async/achan.ashx");

        AJAXDataService.Main.SPWaitingBets.Success = SPWaitingBetsAsyncData;
        AJAXDataService.Main.SPWaitingBets.Error = SPWaitingBetsAsyncError;
        ajaxDataServiceLoaded = true;
        executeEvents(OnAjaxDataServiceLoaded, BetSlip, false);
    }

    window.addEventListener("load", function () {
        setTimeout(initAjaxDataService, 3000);
    })

    function initMyBets() {
        // This method relevant for the website only and not for the mobile/responsive.
        // BetSlip.shouldShowMyBetsTab() is overriden in the mobile solution to always return false because my bets tab there is
        // developed as a seperate block which does not depend on the betslip.
        if (!BetSlip.shouldShowMyBetsTab() || !(MyBets == null))
            return;

        // !!! The code below in this method is executed only in the website solution !!!

        // Cashout for responsive is enabled from Domain item configuration, while for old mobile and website cashout is enabled from UniSlip
        IsCashoutEnabledFromCMS = BetSlip.EnableCashOut;

        MyBets = SPMyBetsCache.getInstance();

        MyBets.addOnContentChanged("BetSlip", function (sender, data) {
            updateMyBetsView(data.toadd, data.toremove, false);
        });

        MyBets.addOnPurchaseChanged("BetSlip", function (sender, data) {
            updateMyBetsViewPurchase(data);
        });

        MyBets.addOnBetsCountChanged("BetSlip", function (sender, data) {
            updateMyBetsCounter();
        });
    }

    function clearMyBets() {
        if (MyBets) {
            MyBets.removeOnContentChanged("BetSlip");
            MyBets.removeOnPurchaseChanged("BetSlip");
            MyBets.removeOnBetsCountChanged("BetSlip");

            MyBets = null;
        }
    }

    function shouldShowMyBetsTab() {
        return showMyBetsTab && UserInfo.current != null;
    }

    function isMyBetsViewActive() {
        return activeView == BetSlipViews.MyBets;
    }

    function addValueToStakeDropDownSeries(value) { //For backwards compatbility, should be removed from slip mode templates
        return getCurrencyWithStakeValues().StakeValuesSeries;
    }

    //Should Be added in BaseSPSlipMode in the NBS
    function getCurrencyID() {
        return UserInfo.current ? (UserInfo.current.CurrencyID ? UserInfo.current.CurrencyID : UserInfo.current.currencyId) : (UserInfo.getCurrencyIDFromStorage ? UserInfo.getCurrencyIDFromStorage() : null);
    }

    function initPreloadedLines() {
        var Bool = {
            False: "false",
            True: "true"
        };

        var hasPreloadedLines = false;

        var Line = {
            ID: isNaN(__preloadLineID) ? "false" : __preloadLineID * 1,
            MasterID: isNaN(__preloadMID) ? "false" : __preloadMID * 1,
            EventID: isNaN(__preloadEventID) ? "false" : __preloadEventID * 1,
            GroupID: isNaN(__preloadLineGroupID) ? "false" : __preloadLineGroupID * 1,
            RowTypeID: isNaN(__preloadRowTypeID) ? "false" : __preloadRowTypeID * 1,
            Odds: isNaN(__preloadOdds) ? "false" : __preloadOdds * 1,
            Points: isNaN(__preloadPoints) ? "false" : __preloadPoints * 1,
            Stake: isNaN(__preloadSelectionStake) ? "false" : __preloadSelectionStake * 1,
            Type: {
                ID: __preloadLineTypeID,
                Info: __preloadLineTypeInfo,
                Regular: "R",
                QA: "Q",
                Live: "L",
                QALive: "QL"
            }
        }

        hasPreloadedLines = addPreloadedLine(Line, Bool);

        if (__preloadComboSelections && __preloadComboSelections != Bool.False) {
            var comboSelections = JSON.parse(__preloadComboSelections);
            for (var index in comboSelections) {
                var currentLine = comboSelections[index];

                Line = {
                    ID: currentLine.lnid || "false",
                    MasterID: currentLine.mid || (currentLine.mid == 0 ? 0 : "false"), // Some events don't have master events and we need to pass 0 to add the selection
                    EventID: currentLine.eid || "false",
                    GroupID: currentLine.lid || "false",
                    RowTypeID: currentLine.rid || "false",
                    Odds: currentLine.odds || "false",
                    Points: currentLine.points || "false",
                    Stake: currentLine.stake || "false",
                    Type: {
                        ID: currentLine.ltid || "false",
                        Info: currentLine.ltin || "false",
                        Regular: "R",
                        QA: "Q",
                        Live: "L",
                        QALive: "QL"
                    }
                }

                hasPreloadedLines = addPreloadedLine(Line, Bool) || hasPreloadedLines;
            }
        }

        if (hasPreloadedLines) {
            getPreloadedLines();
        }

        return hasPreloadedLines;
    }

    function setComboPreloadedStake() {
        var slipModeForCombinations = BetSlip.Modes["combo"] || BetSlip.Modes["comboNoCombination"] || BetSlip.Modes["comboNoCombinationSingle"] || BetSlip.Modes["ukslip"];

        if (__preloadComboStake && __preloadComboStake != "false" && __preloadComboStake > 0 && slipModeForCombinations && typeof slipModeForCombinations.calcVariants === "function") {
            __preloadComboStake = BetSlip.SanitizeNumberString(__preloadComboStake);
            __preloadComboStake = calculateStake(__preloadComboStake);
            slipModeForCombinations.calcVariants();
            var straightComboKey = slipModeForCombinations.Variants.length - 1;
            slipModeForCombinations.setDeposit(straightComboKey, __preloadComboStake);
            BetSlip.selectSlipMode(slipModeForCombinations.ID);
            if (BetSlip.isDefaultStakeAndStakeValuesAvailable()) {
                slipModeForCombinations.StakesUpdatedByUserForDropDown[straightComboKey] = true;
                slipModeForCombinations.deselectStakeDropdown(straightComboKey);
            }

            // add a flag for the current combo key that the user updated the combo amount
            slipModeForCombinations.StakesUpdatedByUser[straightComboKey] = true;
        }

        if (BetSlip.IsInEditMode) {
            slipModeForCombinations = BetSlip.Modes["editopenbet"];
            var myBets = SPMyBetsCache.getInstance();
            var sel = Selections.firstOrDefault(function (s) {
                return s && s.BetID;
            });
            if (!sel) {
                return;
            }

            var bet = myBets.getBetById(sel.BetID);
            var stake = bet.CashOutData.Amount;
            slipModeForCombinations.calcVariants();
            var straightComboKey = slipModeForCombinations.Variants.length - 1;
            BetSlip.CurrentMode.BetID = bet.BetID;
            BetSlip.CurrentMode.PurchaseID = bet.PurchaseID;
            slipModeForCombinations.setDeposit(straightComboKey, stake);
        }
    }

    function calculateStake(stake) {
        if (isNaN(stake)) {
            stake = 0;
        }

        stake = BetSlip.RoundToStakeRounding(stake);

        if (stake > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints) {
            stake = Math.floor(stake);
        }

        return stake;
    }

    function addPreloadedLine(Line, Bool) {
        if (Line.Type.Info != Bool.False) {
            var isLineAdded = false;

            // Regular
            if (Line.Type.Info == Line.Type.Regular &&
                Line.MasterID != Bool.False &&
                Line.EventID != Bool.False &&
                Line.GroupID != Bool.False &&
                Line.ID != Bool.False) {
                addOdd(Line.MasterID, Line.EventID, Line.GroupID, Line.ID, Line.Odds == Bool.False ? 0 : Line.Odds, Line.Points == Bool.False ? 0 : Line.Points, Line.Type.ID, false, true)
                isLineAdded = true;
            }

            // QA
            if (Line.Type.Info == Line.Type.QA &&
                Line.MasterID != Bool.False &&
                Line.EventID != Bool.False &&
                Line.ID != Bool.False) {
                addQAOdd(Line.MasterID, Line.EventID, Line.ID, Line.Odds == Bool.False ? 0 : Line.Odds, Line.Type.ID, true);
                isLineAdded = true;
            }

            // Live
            if (Line.Type.Info == Line.Type.Live &&
                Line.MasterID != Bool.False &&
                Line.EventID != Bool.False &&
                Line.GroupID != Bool.False &&
                Line.RowTypeID != Bool.False) {
                if (Line.Type.ID == Bool.False) {
                    Line.Type.ID = 1;
                }
                addOddLive(Line.MasterID, Line.EventID, Line.GroupID, Line.Type.ID, Line.RowTypeID, Line.Odds == Bool.False ? 0 : Line.Odds, Line.Points == Bool.False ? null : Line.Points, Line.Type.ID, false, true, 0, Line.ID);
                isLineAdded = true;
            }

            // QA live
            if (Line.Type.Info == Line.Type.QALive &&
                Line.MasterID != Bool.False &&
                Line.EventID != Bool.False &&
                Line.ID != Bool.False) {
                addOddQALive(Line.MasterID, Line.EventID, Line.ID, Line.Odds == Bool.False ? 0 : Line.Odds, Line.Type.ID, true);
                isLineAdded = true;
            }

            if (BetSlip.Modes["single"] && Line.Stake != Bool.False && Line.Stake > 0) {
                BetSlip.Modes["single"].setDeposit(BetSlip.getLastUsedViewKey(), Line.Stake);
            } else if (BetSlip.Modes["ukslip"] && Line.Stake != Bool.False && Line.Stake > 0) {
                SingleSPSlipMode.prototype.setDeposit.call(BetSlip.Modes["ukslip"], BetSlip.getLastUsedViewKey(), Line.Stake);
            }
        }

        return isLineAdded;
    }

    function getPreloadedLines() {
        var request = [];
        var selectionsInCombo = BetSlip.CurrentMode.getSelectionsInCombo != undefined ? BetSlip.CurrentMode.getSelectionsInCombo() : null;

        for (var key in Selections) {
            var item = Selections[key];

            var requestItem = item.getUpdateRequest();
            var isItemInCombo = false;
            if (selectionsInCombo && Array.indexOf(selectionsInCombo, item) != -1) {
                isItemInCombo = true;
            }
            var shouldBeUpdated = true;
            var isBanker = item.IsBanker ? true : false;

            requestItem.push(isBanker);
            requestItem.push(item.getComboGroupKey());
            requestItem.push(isItemInCombo);
            requestItem.push(shouldBeUpdated);
            requestItem.push(item.LineTypeID);
            request.push(requestItem);
        }

        if (request.length == 0) {
            return;
        }

        updateCartBetsRunning = true;

        var pageMethods = BetSlip.UseNewBettingStructure ? BettingPageMethods : PageMethods;

        pageMethods.updateRegularCartItems(

            request.join("@"),

            function (res) {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                parseCartBetsUpdate(eval(res), true);
                deleteSuspendedAndClosed();
                setComboPreloadedStake();
                updateView();
                updateBSFooter();
            },

            function (res) {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                deleteSuspendedAndClosed();
                ComboBonusProvider.ComboBonuses = null;
                updateView();
            }
        );
    }

    function setConfig(config) {
        if (typeof (config.DefaultStake) != "undefined") {
            BetSlip.DefaultStake = config.DefaultStake;
        }
        if (typeof (config.ShowCheckBoxes) != "undefined") {
            showCheckBoxes = config.ShowCheckBoxes;
            BetSlip.showCheckBoxes = config.ShowCheckBoxes;
        }
        if (typeof (config.IsAsianMode) != "undefined") {
            BetSlip.isAsianMode = config.IsAsianMode;
        }
        if (typeof config.EnablePrinting != "undefined") {
            BetSlip.EnablePrinting = config.EnablePrinting;
        }
        if (typeof config.SkipClientUserBalanceCheckForSingles != "undefined") {
            BetSlip.SkipClientUserBalanceCheckForSingles = config.SkipClientUserBalanceCheckForSingles;
        }
        if (typeof config.SkipClientUserBalanceCheckForCombos != "undefined") {
            BetSlip.SkipClientUserBalanceCheckForCombos = config.SkipClientUserBalanceCheckForCombos;
        }
        if (typeof (config.ShowMyBetsTab) != "undefined") {
            showMyBetsTab = !!config.ShowMyBetsTab;
        }
        if (typeof (config.EnableCashOut) != "undefined") {
            BetSlip.EnableCashOut = !!config.EnableCashOut;
        }
        if (typeof (config.EnableCashOutConfirmation) != "undefined") {
            BetSlip.EnableCashOutConfirmation = !!config.EnableCashOutConfirmation;
        }
        if (typeof (config.CashOutConfirmationTimeOut) != "undefined") {
            BetSlip.CashOutConfirmationTimeOut = config.CashOutConfirmationTimeOut;
        }
        if (typeof (config.ShowBetHistoryLink) != "undefined") {
            BetSlip.ShowBetHistoryLink = config.ShowBetHistoryLink;
        }
        if (typeof (config.ShowLoginPopup) != "undefined") {
            BetSlip.ShowLoginPopup = config.ShowLoginPopup;
        }
        if (typeof (config.LoginPanelCode) != "undefined") {
            BetSlip.LoginPanelCode = config.LoginPanelCode;
        }
        if (typeof (config.ShowCurrency) != "undefined") {
            BetSlip.ShowCurrency = config.ShowCurrency;
        }
        if (typeof (config.StakeRounding) != "undefined") {
            BetSlip.StakeRounding = config.StakeRounding;
        }
        if (typeof (config.CurrencySetting) != "undefined") {
            BetSlip.CurrencySetting = config.CurrencySetting;
        }
        if (typeof (config.PlaceSingleBetsWithMultiplePurchases) != "undefined") {
            BetSlip.PlaceSingleBetsWithMultiplePurchases = config.PlaceSingleBetsWithMultiplePurchases;
        }
        if (typeof (config.PlaceComboBetsWithMultiplePurchases) != "undefined") {
            BetSlip.PlaceComboBetsWithMultiplePurchases = config.PlaceComboBetsWithMultiplePurchases;
        }
        if (typeof (config.MyBetsEnabled) != "undefined") {
            BetSlip.MyBetsEnabled = config.MyBetsEnabled;
        }
        if (typeof (config.EnableAsianStyleMinMax) != "undefined") {
            BetSlip.EnableAsianStyleMinMax = config.EnableAsianStyleMinMax;
        }
        if (typeof (config.IsAlertMessageEnabled) != "undefined") {
            BetSlip.isAlertMessageEnabled = config.IsAlertMessageEnabled;
        }
        if (typeof (config.CheckMaxBetOnPage) != "undefined") {
            BetSlip.CheckMaxBetOnPage = config.CheckMaxBetOnPage;
        }
        if (typeof (config.UseNewBettingStructure) != "undefined") {
            BetSlip.UseNewBettingStructure = !!config.UseNewBettingStructure;
            BetSlip.UseFreeBet = BetSlip.UseNewBettingStructure && !isAsianView;
        }

        BetSlip.ShowMaxBetButtonLive = true;
        if (typeof (config.ShowMaxBetButtonLive) != "undefined") {
            BetSlip.ShowMaxBetButtonLive = !!config.ShowMaxBetButtonLive;
        }

        BetSlip.ShowMaxBetButtonPrematch = true;
        if (typeof (config.ShowMaxBetButtonPrematch) != "undefined") {
            BetSlip.ShowMaxBetButtonPrematch = !!config.ShowMaxBetButtonPrematch;
        }

        if (typeof (config.KeepSingleTabForMultipleSelections) != "undefined") {
            BetSlip.KeepSingleTabForMultipleSelections = config.KeepSingleTabForMultipleSelections;
        }

        if (config.ImageForXSign) {
            BetSlip.ImageForXSign = config.ImageForXSign;
        }
        if (config.IntervalForQAPreliveLines) {
            BetSlip.IntervalForQAPreliveLines = config.IntervalForQAPreliveLines;
        }
        BetSlip.focusOnNewBet = !!isAsianView;

        if (typeof (config.IsBetslipUK) != "undefined") {
            BetSlip.IsBetslipUK = config.IsBetslipUK;
        }

        if (typeof (config.BaseOddsRoundingMode) != "undefined") {
            BetSlip.BaseOddsRoundingMode = config.BaseOddsRoundingMode;
        }

        if (typeof (config.GainRoundingMode) != "undefined") {
            BetSlip.GainRoundingMode = config.GainRoundingMode;
        }

        if (typeof (config.ComboOddsRoundingMode) != "undefined") {
            BetSlip.ComboOddsRoundingMode = config.ComboOddsRoundingMode;
        }

        if (typeof (config.ForceTwoDigitsForOddsRounding) != "undefined") {
            BetSlip.ForceTwoDigitsForOddsRounding = config.ForceTwoDigitsForOddsRounding;
        }

        if (typeof (config.UseItalianGainRounding) != "undefined") {
            BetSlip.UseItalianGainRounding = config.UseItalianGainRounding;
        }

        if (typeof (config.MinBetOddsThreshold) != "undefined") {
            BetSlip.MinBetOddsThreshold = config.MinBetOddsThreshold;
        }

        if (typeof (config.EnableVisualLogger) != "undefined") {
            BetSlip.EnableVisualLogger = config.EnableVisualLogger;
            if (typeof VisualLogger != "undefined") { // For cases when VisualLogger.js is loaded before UniSlip.js
                VisualLogger.init();
            }
        }

        if (typeof (config.ShowFullNumberForMinMaxBetValue) != "undefined") {
            BetSlip.ShowFullNumberForMinMaxBetValue = config.ShowFullNumberForMinMaxBetValue;
        }

        if (typeof (config.ClearBetReceiptOnSelectionAdded) != "undefined") {
            BetSlip.ClearBetReceiptOnSelectionAdded = config.ClearBetReceiptOnSelectionAdded;
        }

        if (typeof (config.isComboBonusEnabledForSite) != "undefined") {
            BetSlip.IsComboBonusEnabledForSite = config.isComboBonusEnabledForSite;
        }

        if (typeof (config.ShowTextForEmptyBetslip) != "undefined") {
            BetSlip.ShowTextForEmptyBetslip = config.ShowTextForEmptyBetslip;
        }

        if (typeof (config.TemplateType) != "undefined") {
            BetSlip.TemplateType = config.TemplateType;
        }

        if (typeof (config.DisableBetPlaceForNotVerifiedUsers) != "undefined") {
            BetSlip.DisableBetPlaceForNotVerifiedUsers = config.DisableBetPlaceForNotVerifiedUsers;
        }

        if (typeof (config.DisplayNARelatedSelections) != "undefined") {
            BetSlip.DisplayNARelatedSelections = config.DisplayNARelatedSelections;
        }

        if (typeof (config.SwitchBackToSingleMode) != "undefined") {
            BetSlip.SwitchBackToSingleMode = config.SwitchBackToSingleMode;
        }

        if (config.DeclineBetsWithDifferentTypesForTheSameEvent) {
            BetSlip.declineBetsWithDifferentTypesForTheSameEvent = config.DeclineBetsWithDifferentTypesForTheSameEvent;
        }

        BetSlip.ShowRequestAmountMessage = (typeof (config.ShowRequestAmountMessage) != "undefined") ? config.ShowRequestAmountMessage : true;
        if (typeof (config.CalcEachCombinationIndividualy) != "undefined") {
            BetSlip.CalcEachCombinationIndividualy = config.CalcEachCombinationIndividualy;
        }

        if (!BetSlip.config) {
            BetSlip.config = {};
            BetSlip.config.IsPlaceBetButtonHiddenOnClearBetSlip = config.IsPlaceBetButtonHiddenOnClearBetSlip;
            BetSlip.config.HideSummaryTableAfterBetIsPlaced = config.HideSummaryTableAfterBetIsPlaced;
            BetSlip.config.DisallowModifiedFastMarketBets = config.DisallowModifiedFastMarketBets;
            BetSlip.config.IsMultiplesSectionCollapsedInUkSlipMode = config.IsMultiplesSectionCollapsedInUkSlipMode;
            BetSlip.config.IsPreviewEnabled = config.IsPreviewEnabled;
            BetSlip.config.EnableBookABet = config.EnableBookABet;
            BetSlip.config.EnableAddingBetsByBookedBetsCode = config.EnableAddingBetsByBookedBetsCode;
            BetSlip.config.EnableBookABetQrCode = !!config.EnableBookABetQrCode;
        }

        if (BetSlip.config.EnableBookABet) {
            BookABet.init(StorageUtils, BookABetService);
        }
    }

    function restoreSlipMode(id) {
        BetSlip.CurrentMode = Modes[id];
    }

    function selectSlipMode(id, restore, isForce) {
        if (BetSlip.CurrentMode && BetSlip.CurrentMode.ID == id && !isForce) return false;

        // Disable navigation to a different tab if there is a bet currently in process
        if (BetSlip.isBetInProcess && !restore) return false;

        var newMode = Modes[id];
        var modeCanBeSelected = newMode && (restore || newMode.canActivate());
        if (!modeCanBeSelected) return false;

        if (BetSlip.CurrentMode)
            BetSlip.CurrentMode.deactivate();

        BetSlip.CurrentMode = newMode;
        BetSlip.CurrentMode.activate();
        BetSlip.clearEW();

        typeof rootElement !== "undefined" && rootElement !== null && (rootElement.className = [rootClassName, BetSlip.CurrentMode.TabClass].join(" ").trim());

        updateCartBets();
        updateView();
        BetSlip.saveState();

        executeEvents(OnModeChanged, BetSlip, id);
        return true;
    }

    function isAllowedToReplaceSelection(selection, candidate) {

        return selection.Type === candidate.Type || ((selection.Type === 0 || selection.Type === 39) && candidate.Type === 61) || (selection.Type === 61 && (candidate.Type === 0 || candidate.Type === 39));
    }

    function isSameOutright(selection, candidate) {
        return selection.MasterEventID === 0 && candidate.MasterEventID === 0 && selection.EventID === candidate.EventID;
    }

    function add(cb, isPreloaded) {
        if (BetSlip.IsInEditMode && !cb.BetID) {
            UI.message.show({
                header: '',
                body: $dict.bs("EditModeAddingBetError")
            });
            return;
        }
        if (BetSlip.IsInConfigMode && !UniSlipBlock.isShown()) {
            UniSlipBlock.showBlock();
        }

        if (BetSlip.ClearBetReceiptOnSelectionAdded) {
            BetSlip.cleanPurchases();
        }

        if (isMyBetsViewActive()) {
            BetSlip.showBetSlipView();
        }

        for (var key in Selections) {
            if (Selections[key].equals(cb)) {
                if (!cb.CastSelection) // this selection is single (its not part of fore/tricast)
                {
                    if (Selections[key].CastSelection == true) // this selection is part of fore/tricast bet in betslip
                    {
                        cb.CastSelection = true;
                    }

                    if (cb.CastSelection && !Selections[key].VisibleCastSelection) {
                        Selections[key].VisibleCastSelection = true; // add separate single row in betslip for this selection

                        executeEvents(OnAdd, BetSlip, cb);
                        updateView();

                        return -1;
                    }

                    if (!isPreloaded) {
                        removeBet(Selections[key].ViewKey, false, true, true);
                    }

                    delete PushUpdateEvents[cb.EventID];
                }
                return -1;
            } else if (this.declineBetsWithDifferentTypesForTheSameEvent && BetSlip.CurrentMode.ID !== "yourbet" &&
                ((!Selections[key].CastSelection && Selections[key].MasterEventID === cb.MasterEventID && Selections[key].MasterEventID) ||
                    (Selections[key].MasterEventID === 0 && cb.MasterEventID === 0 && Selections[key].LeagueID === cb.LeagueID && !Selections[key].IsEachWaySelection) ||
                    isSameOutright(Selections[key], cb))) {
                var combinatorGroup = Selections[key].CombinatorGroup;
                if (combinatorGroup) {
                    cb.CombinatorGroup = combinatorGroup;
                    cb.ViewKey = Selections[key].ViewKey;
                }
                clearReplacedSelection(Selections[key]);
                removeBet(Selections[key].ViewKey, true, true, false);
            }
        }

        if (BetSlip.Modes["combinator"]) {
            BetSlip.Modes["combinator"].clearErrors(); // should be Combinator
        }

        PushUpdateEvents[cb.EventID] = {
            ID: cb.EventID
        };
        if (Array.getLength(Selections) >= maxSelectionsNumber) {
            executeEvents(OnSelectionRemoved, createRemoveMessage(cb, false));
            BetSlip.CurrentMode.setError($dict.bs("MaxRegularLinesCounts").format(maxSelectionsNumber), true);
            return -2;
        }

        if (!cb.ViewKey) {
            cb.ViewKey = BetSlip.getViewKey();
        }

        cb.ComboBetIsEnabled = cb instanceof MultiLineItem ? 0 : 1;
        cb.SystemBetIsEnabled = cb instanceof MultiLineItem ? 0 : 1;


        Selections[cb.ViewKey] = cb;
        addSelectionToMasterGroup(cb);

        if (BetSlip.CurrentMode.ID === "yourbet") {
            BetSlip.CurrentMode.selectionAdded(cb);
        } else {
            for (var k in Modes)
                Modes[k].selectionAdded(cb);
        }

        if (!isPreloaded) {
            BetSlip.saveState();
            updateCartBets(null, cb, true);
            //updateSelection(cb);
            startCartMonitor();
        }

        var comboMode = BetSlip.Modes.combo;
        if (comboMode) {
            comboMode.setIsGeneratedCombo(false);
        }

        executeEvents(OnAdd, BetSlip, cb);

        updateView();
        updateScrollbar();
        return cb.ViewKey;
    }

    function autoSelectMode(isForce) {
        // We test if we have a combo bet purchase in our unislip purchases. If we have one, we don't chenge the mode to single as this would break the logic drawing the current purchase bets
        if (typeof isMobileBettingPage != "undefined") {
            for (var i = BetSlip.Purchases.length; i >= 0; i = i - 1) {
                var currentPurchase = BetSlip.Purchases[i];
                if (currentPurchase instanceof SPPurchase) {
                    if (currentPurchase.Bet instanceof SPComboBet && !BetSlip.IsInEditMode) {
                        return;
                    }
                }
            }
        }

        if (preselected_state && BetSlip.CurrentMode.canActivate()) return;

        var stateid = false;
        var maxwieght = 0;
        var force_teaser = false;

        for (var k in Modes) {
            var mode = Modes[k];
            var cc = mode.canActivate();
            if (cc <= maxwieght) continue;

            maxwieght = cc;
            stateid = mode.ID;
        }

        if (BetSlip.CurrentMode.ID == "teaser" && Array.getLength(BetSlip.Selections) >= 2) {
            force_teaser = true;
        }

        if (BetSlip.CurrentMode.ID === "comboNoCombinationSingle") {
            stateid = BetSlip.CurrentMode.ID;
        }

        if (!stateid) return;

        if (BetSlip.isAutoSelectModeAllowed() || isForce) {
            selectSlipMode(stateid);
        }

        if (force_teaser) {
            selectSlipMode("teaser");
        }
    }

    function isAutoSelectModeAllowed() {
        return BetSlip.CurrentMode.ID !== "yourbet";
    }

    function removeMultiLineItemSelections(viewKey, isPurchased) {
        var cb = Selections[viewKey];

        if (!(cb instanceof MultiLineItem)) {
            return;
        }

        for (var idx in cb.Selections) {
            var contain = false;
            var innerCb = Selections[cb.Selections[idx]];
            if (!innerCb) {
                continue;
            }

            if (isPurchased) {
                cb.PurchasedSelections[innerCb.ViewKey] = innerCb;
            }

            //check if the selection is part of another multiline item,
            //if it is, we should not remove it
            for (var selidx in Selections) {
                var selection = Selections[selidx];
                if ((selection instanceof MultiLineItem) && (selection.ViewKey != viewKey)) {
                    contain = selection.Selections.any(function (innerSelViewKey) {
                        return innerSelViewKey == innerCb.ViewKey;
                    });
                    if (contain)
                        break;
                }
            }

            if (contain) {
                continue;
            }

            //Not part of other multiline bets
            if (innerCb.VisibleCastSelection) { //Is also a separate single bet
                innerCb.VisibleCastSelection = false;
                innerCb.CastSelection = false;
            } else {
                innerRemoveBet(innerCb.ViewKey, true);
            }
        }
    }

    function isQaSlipSelectionUsedInSeveralMultiLineItems(viewKey) {
        if (!UseAustralianHorseRacingView)
            return false;
        var multiLineItemsContainingThis = 0;
        var qaSlipSelection = Selections[viewKey];
        if (qaSlipSelection instanceof QASlipSelection) {
            for (var i in Selections) {
                var multilineItem = Selections[i];
                if (multilineItem instanceof MultiLineItem) {
                    var selections = multilineItem.Selections;
                    for (var j in selections) {
                        var selection = selections[j];
                        if (selection == viewKey)
                            multiLineItemsContainingThis++;
                    }
                }
            }
        }
        return multiLineItemsContainingThis >= 2;
    }

    function innerRemoveBet(viewKey, isReplacelment, isFromBlocks) {
        if (Object.keys(BetSlip.SelectionsMasterGroups).length) {
            removeSelectionFromGroupByViewKey(viewKey);
        }

        var cb = Selections[viewKey];
        if (!cb) return false;

        delete PushUpdateEvents[cb.EventID];

        // Clear UpdateOdds flag of the selection when it is removed from the betslip and added to the purchase
        cb.UpdatedOdds = cb.UpdatedPoints = cb.UpdatedScore = false;
        if (cb.VisibleCastSelection) {
            cb.VisibleCastSelection = false;
        } else if (!isQaSlipSelectionUsedInSeveralMultiLineItems(viewKey)) {
            var selection = Selections[viewKey];
            delete Selections[viewKey];   
            

            if (Application.IsNativeApp())
            {
                clearReplacedSelection(selection);
            }
    
            if(!(cb instanceof MultiLineItem)){
                executeEvents(OnSelectionRemoved, createRemoveMessage(selection, isFromBlocks));
            }
        }
        
        if (!BetSlip.CheckForEachWayIncluded()) BetSlip.clearEW();

        for (var k in Modes) Modes[k].selectionRemoved(cb, isReplacelment);

        var selectionsCount = Array.getLength(Selections);
        if (selectionsCount == 0) {
            preselected_state = false;
            for (var k in Modes) Modes[k].clear();
            BetSlip.config.EnableAddingBetsByBookedBetsCode && BookedBets.clear();
        } else if (selectionsCount < maxSelectionsNumber && BetSlip.CurrentMode.CurrentError == $dict.bs("MaxRegularLinesCounts").format(maxSelectionsNumber)) {
            BetSlip.CurrentMode.CurrentError = false;
        }

        BetSlip.saveState();
        autoSelectMode();
        return true;

    }

    function createRemoveMessage(selection, isFromBlocks) {
        var message = {
            receiverIds: [],
            items: [],
            senderId: sbBetslipUtilsContracts.BsUtilReceivers.FullBetSlip
        }
        if (!selection.IsMultiLine) {
            message.items.push({
                eventId: BetSlip.getSDEventId(selection),
                marketId: BetSlip.getSDMarketId(selection),
                selectionId: BetSlip.getSDSelectionId(selection),
                type: "Regular"
            });
        }
        if (isRacingBranch(selection.BranchID)) {
          	let item = message.items[0];
            item.legacy = {
                eventId: selection.EventID,
                selectionId: selection.LineID,
                odds: selection.Odds,
                eventTypeId: selection.EventTypeID,
                isOtherHR: true
            }
        }
        message.isFromBlocks = isFromBlocks;
        return message;
    }

    function clearReplacedSelection(item) {
        var selectedBetsClass = "chosenBet";
        var uniqueOddsClass = getUniqueOddsClass(item.LineID, item instanceof QASlipSelection || item instanceof QALiveSlipSelection);
        var bets = document.getElementsByClassName(uniqueOddsClass);

        for (var i = 0; i < bets.length; i++) {
            var bet = bets[i];
            bet.classList.remove(selectedBetsClass);
        }
    }

    function removeBet(viewKey, isReplacelment, fromAdd, isFromBlocks) {
        var selectionToRemove = Selections[viewKey];
        removeMultiLineItemSelections(viewKey);
        innerRemoveBet(viewKey, isReplacelment, isFromBlocks);

        if (isBankersResetNeeded()) {
            resetAllBankers();
        }

        var comboMode = BetSlip.Modes.combo;
        if (comboMode) {
            comboMode.setIsGeneratedCombo(false);
        }

        updateCartBets();

        var onDeletePayload = {
            isFromAdd: fromAdd,
            removedSelection: selectionToRemove
        };

        eventsManager.dispatchEvent("onSelectionRemoved", selectionToRemove.LineID, true);
        executeEvents(OnDelete, BetSlip, onDeletePayload);

        updateView();
    }

    function getViewKey() {
        return (viewGen++);
    }

    function getLastUsedViewKey() {
        if (viewGen <= 0) {
            return viewGen;
        } else {
            return (viewGen - 1);
        }

    }

    function getCurrencyWithStakeValues() {
        var currencyID = getCurrencyID();
        var currency;


        if (currencyID) {
            currency = Array.find(BetSlip.CurrencySetting, function (key) {
                return key.Id ? key.Id == currencyID : key.ID == currencyID;
            });
        }
        if (!currency) {
            currency = getDefaultCurrency();
        }
        return currency;
    }

    function isDefaultStakeAndStakeValuesAvailable() {
        var currentCurrency = getCurrencyWithStakeValues();

        return currentCurrency && !!currentCurrency.StakeValuesSeries.length;
    }

    function getCurrencyCodeForBetSlip() {
        var currencyCode = "";
        if (UserInfo.current && BetSlip.ShowCurrency) {
            currencyCode = BetSlip.getCurrencyCode();
        }
        return currencyCode;
    }

    function getCurrencyCode() {
        if (UserInfo.current && UserInfo.current != null && UserInfo.current.currencyCode && UserInfo.current.currencyCode != null) {
            var currencyCode = UserInfo.current.currencyCode;
            BetSlip.UserCurrencyCode = currencyCode;

            return currencyCode;
        }

        // This line of code will be reached in case the user logs out while the purchase is present on the screen
        return BetSlip.UserCurrencyCode;
    }

    function getDefaultCurrency() {
        var toReturn = Array.find(BetSlip.CurrencySetting, function (key) {
            return key.IsDefault;
        });

        return toReturn;
    }

    function addPurchase(purchase, clearPreselectedState, keepPreviousPurchase, isAfterRestore) {
        var teaserBet, teaserBetType;
        if (clearPreselectedState)
            preselected_state = false;

        // Place bet method has returned a response when adding a purchase
        BetSlip.isBetInProcess = false;

        if (!keepPreviousPurchase && !BetSlip.MyBetsEnabled)
            cleanPurchases();

        Purchases[purchase.ViewKey] = purchase;
        teaserBet = purchase.Bets.find(function (x){ return x.BetType == BetTypes.Teaser});
        teaserBetType = teaserBet ? TeaserSPTypes.getTeaserInfo(teaserBet.TeaserTypeID) : undefined;
        for (var vk in purchase.getSelections()) {
            handleTeaserPointsReduction(teaserBet, teaserBetType, vk);
            removeMultiLineItemSelections(vk, true);
            innerRemoveBet(vk, true);
        }

        // Adding a purchase removes the selections from the slip, so if after placing a combo for example there are any selections left in the betslip
        // then they had not been checked before placing the combo. So now we try to add these unchecked selections to combo tab after placing a bet
        if (!isAfterRestore) {
            for (var k in Modes) {
                if (typeof Modes[k].addUncheckedAfterPurchase == "function") {
                    Modes[k].addUncheckedAfterPurchase();
                }
            }
        }

        BetSlip.saveState();

        updateView();
        startWaitingMonitor();


        executeEvents(OnPurchaseAdded, BetSlip, false);
        executeEvents(OnDelete, BetSlip);
    }

    function handleTeaserPointsReduction (teaserBet, teaserBetType, vk)
    {
        if (teaserBet && teaserBetType && BetSlip.Selections[vk]) {
            var selection =  BetSlip.Selections[vk];
            var points = teaserBetType && selection.BetTypeID == 3 && selection.BetSide == 1 ?
            selection.Points + teaserBetType.PointsToReduce : selection.Points - teaserBetType.PointsToReduce;
            selection.Points = points;
        }
    }

    function removePurchase(viewKey) {
        var purchase = Purchases[viewKey];
        if (!purchase || !purchase.Final) return;

        for (var i in purchase.Bets) {
            if (purchase.Bets[i].RowID != null) {
                PageMethods.RemoveItemFromSlip(purchase.Bets[i].RowID);
            }
        }

        delete Purchases[viewKey];
        updateCartBets();
        updateView();

        executeEvents(OnPurhaseRemoved, BetSlip, false);
    }

    function clear() {
        if (BetSlip.isBetInProcess) return;

        var message = {
            receiverIds: [],
            items: []
        }

        BetSlip.SelectionsMasterGroups = [];

        for (var k in Selections) {
            var selection = Selections[k];
            delete PushUpdateEvents[selection.EventID];
            delete Selections[k];
            selection.IsQA = (selection instanceof QASlipSelection || selection instanceof QALiveSlipSelection);
            if (!selection.IsMultiLine) {
                message.items.push({
                    eventId: BetSlip.getSDEventId(selection),
                    marketId: BetSlip.getSDMarketId(selection),
                    selectionId: BetSlip.getSDSelectionId(selection),
                    type: "Regular"
                });
            }
            eventsManager.dispatchEvent("onSelectionRemoved", selection.LineID, true);
        }

        if (message.items.length) {          
        	executeEvents(OnSelectionRemoved, message);
        }

        preselected_state = false;
        for (var k in Modes) Modes[k].clear();

        for (var k in Purchases) {
            var purchase = Purchases[k];
            if (purchase.canRemove() || purchase.PurchaseTypeID === SPPurchaseType.YourBet) delete Purchases[k];
        }


        BetSlip.saveState();

        WebStorage.sessionRemove("complexPurchase");
        WebStorage.sessionRemove("spPurchase");

        ComboBonusProvider.ComboBonuses = null;
        BetSlip.updateView();
        updateCartBets();
        BetSlip.config.EnableAddingBetsByBookedBetsCode && BookedBets.clear();

        executeEvents(OnDelete, BetSlip);
        autoSelectMode();
    }

    function disablePlaceBetButton() {
        togglePlaceBetButton(true);
    }

    function togglePlaceBetButton(isDisabled) {
        var place = document.getElementById("place");
        var placeBetButton = document.getElementById("PlaceBetButton");
        if (place && placeBetButton) {
            place.classList[isDisabled ? "add" : "remove"]("disabledBtn");
            placeBetButton.disabled = isDisabled;
        }
    }

    function toggleBetReceiptBanner(hasBetSections) {
        var banner = document.getElementById("idBetReceiptBanner");
        if (banner) {
            banner.classList[hasBetSections ? "add" : "remove"]("hidden");
            toggleBetSlipSummaryArea(hasBetSections);
        }
    }

    function toggleBetSlipSummaryArea(hasBetSections) {
        var summaryArea = document.getElementById("idBetSlipSummaryArea");
        summaryArea && summaryArea.classList[hasBetSections ? "remove" : "add"]("hidden");
    }

    function disableOddsBlinking(selections) {
        for (var key in selections) {
            var selection = selections[key],
                isOddUpdated = selection.UpdatedOdds;

            if (BetSlip && BetSlip.CurrentMode && typeof BetSlip.CurrentMode.isSelectionPropUpdated == "function") {
                isOddUpdated = BetSlip.CurrentMode.isSelectionPropUpdated(selection.ViewKey, BetSlipSelectionPropCasesWithBlinking.Odds);
            }

            if (isOddUpdated) {
                var elementID = "cOddsToWhow_" + selection.ViewKey;
                document.getElementById(elementID).classList.remove("updated");
            }
        }
    }

    // this function is overriden in UniSlip.ext.js
    function updateBSFooter() {
        // Externalized in UniSlip.ext.js to support mobile and tablet overrides
        BetSlip.updateBSFooter();
    }

    function ShouldReturnNAAsTotalGain(selections) {
        return selections.any(function (selection) {
            return selection.EventTypeID === RacingEventTypes.AUHRTote || selection.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly
        });
    }

    function getFooterTotals() {
        var Totals = {};

        Totals.FreeBetDeposit = typeof BetSlip.CurrentMode.getFreeBetDeposit != "undefined" ? BetSlip.CurrentMode.getFreeBetDeposit() : 0;
        Totals.TotalDeposit = BetSlip.CurrentMode.getTotalDeposit();
        Totals.RiskFreeBetDeposit = BetSlip.CurrentMode.getRiskFreeBetDeposit();

        var freeBetContribution = Totals.RiskFreeBetDeposit ? Totals.FreeBetDeposit - Totals.RiskFreeBetDeposit : Totals.FreeBetDeposit;
        Totals.BalanceDeposit = Totals.TotalDeposit - freeBetContribution;

        var returnNAAsTotalGain = BetSlip.ShouldReturnNAAsTotalGain(BetSlip.Selections) || BetSlip.CheckForSPIncluded() || BetSlip.CheckForNoOddsSelectionIncluded();
        if (!returnNAAsTotalGain) {
            Totals.Gain = BetSlip.CurrentMode.getTotalGain() - (Totals.RiskFreeBetDeposit ? 0 : freeBetContribution);
            Totals.GainEachWay = BetSlip.CurrentMode.getEachWayTotalGain ? BetSlip.CurrentMode.getEachWayTotalGain() : 0;
            Totals.PossibleWinnings = Totals.Gain + Totals.GainEachWay;
        }
        Totals.ExtraWinnings = BetSlip.CheckForEachWayIncluded() ? 0 : GetExtraWinnings();
        Totals.ExtraWinnings = BetSlip.CurrentMode.floorDecimal(Totals.ExtraWinnings);
        Totals.Gain = BetSlip.CurrentMode.floorDecimal(Totals.Gain);
        Totals.GainPlusExtraWinnings = getGainPlusExtraWinnings(freeBetContribution, Totals.Gain, Totals.ExtraWinnings);
        Totals.Taxes = BetSlip.CurrentMode.floorDecimal(UserInfo.TaxProvider.getBetSlipTaxAmount(Totals.GainPlusExtraWinnings + (BetSlip.CheckForEachWayIncluded() ? Totals.GainEachWay : 0)));
        Totals.ReturnWithTaxes = BetSlip.CurrentMode.floorDecimal((Totals.GainPlusExtraWinnings + (BetSlip.CheckForEachWayIncluded() ? Totals.GainEachWay : 0)) - Totals.Taxes);
        if (returnNAAsTotalGain || Totals.ExtraWinnings < 0) {
            if (Totals.ExtraWinnings < 0) {
                // set the extrawinning to zero when we have closed/canceled selection, till the gain is refreshed.
                Totals.ExtraWinnings = "0";
            }
            Totals.Gain = Totals.GainEachWay = Totals.PossibleWinnings = Totals.GainPlusExtraWinnings = Totals.ReturnWithTaxes = $dict.bs("NotAvailable");
        }
        Totals.NumberOfBets = BetSlip.CurrentMode.getNumberOfBets();
        return Totals;
    }

    function getGainPlusExtraWinnings (freeBetContribution, gain, extraWinnings) {
        var shouldAddExtraWinnigs = (!(typeof SingleSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof SingleSPSlipMode)
            && ComboBonusProvider.ComboBonuses != null
            && BetSlip.CurrentMode.getTotalGainWithBonuses != undefined
            && !freeBetContribution);

        return BetSlip.CurrentMode.floorDecimal(shouldAddExtraWinnigs ? (gain + extraWinnings) : gain);
    }

    function GetExtraWinnings() {
        var totalExtraWinnings = 0;

        if (!(typeof SingleSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof SingleSPSlipMode || BetSlip.isCombinatorMode(BetSlip.CurrentMode)) &&
            ComboBonusProvider.ComboBonuses != null && BetSlip.CurrentMode) {
            if (typeof SystemSPSlipMode != "undefined" && BetSlip.CurrentMode instanceof SystemSPSlipMode &&
                BetSlip.CurrentMode.FreeBets && !BetSlip.CurrentMode.FreeBets[BetSlip.CurrentMode.SystemKey * 1]) {
                var sysKey = BetSlip.CurrentMode.SystemKey * 1;
                if (ComboBonusProvider.ComboBonuses[sysKey] && ComboBonusProvider.ComboBonuses[sysKey].AdditionalOdds &&
                    BetSlip.CurrentMode.SystemDeposit && BetSlip.CurrentMode.SystemDeposit.valueOf()) {
                    var vodd = ComboBonusProvider.ComboBonuses[sysKey].AdditionalOdds;
                    var deposit = BetSlip.CurrentMode.SystemDeposit.valueOf();
                    deposit = UserInfo.TaxProvider.applyTaxToDeposit(deposit);
                    var extraWinnings = BetSlip.CurrentMode.NamedSystemOdds[sysKey]
                        ? 0
                        : vodd * deposit;

                    totalExtraWinnings += extraWinnings;
                }
            } else {
                for (var key in BetSlip.CurrentMode.VariationsDeposits) {
                    if (ComboBonusProvider.ComboBonuses[key] && !BetSlip.CurrentMode.SelectedEWVariants[key] &&
                        BetSlip.CurrentMode.FreeBets && !BetSlip.CurrentMode.FreeBets[key]) {
                        if (ComboBonusProvider.ComboBonuses[key].AdditionalOdds && BetSlip.CurrentMode.VariationsDeposits[key]) {
                            var vodd = ComboBonusProvider.ComboBonuses[key].AdditionalOdds;
                            var deposit = BetSlip.CurrentMode.VariationsDeposits[key];
                            deposit = UserInfo.TaxProvider.applyTaxToDeposit(deposit);
                            var extraWinnings = vodd * deposit;

                            totalExtraWinnings += extraWinnings;
                        }
                    }
                }
            }
        }

        return totalExtraWinnings;
    }

    function showDisabledBetPlacementMessage() {
        var betslipMessageContainer = document.getElementById("betslip-verification-message"),
            betslipMessageText = document.getElementById('betslip-verification-message-text'),
            summaryTable = document.getElementById('idSummaryTable');

        if (UserInfo.current) {


            if (UserInfo.current.IsPlacingBetsDisabled) {
                var shouldShowMessageForDisableButton = !UserInfo.current.IsExternalyVerified && BetSlip.DisableBetPlaceForNotVerifiedUsers && RegulationRuleId != RegulationRuleType.Polish;

                if (shouldShowMessageForDisableButton) {
                    betslipMessageContainer && betslipMessageContainer.classList.remove("isHidden");
                    betslipMessageText && (betslipMessageText.innerHTML = $dict.bs("BetslipVerificationMessage").format("onclick=Application.navigateToDocumentUploadFacility(event)"));
                    summaryTable && summaryTable.classList.add("isHidden");
                }
                disablePlaceBetButton();
            }
            if (RegulationRuleId == RegulationRuleType.French) {
                if (UserInfo.current.IsIdVerificationPeriodFailed) {
                    disablePlaceBetButton();
                }
            }

        } else {
            betslipMessageContainer && betslipMessageContainer.classList.add("isHidden");
            summaryTable && summaryTable.classList.remove("isHidden");
        }
    }

    function placeBets(isUniBet) {
        BetSlip.isNotLoggedInMessage = false;
        if (!UserInfo.current || UserInfo.current == null) {
            //update seamless clients when not logged in users are trying to place bets
            if (IsAPIDefined() && typeof APIUser().notLoggedInBetPlacement === "function") {
                APIUser().notLoggedInBetPlacement();
            }
            if (typeof (SingleSPSlipMode) !== "undefined" && BetSlip.CurrentMode instanceof SingleSPSlipMode) {
                BetSlip.CurrentMode.setLastLineError(true);
            }
            var shouldDisplayLoginPopup = BetSlip.ShowLoginPopup;
            if (shouldDisplayLoginPopup) {
                BetSlipLogin.show();
            } else {
                getNotLoggedInMessage();
            }
        } else if (Array.getLength(Selections) > 0) {
            if (BetSlip.isAlertMessageEnabled) {
                if (BetSlip.CheckMaxBetOnPage && !BetSlip.CurrentMode.validateMaxBet()) {
                    return false;
                }

                disableOddsBlinking(Selections);

                var message = $dict.bs("ConfirmationMessage");
                if (confirm(message)) {
                    confirmPlaceBets.call(this, isUniBet);
                } else {
                    updateScrollbar();
                }
            } else {
                confirmPlaceBets.call(this, isUniBet);
            }
        }
    }

    function confirmPlaceBets(isUniBet) {
        if (BetSlip.BetSlipCaptureGPSLocation) {
            if (!navigator.geolocation || typeof navigator.geolocation.getCurrentPosition !== "function") {
                BetSlip.CurrentMode.setError($dict.bs("GeoLocationFeatureNotSupported"));
                BetSlip.CurrentMode.setBetInProcess(false, true);
                updateScrollbar();
                return;
            }

            var self = this;
            var location_options = {
                enableHighAccuracy: true,
                timeout: 30000,
                maximumAge: 0
            };

            navigator.geolocation.getCurrentPosition(function (position) {
                for (var key in BetSlip.Selections) {
                    var item = BetSlip.Selections[key];
                    item.Latitude = position.coords.latitude;
                    item.Longitude = position.coords.longitude;
                }
                self.performPlaceBets(isUniBet);
            }, function (err) {
                var msgCode = "GeoLocationUnknownError"
                if (err.code === GeoLocationErrors.PERMISSION_DENIED) {
                    msgCode = "GeoLocationDisabled";
                } else if (err.code === GeoLocationErrors.POSITION_UNAVAILABLE) {
                    msgCode = "GeoLocationUnavailable";
                } else if (err.code === GeoLocationErrors.TIMEOUT) {
                    msgCode = "GeoLocationTimeout";
                }
                BetSlip.CurrentMode.setError($dict.bs(msgCode));
                BetSlip.CurrentMode.setBetInProcess(false, true);
                updateScrollbar();
            }, location_options);
        }
        else {
            this.performPlaceBets(isUniBet);
        }
    }

    function performPlaceBets(isUniBet) {
        try {
            executeEvents(OnBetSend);
            this.placeBetsInMode(isUniBet);
        } catch (e) {
            BetSlip.CurrentMode.setError($dict.bs("PlaceBetUnexpectedJSError"));
            BetSlip.CurrentMode.setBetInProcess(false, true);
        }

        updateScrollbar();
    }

    function placeBetsInMode(isUniBet) {
        if (isUniBet && typeof BetSlip.CurrentMode.placeUniBets == "function") {
            BetSlip.CurrentMode.placeUniBets();
        } else {
            BetSlip.CurrentMode.placeBets();
        }
    }

    function cashOutBet(purchaseId, betId) {
        if (MyBets == null || UserInfo.current == null) {
            return false;
        }

        return MyBets.CashOut(purchaseId, betId);
    }

    //#region privates

    //#region selection updates

    var cartUpdateMonitorID = 0;
    var updateCartBetsLast;
    var updateCartBetsRunning = false;

    function getUpdateCartBetsRunning() {
        return updateCartBetsRunning;
    }

    function formatSelectionForUpdateRegularCartItem(request, selection, selectionsInCombo, isFromPush) {
        if (!selection.Initialized) return;
        var requestItem = selection.getUpdateRequest();
        var isItemInCombo = false;

        if (selectionsInCombo && Array.indexOf(selectionsInCombo, selection) != -1) {
            isItemInCombo = true;
        }

        var isBanker = BetSlip.CurrentMode.ID === "system" && !!selection.IsBanker;

        requestItem.push(isBanker);
        requestItem.push(selection.getComboGroupKey());
        requestItem.push(isItemInCombo);
        requestItem.push(isFromPush);
        requestItem.push(selection.LineTypeID);
        request.push(requestItem.join(","));
        return request;
    }

    function updateOnlyChangedSelections(data, subscriberKey, request) {
        for (var idx in data) {
            var selections = Array.findAll(BetSlip.Selections, function (sel) {
                return sel.EventID == idx
            });
            var selectionsInCombo = BetSlip.CurrentMode.getSelectionsInCombo != undefined ? BetSlip.CurrentMode.getSelectionsInCombo() : null;
            for (var key in selections) {
                request = formatSelectionForUpdateRegularCartItem(request, selections[key], selectionsInCombo, true);
            }
        }
        return request;
    }

    function subscribeToPush(data, subscriberKey) {
        var request = [];
        if (BetSlip.IsComboBonusEnabledForSite) {
            var selectionsInCombo = BetSlip.CurrentMode.getSelectionsInCombo != undefined ? BetSlip.CurrentMode.getSelectionsInCombo() : null;
            for (var key in BetSlip.Selections) {
                request = formatSelectionForUpdateRegularCartItem(request, BetSlip.Selections[key], selectionsInCombo, !!data[BetSlip.Selections[key].EventID]);
            }
        } else {
            request = updateOnlyChangedSelections(data, subscriberKey, request);
        }
        this.updateCartBets(request);
        Facade.clearDebounceEvents(subscriberKey);
    }

    function startCartMonitor() {
        if (cartUpdateMonitorID) return;
        updateCartBetsLast = 0;
        updateCartBetsRunning = false;
        cartUpdateMonitorID = setInterval(updateSelections, 500);
    }

    function updateSelections() {
        if (updateCartBetsRunning) return;
        var timeout = checkForHorseRacingSelections() ? HorseRacingCartUpdateTimeout : CartUpdateTimeout;

        if (window.Communicator) {
            timeout = Array.find(BetSlip.Selections, function (el) {
                el.BetType == 7
            }) ? timeout < BetSlip.IntervalForQAPreliveLines ? timeout : BetSlip.IntervalForQAPreliveLines : timeout;
        }
        if ((new Date()).getTime() - updateCartBetsLast < timeout) return;
        updateCartBets();
    }

    function updateCartBets(pushOddsRequest, cb, isFirstCall) {
        var request = [];
		var selectionsInCombo = BetSlip.CurrentMode.getSelectionsInCombo != undefined ? BetSlip.CurrentMode.getSelectionsInCombo() : null;
		
		if (BetSlip.CurrentMode && BetSlip.CurrentMode.isRunningGeoCheck) {
			return;
		}

        if (!pushOddsRequest) {
            for (var key in Selections) {
                var item = Selections[key];

                if (item.resetError && item.resetError() && item.removeError) item.removeError();

                if (!item.Valid && !item.Danger) continue;

                var requestItem = item.getUpdateRequest();
                var isItemInCombo = false;
                if (selectionsInCombo && Array.indexOf(selectionsInCombo, item) != -1) {
                    isItemInCombo = true;
                }
                var shouldBeUpdated = true;
                var isBanker = BetSlip.CurrentMode.ID === "system" && !!item.IsBanker;

                requestItem.push(isBanker);
                requestItem.push(item.getComboGroupKey());
                requestItem.push(isItemInCombo);
                requestItem.push(shouldBeUpdated);
                requestItem.push(item.LineTypeID);
                request.push(requestItem.join(","));
            }
        } else {
            request = pushOddsRequest;
        }
        if (request.length == 0) {
            if (Array.getLength(Selections) > 0) {
                // If all currently selected lines are closed try to remove them from the betslip after some period
                deleteSuspendedAndClosed();
            }
            return;
        }

        updateCartBetsRunning = true;

        var pageMethods = BetSlip.UseNewBettingStructure ? BettingPageMethods : PageMethods;

        pageMethods.updateRegularCartItems(

            request.join("@"),

            function (res) {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                if (cb) {
                    cb.Initialized = false;
                }
                var response = eval(res);
                parseCartBetsUpdate(response, isFirstCall);
                setRelatedBankers();

                if (cb) {
                    BetSlip.autoSelectMode();
                    itemUpdated(cb, true);
                }
                deleteSuspendedAndClosed();
                setComboPreloadedStake();
                BetSlip.autoSelectMode();
                updateBSFooter();
                executeEvents(OnSelectionsUpdated, BetSlip, response);
                isFirstCall && executeEvents(BetSlip.OnSelectionAdded, BetSlip, cb)
                BetSlip.showDisabledBetPlacementMessage();
            },

            function () {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                deleteSuspendedAndClosed();
                ComboBonusProvider.ComboBonuses = null;
                updateView();
            }
        );
    }

    function checkForHorseRacingSelections() {
        return Array.find(BetSlip.Selections, function (s) {
            return (s.BranchID == 61 || s.BranchID == 66) && Math.abs(serverdate.getTime() - Date.fromISO(s.EventDate).getTime()) <= HorseRacingOneHourLimitations;
        }) != null;
    }

    function updateSelection(cb) {
        var firstcall = cb.FirstCall;

        var pageMethods = BetSlip.UseNewBettingStructure ? BettingPageMethods : PageMethods;
        updateCartBetsRunning = true;

		if (BetSlip.CurrentMode && BetSlip.CurrentMode.isRunningGeoCheck) {
			return;
		}

        pageMethods.updateRegularCartItems(

            cb.getUpdateRequest().join(","),

            function (res) {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                cb.Initialized = false;
                parseCartBetsUpdate(eval(res), firstcall);
                BetSlip.autoSelectMode();

                itemUpdated(cb, true);

                // If we are on the event view tab of the live betting page we need to refreshe this view so that the event type headers
                // of the event display an icon which tells the user that they can combine this type with the selections already picked in the betslip
                if (cb.Live &&
                    typeof AdvancedLivePage != "undefined" && typeof ViewMode != "undefined" &&
                    AdvancedLivePage.current && AdvancedLivePage.current.viewMode == ViewMode.EventView &&
                    AdvancedLivePage.current.currentGame && AdvancedLivePage.current.currentGame.ID == cb.MasterEventID) {
                    executeEvents(OnAdd, BetSlip, cb);
                }

                BetSlip.focusOnNewBet && setTimeout(function () {
                    BetSlip.CurrentMode.focusOnStakeBox(cb);
                }, 0);
            },

            function () {
                updateCartBetsRunning = false;
                updateCartBetsLast = (new Date()).getTime();
                cb.processBetUpdate(-1);
                itemUpdated(cb, true);
            });
    }

    function parseCartBetsUpdate(arr, firstcall) {
        if (arr == -1000) {
            underMaintence();
            return;
        }

        var isPromoUpdated = false;

        if (JSON.stringify(ComboBonusProvider.ComboBonuses) !== JSON.stringify(arr["Folds"])) {
            ComboBonusProvider.ComboBonuses = arr["Folds"];
            isPromoUpdated = true;
        }

        var to_update = [];

        var multiLineItems = [];
        for (var key in arr) {
            var data = arr[key];
            var item = Selections[key];

            // Initialize the bonus data from server
            if (key === "Folds") {
                ComboBonusProvider.setComboBonuses(data);
            }

            if (item && item.processBetUpdate) {
                var init_call = !item.Initialized;
                item.HasGameStarted = (!!data) && (!!data.HasGameStarted);
                item.processBetUpdate(data, firstcall);

                addSelectionToMasterGroup(item);

                for (var i in Modes) Modes[i].selectionUpdated(item, init_call);

                if (item.Updated) executeEvents(OnItemUpdate, BetSlip, item);
                if (item.UpdatedOdds || item.UpdatedPoints) {
                    to_update.push(item.getGuiUpdateObject());
                    if (item.UpdatedPoints && item.Points) {
                        sbInternalMsgBus.internalMessageBus.emit(sbBetslipUtilsContracts.BetslipUtilChannels.Listen.Commands.get_status);
                    }
                }
                if (item.UpdatedLineClosed) executeEvents(OnLineClosedUpdate, BetSlip, item);

                if (item instanceof MultiLineItem) multiLineItems.push(item);
            }
        }

        //Check for multiline item errors after all single selections have been updated
        var itemsChanged = false;
        for (var items in multiLineItems) {
            var MLitem = multiLineItems[items];
            for (var j in MLitem.Selections) {
                var multiLineItemSelectionViewKey = multiLineItems[items].Selections[j];
                if (BetSlip.Selections[multiLineItemSelectionViewKey] && BetSlip.Selections[multiLineItemSelectionViewKey].Error) {
                    MLitem.Error = Selections[multiLineItemSelectionViewKey].Error;
                    MLitem.Valid = Selections[multiLineItemSelectionViewKey].Valid;
                    itemsChanged = true;
                    break;
                }
            }
        }

        if (itemsChanged) updateView();

        if (isPromoUpdated && BetSlip.CurrentMode && typeof (BetSlip.CurrentMode.updateViewOptions) == 'function') {
            updateView();
        }

        BetSlip.saveState();
        if (retainSelectionUVFlag) {
            // This check ensures that besides the current mode's view also the whole view
            // of the betslip gets visaully updated after retain selection is pressed
            retainSelectionUVFlag = false;
            updateView();
        }

        if (to_update.length) {
            executeEvents(OnOddsChanged, BetSlip, to_update);
        }
    }

    function acceptChangedOdds() {
        AcceptChangingOdds.acceptOdds();
        for (var key in Selections) {
            Selections[key].AcceptingOdds = Selections[key].Odds;
        }
        AcceptChangingOdds.toggleAcceptOddsButton(false);
    }

    //function setComboBonuses(data)
    //{
    //    BetSlip.ComboBonuses = data;
    //}

    function itemUpdated(cb, initCall) {
        for (var i in Modes)
            Modes[i].selectionUpdated(cb, initCall);

        executeEvents(OnItemUpdate, BetSlip, cb);
        //updateCartBets();
        updateView();
        BetSlip.updateBSFooter();
    }

    // Suspended and closed lines are automatically removed after some period of time if they still remain suspended/invalid
    function deleteSuspendedAndClosed() {
        for (var key in Selections) {
            var selection = Selections[key];

            if (selection.Valid && !selection.LineClosed) {
                selection.DeleteTimeSet = new Date(0);
                continue;
            }

            if (selection.DeleteTimeSet.getTime() == 0) {
                selection.DeleteTimeSet = new Date();
                continue;
            }

            if (typeof (CleanSlipTimeout) != "undefined") {
                if ((new Date()).getTime() - selection.DeleteTimeSet.getTime() > CleanSlipTimeout) {
                    removeBet(key);
                }

                var selectionLength = Object.keys(Selections).length;
                var hasSelectionFromOpenBet = Selections.any(function (sel) {
                    return sel.BetID
                });
                if (BetSlip.CurrentMode.ID === "editopenbet" && (selectionLength < 2 || !hasSelectionFromOpenBet)) {
                    exitFromEditMode();
                }
            }
        }
    }

    //#endregion

    //#region waiting bets routines

    var waitingMonitorID = 0;
    var updateWaitingBetsRunning = false;
    var updateWaitingBetsLast = 0;

    function startWaitingMonitor() {
        if (!ajaxDataServiceLoaded) {
            BetSlip.OnAjaxDataServiceLoaded.BetSlip = startWaitingMonitor;
            return;
        }

        var arr = getAllWaitingBets();
        if (arr.length > 0 && arr.IsNewSPPurchase) {
            AJAXDataService.Main.SPWaitingBets.start(arr);
        } else {
            if (BetSlip.MyBets) {
                setTimeout(BetSlip.MyBets.forceContentUpdate, 3000);
            }
            var isSPActive = AJAXDataService.Main.SPWaitingBets.isActive();
            if (isSPActive) {
                AJAXDataService.Main.SPWaitingBets.stop();
                updateScrollbar();
            }
        }
    }

    function SPUpdateWaitingBets() {
        var arr = getAllWaitingBets();
        if (!arr.IsNewSPPurchase) return;

        if (updateWaitingBetsRunning) return;
        var timeout = SlipUpdateTimeout;
        if (!UserInfo.current) timeout = timeout * notLoggedMultiplier;
        if ((new Date()).getTime() - updateWaitingBetsLast < timeout) return;

        var isActive = AJAXDataService.Main.SPWaitingBets.isActive();
        if (isActive) return;

        updateWaitingBetsRunning = true;

        if (arr.length == 0) {
            clearInterval(waitingMonitorID);
            waitingMonitorID = 0;
            return;
        }

        PageMethods.spGetWaitingBetsUpdateEx(arr,
            function (result) {
                updateWaitingBetsLast = (new Date()).getTime();
                updateWaitingBetsRunning = false;
                parseSlipUpdate(eval(result), true);
            },
            function (result, context) {
                updateWaitingBetsLast = (new Date()).getTime();
                updateWaitingBetsRunning = false;
            },
            this
        );

        setTimeout(function () {
            startWaitingMonitor();
        }, 3000);
    }

    function getAllWaitingBets() {
        var arr = [];
        var isNewSPPurchase = false;

        for (var i in Purchases) {
            arr = arr.concat(Purchases[i].getAllWaitingBets());

            //
            // Currently, we ask the server for updates to all waiting bets, passing a json array for all bets of a purchase.
            // For the new structure, the server keeps track of waiting purchases, instead of bets. And when we ask for updates
            // with all the bets of a purchase, the server returns an array with duplicate entries for every bet.
            // This can be optimized if we ask the server only with the info of the purchases, as shown below.
            // (If uncommenting the code, change the parsing of the json in this class - SPWaitingBetRequest).
            // This code is not thoroughly tested.
            //
            //var purchase = Purchases[i];
            //if (purchase.Status === 3)
            //{
            //    arr.push({
            //        RowID: purchase.WaitingBetID,
            //        State: purchase.Status
            //    });
            //}

            if (!isNewSPPurchase) {
                isNewSPPurchase = (Purchases[i].IsNewSPPurchase ? true : false);
            }
        }

        arr.IsNewSPPurchase = isNewSPPurchase;
        return arr;
    }

    function SPWaitingBetsAsyncData(cdata) {
        if (cdata) {
            parseSlipUpdate(cdata, true);
            startWaitingMonitor();
        } else {
            AJAXDataService.Main.SPWaitingBets.Data = false;
            SPUpdateWaitingBets(); // backup call
        }
    }

    function SPWaitingBetsAsyncError(status, message) {
        if ((status >= 500 && status <= 699) || status == 404 || status == 401) {
            AJAXDataService.Main.SPWaitingBets.Data = false;
            SPUpdateWaitingBets(); // backup call
        }
    }

    function parseSlipUpdate(data, asynccall) {
        var datahash = [];
        for (var i in data) datahash[data[i].RowID] = data[i];

        for (var i in Purchases) {
            Purchases[i].updateFromServer(datahash, asynccall);
            afterBetSuccessfullyPlaces(Purchases[i]);
        }

        //    this.updateCart();
        //Get balance call
        var updatedPurchases = Array.findAll(Purchases, function (p) {
            return !!datahash[p.WaitingBetID];
        });
        executeEvents(OnPurchaseUpdated, BetSlip, updatedPurchases);
    }

    function acceptOffer(rowid) {
        var bet = false;
        var purchase;
        for (var i in Purchases) {
            purchase = Purchases[i];
            if (bet = purchase.findBet(rowid)) break;
        }

        if (!bet) return;

        if (bet.IsNewSPPurchase) {
            var selArray = [];
            var req = {
                WaitingBetID: bet.WaitingBetID
            };

            if (bet.IsCashOutRequest) {
                req.CashOut = {
                    BetID: bet.Bet.BetID,
                    Amount: bet.Bet.CashOutAmount
                };
            } else {
                for (var sIdx in bet.Selections) {
                    var sel = bet.Selections[sIdx];
                    if (sel.IsMultiLine) continue;

                    var selObj = new Object();
                    selObj.ViewKey = sel.ViewKey;
                    selObj.YourBet = sel.getYourBetFormattedForPurchase(0);
                    selObj.Odds = sel.Odds;
                    selObj.Points = sel.Points;
                    selArray.push(selObj);
                }
            }

            req.Selections = selArray;

            var isWBCmd = false;
            var isSPCmd = (AJAXDataService.Main.command("accept_sp_waiting_bet", req) * 1 == 1)
        } else {
            var request = {};
            request.WainingBetID = bet.WaitingBetID;
            request.WaitingBetType = bet.BetType;
            if (bet.Selection) {
                request.WaitingBetOdds = bet.Selection.Odds;
                request.WaitingBetPoints = bet.Selection.Points;
                if (typeof request.WaitingBetPoints == "undefined") {
                    request.WaitingBetPoints = 0;
                }
            }

            var isWBCmd = (AJAXDataService.Main.command("accept_waiting_bet", request) * 1 == 1)
            var isSPCmd = false;
        }

        if (isWBCmd || isSPCmd) {
            bet.setWaiting();
        } else {
            bet.setRejected();
        }

        //before starting new monitor we need to give some time for previous monitor to finish processing
        var ref = this;
        setTimeout(function () {
            ref.startWaitingMonitor();
        }, 500);
        executeEvents(OnOfferAccepted, BetSlip, bet);
    }

    function declineOffer(rowid) {
        var bet = false;
        var purchase;
        for (var i in Purchases) {
            purchase = Purchases[i];
            if (bet = purchase.findBet(rowid)) break;
        }

        if (!bet) return;

        if (bet.IsNewSPPurchase) {
            AJAXDataService.Main.command("reject_sp_waiting_bet", {
                WaitingBetID: bet.WaitingBetID
            });
        } else {
            AJAXDataService.Main.command("reject_waiting_bet", {
                WainingBetID: bet.WaitingBetID,
                WaitingBetType: bet.BetType
            });
        }
        try { } catch (ex) { }

        try { } catch (ex) { }

        bet.setDeclined();
        bet.IsClientDecline = true;

        cleanPurchases();

        this.startWaitingMonitor();
        executeEvents(OnOfferDeclined, BetSlip, bet);
    }

    function exitFromEditMode() {
        if (!BetSlip.IsInEditMode) {
            return;
        }

        typeof UniSlipBlock != "undefined" && UniSlipBlock.ParentBlock && UniSlipBlock.ParentBlock.showHideTabsLine(true);

        // remove selections from open bets
        for (var k in Selections) {
            if (Selections[k].BetID) {
                BetSlip.removeBet(k);
            }
        }

        preselected_state = false;
        BetSlip.IsInEditMode = false;
        if (BetSlip.EnableAcceptChangingOdds && AcceptChangingOdds.isActive()) {
            AcceptChangingOdds.toggleAcceptOddsButton(false);
            AcceptChangingOdds.acceptOdds();
        }

        BetSlip.autoSelectMode();
        BetSlip.saveState();
    }

    //#endregion

    //#region Visual

    function blink() { }

    function cleanPurchases() {
        var i, purchasesContainer = document.getElementById("purchases"),
            purchasesTitle = document.getElementById("purchases_title");

        for (var i in Purchases) {
            var purchase = Purchases[i];
            if (purchase.isEmpty() || purchase.canRemove()) {
                purchase.remove();
                delete Purchases[i];
            }
        }

        if (Array.isEmpty(Purchases) && purchasesContainer) {
            purchasesContainer.parentElement.removeChild(purchasesContainer);
            purchasesTitle.parentElement.removeChild(purchasesTitle);
        }

        executeEvents(OnPurhaseRemoved, BetSlip, false);
    }

    function updateView() {
        // Externalized in UniSlip.ext.js to support mobile and tablet overrides
        BetSlip.updateView();
    }

    function openPurchaseDetails(id) {
        var purchase = Purchases[id];
        if (!purchase) return;

        if (purchase.getExpanded()) {
            purchase.collapse();
        } else {
            purchase.expand();
        }
    }

    //#endregion

    //#region State storage

    function saveState(index, skipSelections) {
        if (!WebStorage.isSupported() || BetSlip.CurrentMode.ID == 'yourbet') return;

        var state = {};
        state.viewGen = viewGen;
        state.Selections = [];
        index = index || '';

        if (!skipSelections) {
            for (var i in Selections)
                state.Selections.push(Selections[i].serialize());
        }

        state.Modes = [];

        for (var i in Modes)
            state.Modes.push(Modes[i].serialize());

        state.CurrentMode = BetSlip.CurrentMode.ID;
        state.preselected_state = preselected_state;
        state.UserCurrencyCode = BetSlip.UserCurrencyCode;
        state.activeView = activeView;
        state.isShowingOnlyCashOutBets = isShowingOnlyCashOutBets;
        state.IsInEditMode = BetSlip.IsInEditMode;

        if (Array.getLength(Purchases) > 0) {
            var bagItems = createPurchaseReadyState(Purchases);
            if (Array.getLength(bagItems.Items) > 0) StorageUtils.saveToStorage("complexPurchase" + index, JSON.stringify(bagItems), sessionStorage);

            var spItems = createSPPurchaseReadyState(Purchases);
            if (Array.getLength(spItems) > 0) StorageUtils.saveToStorage("spPurchase" + index, JSON.stringify(spItems), sessionStorage);
        }

        StorageUtils.saveToStorage("unislip" + index, JSON.stringify(state), sessionStorage);
        executeEvents(OnBetSlipStateSaved, BetSlip);
    }

    function restoreSelections(selections_data, viewKeyMapping) {
        if (!selections_data)
            return;

        for (var i in selections_data) {
            var sdata = selections_data[i];
            var selection = BaseSlipSelection.deserializeSelection(sdata);
            selection.IsRestored = true;

            if (!selection) continue;

            if (viewKeyMapping && (selection instanceof MultiLineItem)) {
                for (var sidx in selection.Selections) {
                    var selOldViewKey = selection.Selections[sidx];
                    var selNewViewKey = viewKeyMapping[selOldViewKey];
                    if (selNewViewKey) {
                        selection.Selections[sidx] = selNewViewKey;
                        selection.Positions[sidx].ViewKey = selNewViewKey;
                    }
                }
            }
            PushUpdateEvents[selection.EventID] = {
                ID: selection.EventID
            };
            selection.ViewKey = sdata.ViewKey;

            selection.processBetUpdate(sdata, true);
            Selections[selection.ViewKey] = selection;
            addSelectionToMasterGroup(selection);
            selection.StakeUpdatedByUser = false;
            for (var k in Modes) Modes[k].selectionAdded(selection);

			executeEvents(OnAdd, BetSlip, selection);
			executeEvents(BetSlip.OnSelectionAdded, BetSlip, selection);
        }

        var isLanguageChanged = WebStorage.getItemArrayFromSession(languageChangedFlag);
        WebStorage.sessionRemove(languageChangedFlag);

        if (!Array.isEmpty(Selections)) {
            if (isLanguageChanged) {
                updateCartBets();
            }

            startCartMonitor();
        }
    }

    function restorePurchaseSelections(purchaseKey, keepPurchasesAndAutoSelect) {
        var p = Purchases[purchaseKey];
        if (!p) return;

        if (!p.Final || p.PurchaseStatus == 3) return;

        if (isMyBetsViewActive()) {
            BetSlip.showBetSlipView();
        }

        var sdata = p.getSelections();
        var data = [];

        var viewKeyMapping = [];
        for (var k in sdata) {
            var oldViewKey = sdata[k].ViewKey;
            sdata[k].ViewKey = getViewKey();

            // no need to restore FreeBet, it's already used
            sdata[k].FreeBet = null;

            var alreadyInSlip = false;
            for (var key in Selections) {
                if (Selections[key].equals(sdata[k])) {
                    alreadyInSlip = true;
                    break;
                }
            }

            if (sdata[k].IsEachWaySelection || sdata[k].IsExtended) continue;
            if (!alreadyInSlip) {
                data.push(sdata[k].serialize());
                viewKeyMapping[oldViewKey] = sdata[k].ViewKey;
            }
        }

        restoreSelections(data, viewKeyMapping);
        startWaitingMonitor();

        if (typeof keepPurchasesAndAutoSelect !== "undefined" && keepPurchasesAndAutoSelect) {
            autoSelectMode();
        } else {
            // Remove the purchase when user clicks retain selections
            cleanPurchases();
        }

        retainSelectionUVFlag = true;

        updateScrollbar();
    }

    function restoreModes(modes) {
        for (var i in modes) {
            var mode_data = modes[i];
            var mode = Modes[mode_data.ID];
            if (mode) mode.deserialize(mode_data);
        }
    }

    function setState(state) {
        viewGen = state.viewGen;

        restoreSelections(state.Selections);

        if (state.Modes)
            restoreModes(state.Modes);

        BetSlip.restoreSlipMode(state.CurrentMode);
        preselected_state = state.preselected_state;
        activeView = state.activeView;
        isShowingOnlyCashOutBets = state.isShowingOnlyCashOutBets;
        BetSlip.IsInEditMode = state.IsInEditMode;

        if (state.UserCurrencyCode) {
            if (IsShowCurrencyCode) {
                BetSlip.UserCurrencyCode = state.UserCurrencyCode;
            } else {
                BetSlip.UserCurrencyCode = "";
            }
        }
    }

    function restoreState(index) {
        if (!WebStorage.isSupported()) return false;

        index = index || "";

        var sstate = StorageUtils.getFromStorage("unislip" + index, sessionStorage);
        if (!sstate) return false;
        var state = JSON.parse(sstate);

        setState(state);

        return true;
    }

    function getPurchases(index) {
        index = index || "";

        PageMethods.getRegularSlip(

            function (res) {
                var lines = eval(res);
                var complexPurchases = StorageUtils.getFromStorage("complexPurchase" + index, sessionStorage);
                var combo = JSON.parse(complexPurchases);

                if (Array.getLength(lines) < 1) {
                    WebStorage.sessionRemove("complexPurchase" + index);
                    return;
                }
                restoreComplexPurchases(lines, combo)
                restorePurchases(lines);
            });
    }

    function getSPPurchases(index) {
        index = index || "";

        PageMethods.getWaitingBetsSP(
            function (res) {
                var purchases = eval(res);
                if (!purchases || Array.getLength(purchases) == 0) {
                    return;
                }

                var storedPurchases = JSON.parse(StorageUtils.getFromStorage("spPurchase" + index, sessionStorage));
                if (Array.getLength(storedPurchases) == 0) {
                    WebStorage.sessionRemove("spPurchase" + index);
                    return;
                }

                var hasCOPurchasesForRestore = false;

                for (var idx in purchases) {
                    var item = purchases[idx];
                    var storedItem = Array.find(storedPurchases, function (sPur) {
                        return sPur.WaitingBetID == item.WBID;
                    });
                    if (!storedItem) continue;

                    var storedInPurchases = Array.find(Purchases, function (sPur) {
                        return sPur || sPur.WaitingBetID == item.WBID;
                    });
                    if (storedInPurchases) continue;


                    if (storedItem.Final) {
                        continue;
                    }

                    var selectionsInPurchase = [];

                    for (var i in storedItem.Selections) {
                        var sData = storedItem.Selections[i];
                        if (!sData) continue;

                        var sel = BaseSlipSelection.deserializeSelection(sData);
                        sel.ViewKey = sData.ViewKey;
                        //Use this to copy all properties from session storage for the purchase templates
                        sel.processBetUpdate(sData);
                        selectionsInPurchase.push(sel);
                    }

                    switch (storedItem.PurchaseTypeID) {
                        case SPPurchaseType.Single:
                            {
                                SPPurchaseBuilder.createSingleSlipPurchase(item, selectionsInPurchase, true, true)
                                break;
                            }
                        case SPPurchaseType.Combo:
                        case SPPurchaseType.Combinator:
                            {
                                SPPurchaseBuilder.createComboSlipPurchase(item, selectionsInPurchase, true, true, item.AltOddStyle)
                                break;
                            }
                        case SPPurchaseType.System:
                            {
                                SPPurchaseBuilder.createSystemSlipPurchase(item, selectionsInPurchase, true, true, item.AltOddStyle, storedItem.SystemKey);
                                break;
                            }
                        case SPPurchaseType.Teaser:
                            {
                                SPPurchaseBuilder.createTeaserSlipPurchase(item, selectionsInPurchase, true, true);
                                break;
                            }
                        case SPPurchaseType.StraightCombo:
                            {
                                SPPurchaseBuilder.createComboSlipPurchase(item, selectionsInPurchase, true, true, item.AltOddStyle, SPPurchaseType.StraightCombo)
                                break;
                            }
                        case SPPurchaseType.CashOutSingle:
                        case SPPurchaseType.CashOutCombo:
                            {
                                hasCOPurchasesForRestore = true;
                                break;
                            }
                        case SPPurchaseType.UKPurchase:
                            {
                                SPPurchaseBuilder.createUKPurchase(item, selectionsInPurchase, true, true, item.AltOddStyle);
                                break;
                            }
                    }
                }

                // Now, we need to restore cash out purchases (if any).
                // But first, we have to wait for MyBets to be initalized and have its purchases content downloaded.
                if (hasCOPurchasesForRestore) {
                    if (UserInfo.current) {
                        restoreCashOutPurchases(purchases);
                    } else {
                        UserInfo.onLogin["BetSlip_CashOutPurchaseRestore"] = function () {
                            restoreCashOutPurchases(purchases);
                            delete UserInfo.onLogin["BetSlip_CashOutPurchaseRestore"];
                        }
                    }
                }
            });
    }

    function restoreCashOutPurchases(purchases) {
        initMyBets();

        if (!BetSlip.MyBets) {
            return;
        }

        var myBetsPurchases = BetSlip.MyBets.getPurchases();
        if (myBetsPurchases.length > 0) {
            handleCashOutPurchaseRestore(purchases);
        } else {
            BetSlip.MyBets.addOnContentChanged("BetSlip_CashOutPurchaseRestore", function (sender, data) {
                if (data.isFirstUpdate) {
                    handleCashOutPurchaseRestore(purchases);
                    BetSlip.MyBets.removeOnContentChanged("BetSlip_CashOutPurchaseRestore");
                }
            });

            BetSlip.MyBets.forceContentUpdate();
        }
    }

    function handleCashOutPurchaseRestore(purchases) {
        if (!purchases || Array.getLength(purchases) == 0) {
            return;
        }

        for (var idx in purchases) {
            var item = purchases[idx];
            if (item.IsCashOutRequest) {
                BetSlip.MyBets.restoreCashOutPurchase(item);
            }
        }
    }

    function buyPointsAdd(viewKey) {
        var ddElement = document.getElementById("ddBuyPoints_" + viewKey);
        var odds = ddElement.options[ddElement.selectedIndex].getAttribute("odds");
        var points = ddElement.value;

        var cb = Selections[viewKey];
        if (!cb) return false;

        if (points == cb.Points && odds == cb.Odds) {
            cb.IsBuyPoint = false;

            cb.BuyPointsOdds = -1;
            cb.BuyPointsPoints = -1;
            cb.BuyPointSelectedIndex = null;
        } else {
            cb.IsBuyPoint = true;

            cb.BuyPointsOdds = odds;
            cb.BuyPointsPoints = parseFloat(points);
            cb.BuyPointSelectedIndex = points;
        }
        updateCartBets();
        //updateSelection(cb);
        updateBSFooter();
    }

    function createPurchaseReadyState(purch_bag) {
        var currentBag = {};
        currentBag.Items = [];
        for (var i in purch_bag) {
            var purch = purch_bag[i];

            if (typeof ComboBetsPurchase != "undefined" && purch instanceof ComboBetsPurchase) {
                for (var key in purch.Bets) {
                    var bet = purch.Bets[key];
                    var comboBet = {};
                    comboBet.Selections = [];

                    comboBet.BetID = bet.BetID;
                    comboBet.BetStatus = bet.BetStatus;
                    comboBet.Deposit = bet.Deposit;
                    comboBet.Final = bet.Final;
                    comboBet.IsConfigured = bet.IsConfigured;
                    comboBet.LastUpdate = bet.LastUpdate;
                    comboBet.Odds = bet.Odds;
                    comboBet.Rate = bet.Rate;
                    comboBet.RowID = bet.RowID;
                    comboBet.Status = bet.Status;
                    comboBet.StatusText = bet.StatusText;
                    comboBet.VariationType = bet.VariationType;
                    comboBet.ViewKey = bet.ViewKey;
                    comboBet.WaitingBetID = bet.WaitingBetID;
                    comboBet.ConstructorType = "ComboBetsPurchase";

                    currentBag.Items.push(comboBet);
                }
            } else if (typeof TeaserBetsPurchase != "undefined" && purch instanceof TeaserBetsPurchase) {
                var bet = purch.Bet;
                var teaserBet = {};
                teaserBet.Selections = [];

                teaserBet.ConstructorType = "TeaserBetsPurchase";
                teaserBet.TeaserTypeID = bet.TeaserTypeID;
                teaserBet.BetID = bet.BetID;
                teaserBet.BetStatus = bet.BetStatus;
                teaserBet.Deposit = bet.Deposit;
                teaserBet.Final = bet.Final;
                teaserBet.IsConfigured = bet.IsConfigured;
                teaserBet.LastUpdate = bet.LastUpdate;
                teaserBet.Odds = bet.Odds;
                teaserBet.Rate = bet.Rate;
                teaserBet.RowID = bet.RowID;
                teaserBet.Status = bet.Status;
                teaserBet.StatusText = bet.StatusText;
                teaserBet.VariationType = bet.VariationType;
                teaserBet.ViewKey = bet.ViewKey;
                teaserBet.WaitingBetID = bet.WaitingBetID;

                currentBag.Items.push(teaserBet);
            } else if (typeof SystemBetsPurchase != "undefined" && purch instanceof SystemBetsPurchase) {
                for (var key in purch.Bets) {
                    var bet = purch.Bets[key];
                    var systemBet = {};
                    systemBet.Selections = [];

                    systemBet.BetID = bet.BetID;
                    systemBet.BetStatus = bet.BetStatus;
                    systemBet.Deposit = bet.Deposit;
                    systemBet.Final = bet.Final;
                    systemBet.IsConfigured = bet.IsConfigured;
                    systemBet.LastUpdate = bet.LastUpdate;
                    systemBet.Odds = bet.Odds;
                    systemBet.Rate = bet.Rate;
                    systemBet.RowID = bet.RowID;
                    systemBet.Status = bet.Status;
                    systemBet.StatusText = bet.StatusText;
                    systemBet.VariationType = bet.VariationType;
                    systemBet.ViewKey = bet.ViewKey;
                    systemBet.WaitingBetID = bet.WaitingBetID;
                    systemBet.ConstructorType = "SystemBetsPurchase";

                    systemBet.VariationsDeposits = bet.VariationsDeposits;
                    systemBet.Variants = bet.Variants;

                    currentBag.Items.push(systemBet);
                }
            }
        }
        return currentBag;
    }

    function createSPPurchaseReadyState(purchases) {
        var serialArr = [];

        for (var i in purchases) {
            var purch = purchases[i];

            if (!purch.IsNewSPPurchase /* || purch.Final*/) continue;

            serialArr.push(purch.serialize());
        }
        return serialArr;
    }

    function restoreComplexPurchases(lines, combo) {
        if (typeof combo != "undefined" && combo != null) {
            if (combo != "" && combo != "{}") {
                var items_for_restore = [];
                for (var key in combo.Items) {
                    for (var itemkey in lines) {
                        var sel = false;
                        if (combo.Items[key].RowID == lines[itemkey].RowID) {
                            if ("Score1" in lines[itemkey] && lines[itemkey].IsShureBet) {
                                sel = new RegularLiveSlipSelection(lines[itemkey].MasterEventID, lines[itemkey].EventID, lines[itemkey].LineGroupID,
                                    lines[itemkey].BetTypeID, lines[itemkey].BetSide, lines[itemkey].Odds, lines[itemkey].Points, lines[itemkey].Score1, lines[itemkey].Score2);
                            } else if ("Score1" in lines[itemkey] && lines[itemkey].IsQAShureBet) {
                                sel = new QALiveSlipSelection(lines[itemkey].MasterEventID, lines[itemkey].EventID, lines[itemkey].LineID, lines[itemkey].Odds, lines[itemkey].Score1, lines[itemkey].Score2);
                            } else if (lines[itemkey].IsShureBet) {
                                sel = new RegularSlipSelection(lines[itemkey].MasterEventID, lines[itemkey].EventID, lines[itemkey].LineGroupID, lines[itemkey].LineID, lines[itemkey].Odds, lines[itemkey].Points);
                            } else if (lines[itemkey].IsQAShureBet) {
                                sel = new QASlipSelection(lines[itemkey].MasterEventID, lines[itemkey].EventID, lines[itemkey].LineID, lines[itemkey].Odds);
                            }
                            sel.processBetUpdate(lines[itemkey]);
                            sel.ViewKey = getViewKey();
                            combo.Items[key].Selections.push(sel);
                            //!!! IMPORTANT
                            lines[itemkey].InCombo = true;
                            if (combo.Items[key].VariationsDeposits) {
                                for (var vkey in combo.Items[key].VariationsDeposits) {
                                    if (combo.Items[key].VariationsDeposits[vkey] == null) delete combo.Items[key].VariationsDeposits[vkey];
                                }
                            }
                            if (combo.Items[key].Variants) {
                                for (var itmkey in combo.Items[key].Variants) {
                                    if (combo.Items[key].Variants[itmkey] == null) {
                                        delete combo.Items[key].Variants[itmkey];
                                        continue;
                                    }
                                    for (var innerKey in combo.Items[key].Variants[itmkey]) {
                                        for (var sInnerkey in combo.Items[key].Variants[itmkey][innerKey]) {
                                            if (combo.Items[key].Variants[itmkey][innerKey][sInnerkey].LineID === sel.LineID) {
                                                combo.Items[key].Variants[itmkey][innerKey][sInnerkey] = sel;
                                            }
                                        }
                                    }
                                }
                            }

                        }
                    }
                    var current_bet = null;
                    if (combo.Items[key].ConstructorType == "ComboBetsPurchase") {
                        current_bet = Modes["combo"].createBet(combo.Items[key].Selections, combo.Items[key].Deposit, combo.Items[key].VariationType);
                    } else if (combo.Items[key].ConstructorType == "TeaserBetsPurchase") {
                        current_bet = Modes["teaser"].createBet(combo.Items[key].Selections, combo.Items[key].Deposit, combo.Items[key].TeaserTypeID, combo.Items[key].Rate);
                        current_bet.TeaserTypeID = combo.Items[key].TeaserTypeID;

                    } else if (combo.Items[key].ConstructorType == "SystemBetsPurchase") {
                        current_bet = Modes["system"].createBet(combo.Items[key].Selections, combo.Items[key].Deposit, combo.Items[key].VariationType, combo.Items[key].VariationsDeposits, combo.Items[key].Variants);

                        current_bet.VariationType = combo.Items[key].VariationType; // !!! IMPORTANT
                        current_bet.VariationsDeposits = combo.Items[key].VariationsDeposits;
                        current_bet.Variants = combo.Items[key].Variants;
                    }
                    // !!! IMPORTANT
                    current_bet.RowID = combo.Items[key].RowID;
                    current_bet.ViewKey = combo.Items[key].ViewKey ? combo.Items[key].ViewKey : getViewKey();
                    current_bet.WaitingBetID = combo.Items[key].WaitingBetID;
                    current_bet.Final = combo.Items[key].Final;
                    current_bet.Status = combo.Items[key].Status;
                    current_bet.StatusText = combo.Items[key].StatusText;
                    current_bet.ConstructorType = combo.Items[key].ConstructorType;

                    if (Array.getLength(current_bet.Selections) > 0) items_for_restore.push(current_bet);
                }
                restoreCurrentPurchase(items_for_restore)
            }
        }
    }

    function getSelectionsCount() {
        var selections = this.Selections;
        var selectionsLength = Array.getLength(selections, function (s) {
            return s && !PulseBettingEventTypeIds[s.EventTypeID];
        });

        if (selections.any(function (sel) {
            return sel.IsMultiLine
        })) {
            var castSelections = Array.findAll(selections, function (sel) {
                return sel.CastSelection && !sel.VisibleCastSelection
            });
            selectionsLength = selectionsLength - Array.getLength(castSelections);
        }

        return selectionsLength;
    }

    function restorePurchases(slipItems) {
        var rpurchases = [];
        var SingleMode = Modes["single"];

        if (!SingleMode) return;

        for (var key in slipItems) {
            var initObj = slipItems[key];

            if (initObj.IsShureBet || initObj.IsQAShureBet) {
                if (initObj.InCombo) continue;
                var ksp = initObj.PurchaseID ? initObj.PurchaseID : getViewKey();
                rpurchases[ksp] = SingleMode.restorePurchase(initObj, rpurchases[ksp]);
                continue;
            }
        }

        for (var i in rpurchases) {
            var p = rpurchases[i];
            Purchases[p.ViewKey] = p;
        }

        if (!Array.isEmpty(Purchases)) {
            startWaitingMonitor();
        }
        updateView();
    }

    function restoreSPPurchases(serialArr) {
        if (!serialArr || Array.getLength(serialArr) == 0) return;

        for (var idx in serialArr) {
            var pdata = serialArr[idx];
            var purch = new SPPurchase();
            purch.deserialize(pdata);
            Purchases[purch.ViewKey] = purch;
        }

        if (!Array.isEmpty(Purchases)) {
            startWaitingMonitor();
        }
        updateView();
    }

    function restoreCurrentPurchase(items_for_restore) {
        if (Array.getLength(items_for_restore) <= 0) return;

        for (var i in items_for_restore) {
            var items = [];
            items.push(items_for_restore[i]);

            var purchaseViewKey = BetSlip.getViewKey();
            var purch = null;

            if (items_for_restore[i].ConstructorType == "ComboBetsPurchase") {
                purch = new ComboBetsPurchase(items, purchaseViewKey);
            } else if (items_for_restore[i].ConstructorType == "TeaserBetsPurchase") {
                purch = new TeaserBetsPurchase(items_for_restore[i], purchaseViewKey);
            } else if (items_for_restore[i].ConstructorType == "SystemBetsPurchase") {
                var purch = new SystemBetsPurchase(items, items_for_restore[i].VariationType, purchaseViewKey);
            }
            if (purch != null) BetSlip.addPurchase(purch, true, undefined, true);
        }
        if (!Array.isEmpty(Purchases)) startWaitingMonitor();
        updateView()
    }

    //#endregion

    // #endregion

    // Gets an alternative to the currentOddStyle which can be special for the current slip mode.
    function getAlternativeOddsStyle(modeID) {
        if (modeID && Modes[modeID] && typeof Modes[modeID].getAlternativeOddsStyle == "function")
            return Modes[modeID].getAlternativeOddsStyle();

        if (typeof (BetSlip.CurrentMode.getAlternativeOddsStyle) == "function")
            return BetSlip.CurrentMode.getAlternativeOddsStyle();

        return null;
    }

    // This function is added and to new and old betslip.
    // It assures that we will receive the betslip selections from the correct property
    function getBetSlipSelections() {
        return this.Selections;
    }

    function showAlternateLine(viewKey) {
        var selection = this.Selections[viewKey];
        if (!selection || !selection.AlternateLineID) return;

        selection.LineID = selection.AlternateLineID;
        selection.Odds = selection.AlternateLineOdds;
        selection.LineTypeID = selection.AlternateLineTypeID;
        selection.FirstCall = true;
        this.CurrentMode.recalcEachWay(viewKey);
        updateCartBets(null, selection);
    }

    function CheckForEachWayIncluded() {
        if (IsUKBetSlip && BetSlip.CurrentMode.CheckForUKEachWayIncluded) return BetSlip.CurrentMode.CheckForUKEachWayIncluded();
        if (BetSlip.CurrentMode.CheckForEachWayIncluded) return BetSlip.CurrentMode.CheckForEachWayIncluded();

        for (var idx in BetSlip.Selections) {
            var item = BetSlip.Selections[idx];
            if (item.BetType == 7 && item.EachWayIncluded) {
                return true;
            }
        }
        return false;
    }

    function CheckForEachWayEnabled(key) {
        if (BetSlip.CurrentMode.CheckForEachWayEnabled) return BetSlip.CurrentMode.CheckForEachWayEnabled(key);

        if (key) { //For single bets
            var item = BetSlip.Selections[key];
            if (item && item.BetType == 7 && item.IsEachWayEnabled) {
                return true;
            } else {
                return false;
            }
        }

        for (var idx in BetSlip.Selections) {
            var item = BetSlip.Selections[idx];
            if (item.BetType == 7 && item.IsEachWayEnabled) {
                return true;
            }
        }
        return false;
    }

    function recalcEachWay(viewKey) {
        if (BetSlip.CurrentMode) {
            BetSlip.CurrentMode.recalcEachWay(viewKey);
        }
    }

    function getTotalGain() {
        var totWin = 0;
        var EwIncluded = BetSlip.CheckForEachWayIncluded();

        if (EwIncluded)
            totWin = BetSlip.CurrentMode.getTotalGain() + BetSlip.CurrentMode.getEachWayTotalGain();
        else
            totWin = BetSlip.CurrentMode.getTotalGain();

        return totWin;
    }

    function CheckForSPIncluded() {
        if (BetSlip.CurrentMode != 'undefined') {
            return BetSlip.CurrentMode.CheckForSPIncluded();
        }

        return (BetSlip.Selections.any(function (sel) {
            return sel.isSPSelection();
        }));
    }

    function CheckForNoOddsSelectionIncluded() {
        if (BetSlip.CurrentMode != 'undefined') {
            return BetSlip.CurrentMode.CheckForNoOddsSelectionIncluded();
        }

        return BetSlip.Selections.any(function (sel) {
            return sel.Odds == 0;
        });
    }

    function CheckForCastSelectionIncluded() {
        return BetSlip.Selections.any(function (sel) {
            return sel.isCastSelection();
        });
    }

    function clearEW() {
        for (var idx in BetSlip.Selections) {
            var item = BetSlip.Selections[idx];
            if (item.BetType == 7 && item.EachWayIncluded) {
                item.EachWayIncluded = false;
                item.EachWaySingleIncluded = false;
                item.EachWayMultiplesIncluded = false;
            }
        }
        BetSlip.CurrentMode.EWVariants = [];
        BetSlip.CurrentMode.SelectedEWVariants = [];
        BetSlip.CurrentMode.SelectedSystemEWVariants = [];
        BetSlip.CurrentMode.EWSelections = [];
    }

    function PlaceUNIBets() {
        placeBets(true);
    }

    // Rebuild normally if UK mode off
    // Always rebuild if UK mode on
    function shouldRebuildVariantStake() {
        return BetSlip.CurrentMode instanceof ComboSlipMode;
    }

    // Update custom scrollbar
    function updateScrollbar() {
        // Externalized in UniSlip.ext.js to support mobile and tablet overrides
        BetSlip.updateScrollbar();
    }

    // Scroll to the end of the scroll pane
    function scrollToEnd() {
        // Externalized in UniSlip.ext.js to support mobile and tablet overrides
        BetSlip.scrollToEnd();
    }

    function checkForMultiLineItem() { //Check if the slip contains MultiLineItems. If yes, we must force single slip mode
        for (var vk in Selections) {
            var sel = Selections[vk];
            if (sel instanceof MultiLineItem)
                return true;
        }
        return false;
    }

    function updateMyBetsView(addPurchases, removePurchases, isCompleteRedraw, initBets) {
        if (BetSlip.isMyBetsViewActive()) {
            if (initBets) {
                BetSlip.MyBets.initPartialCashoutState();
            }

            // Externalized in UniSlip.ext.js
            BetSlip.updateMyBetsView(addPurchases, removePurchases, isCompleteRedraw);
        }
    }

    function updateMyBetsViewPurchase(purchase) {
        if (BetSlip.isMyBetsViewActive()) {
            // Externalized in UniSlip.ext.js
            BetSlip.updateMyBetsViewPurchase(purchase);
        }
    }

    function updateMyBetsCounter() {
        // Externalized in UniSlip.ext.js
        BetSlip.updateMyBetsCounter();
    }

    // This method is called from UniSlip.ext.js when the active view is changed
    // (when switching between Bet Slip and My Bets tabs) This method is relevant only for the website
    function onViewChanged(viewId) {
        activeView = viewId;

        if (viewId == BetSlipViews.MyBets) {
            if (MyBets) {
                updateMyBetsView([], [], false, true);
                MyBets.addCashoutUpdatesSubsriber('WebsiteMyBets');
            }
        } else {
            updateView();
            MyBets && MyBets.removeCashoutUpdatesSubsriber('WebsiteMyBets');
        }

        BetSlip.saveState();
    }

    function RoundToStakeRounding(deposit, element) {
        var newDeposit = roundDepositValue(deposit);
        if (element && !isNaN(newDeposit)) {
            UpdateElementStake(newDeposit, element);
        }
        return newDeposit;
    }

    function roundDepositValue(deposit) {
        if (BetSlip.StakeRounding === BetSlip.StakeRoundingMethods.TwoDecimalPoints) {
            return Math.floor((deposit * 100).toFixed(4)) / 100;
        } else {
            return Math.floor(deposit);
        }
    }

    function UpdateElementStake(newDeposit, element) {
        var value = element.value;  
        var initialCursorPosition = BetSlip.CurrentMode.getElementCursorPosition(element);
        var newValue = StakeInputParser.getValueForRedraw(value, newDeposit);
        if(newValue != value) {
            element.value = newValue;
            initialCursorPosition && BetSlip.CurrentMode.setCursorAtPosition(element, initialCursorPosition + (newValue.length - value.length));
        }
    }

    function SanitizeNumberString(value) {
        return StakeInputParser.parse(value);
    }

    function getStakeBoxValue(value) {
        var valueType = typeof(value);
        var valueToFormat = valueType === 'number' ? value :
                            (valueType === 'string' ?  StakeInputParser.parse(value) : 0);
        return MoneyFormat.format(valueToFormat);
    }

    function getNotLoggedInMessage() {
        // Externalized in UniSlip.ext.js to support mobile and tablet overrides
        BetSlip.getNotLoggedInMessage();
        BetSlip.isNotLoggedInMessage = true;
    }

    function processPurchaseToGA(purchase) {
        if (purchase.Status == SPPurchaseStatus.Accepted) {
            window.dataLayer = window.dataLayer || [];
            var betTypes = [];
            if (purchase.Bets) {
                purchase.Bets.each(function (bet) {
                    var betTypePropName;
                    ["BetType", "BetTypeID"].each(function (propertyName) {
                        if (bet.hasOwnProperty(propertyName)) {
                            betTypePropName = propertyName;
                        }
                    });
                    if (betTypePropName) {
                        var betType = bet[betTypePropName];
                        if (!betTypes.contains(betType)) {
                            betTypes.push(betType);
                        }
                    }
                });
            }
            var data = {
                'event': 'bet-success',
                'eventCategory': 'Placed bet',
                'eventAction': purchase.PurchaseID,
                'eventLabel': betTypes.join(),
                'eventValue': purchase.GBPDeposit
            };
            dataLayer.push(data);
        }
    };

    function afterBetSuccessfullyPlaces(purchases) {
        for (var key in BetSlip.Selections) {
            var sel = BetSlip.Selections[key];
            if (sel && sel.Odds !== sel.AcceptingOdds) {
                executeEvents(OnOddsChanged, BetSlip);
            }
        }

        if (IsAPIDefined() && typeof APIUser().betAccepted === "function") {
            var purchaseIDsForSeamlessClients = getPurchasesIDsForSeamlessClients(purchases);
            if (Array.getLength(purchaseIDsForSeamlessClients) > 0) {
                APIUser().betAccepted(purchaseIDsForSeamlessClients);
            }
        }

        setTimeout(function () {
            UserInfo.updateBalance();
        }, UserInfo.UpdateBalanceTreshhold);

        setTimeout(function () {
            UserInfo.updateBalance();
        }, 5000);
    }

    function getPurchasesIDs(purchases) {
        var purchaseIDs = [];

        if (purchases.length) {
            for (var i = 0; i < purchases.length; i++) {
                var currentPurchase = purchases[i];
                if (currentPurchase.PurchaseID !== 0 && currentPurchase.Status == SPPurchaseStatus.NotAuthorized) {
                    purchaseIDs[currentPurchase.PurchaseID] = currentPurchase.PurchaseID;
                }
                processPurchaseToGA(currentPurchase);
            }
        } else {
            if (purchases.PurchaseID !== 0 && purchases.Status == SPPurchaseStatus.NotAuthorized) {
                purchaseIDs[purchases.PurchaseID] = purchases.PurchaseID;
            }
            processPurchaseToGA(purchases);
        }
        if (IsAPIDefined() && typeof APIUser().betAccepted === "function") {
            var purchaseIDsForSeamlessClients = getPurchasesIDsForSeamlessClients(purchases);
            if (Array.getLength(purchaseIDsForSeamlessClients) > 0) {
                APIUser().betAccepted(purchaseIDsForSeamlessClients);
            }
        }

    }

    function getPurchasesIDsForSeamlessClients(purchases) {
        var purchaseIDs = [];

        if (purchases.length) {
            for (var i = 0; i < purchases.length; i++) {
                var currentPurchase = purchases[i];
                if (currentPurchase.PurchaseID != 0 && typeof currentPurchase.PurchaseID != "undefined") {
                    purchaseIDs[currentPurchase.PurchaseID] = currentPurchase.PurchaseID;
                }
            }
        } else {
            if (purchases.PurchaseID != 0 && typeof purchases.PurchaseID != "undefined") {
                purchaseIDs[purchases.PurchaseID] = purchases.PurchaseID;
            }
        }

        return purchaseIDs;
    }

    function getPurchasesIDsForSeamlessClients(purchases) {
        var purchaseIDs = [];

        if (purchases.length) {
            for (var i = 0; i < purchases.length; i++) {
                var currentPurchase = purchases[i];
                if (currentPurchase.PurchaseID != 0 && typeof currentPurchase.PurchaseID != "undefined") {
                    purchaseIDs[currentPurchase.PurchaseID] = currentPurchase.PurchaseID;
                }
            }
        } else {
            if (purchases.PurchaseID != 0 && typeof purchases.PurchaseID != "undefined") {
                purchaseIDs[purchases.PurchaseID] = purchases.PurchaseID;
            }
        }

        return purchaseIDs;
    }


    function getPurchasesIDsForSeamlessClients(purchases) {
        var purchaseIDs = [];

        if (purchases.length) {
            for (var i = 0; i < purchases.length; i++) {
                var currentPurchase = purchases[i];
                if (currentPurchase.PurchaseID != 0 && typeof currentPurchase.PurchaseID != "undefined") {
                    purchaseIDs[currentPurchase.PurchaseID] = currentPurchase.PurchaseID;
                }
            }
        } else {
            if (purchases.PurchaseID != 0 && typeof purchases.PurchaseID != "undefined") {
                purchaseIDs[purchases.PurchaseID] = purchases.PurchaseID;
            }
        }

        return purchaseIDs;
    }


    function validatePurchaseWithCountryTaxes(taxAmount, totalDeposit) {
        var selectionWhichHaveOddsZero = Selections.filter(function (s) {
            return s.isSPSelection() || s.isCastSelection();
        });
        if (selectionWhichHaveOddsZero.length > 0) {
            return false;
        }
        var totalGain = getTotalGain();
        var hasTax = totalGain - taxAmount <= totalDeposit;

        return hasTax;
    }

    function taxesApplier(total) {
        var taxRow = document.getElementById("taxRow");
        var returnRow = document.getElementById("returnRow");

        if (!(taxRow || returnRow)) {
            return;
        }

        var showTaxes = !!UserInfo.TaxProvider.getBetSlipTaxAmount(total.ReturnWithTaxes);
        var showReturnRow = showTaxes && UserInfo.TaxProvider.showReturnAfterTaxRowInBetSlip();

        if (showTaxes) {
            document.getElementById("taxConst").innerHTML = ($dict.bs("RegulatoryTax") ? $dict.bs("RegulatoryTax").format(UserInfo.TaxProvider.TaxInfo.CountryTax) : '');
            var taxTooltipMsg = document.getElementById("taxToolipMessage");
            if (taxTooltipMsg && taxTooltipMsg.getAttribute("data-tooltip") == "") {
                taxTooltipMsg.setAttribute("data-tooltip", $dict.bs("GermanTaxTooltip").format(UserInfo.TaxProvider.TaxInfo.CountryTax));
            }
            if (UserInfo.TaxProvider.showTaxAmountInBetSlip()) {
                document.getElementById("taxPercent").innerHTML = BetSlip.formatMoney(total.Taxes);
            } else {
                var taxCurrencySymbol = document.getElementById("taxPercentCurrency");
                taxCurrencySymbol && taxCurrencySymbol.classList.add("isHidden");
            }
            document.getElementById("idBetSlipSummaryArea").classList.add("hasTaxation");
            taxRow.classList.remove("isHidden");
            setTaxTooltip();
        } else {
            taxRow.classList.add("isHidden");
            document.getElementById("idBetSlipSummaryArea").classList.remove("hasTaxation");
        }

        if (showReturnRow) {
            document.getElementById("returnWithTaxes").innerHTML = BetSlip.formatMoney(total.ReturnWithTaxes);
            document.getElementById("returnAfterTaxConst").innerHTML = ($dict.bs("ReturnAfterTax") ? $dict.bs("ReturnAfterTax") : '');
            returnRow.classList.remove("isHidden");
        }
        else {
            returnRow.classList.add("isHidden");
        }
    }

    function setTaxTooltip() { }

    function sendToJSApi() {
        if (IsAPIDefined() && typeof APIUser().betPlaced === "function") {
            var numberOfBets = document.getElementById("numberOfBets").innerHTML;
            var totalDeposit = document.getElementById("totalDeposit").innerHTML;
            var totalGain = document.getElementById("totalGain").innerHTML;
            var betslipMode = BetSlip.CurrentMode.Name;

            APIUser().betPlaced(numberOfBets, totalDeposit, totalGain, betslipMode);
        }

        if (typeof postNativeCallback != "undefined") {
            postNativeCallback(nativeCallbackKind.BetPlaced);
        }
    }

    function sendBetLimitsErrorToJSApi(bettingLimit) {
        if (IsAPIDefined() && typeof APIUser().ShowBetLimitPopUp === "function") {
            APIUser().ShowBetLimitPopUp(bettingLimit.BettingLimitType, bettingLimit.BettingLimitDays, bettingLimit.RemainingBettingLimit, bettingLimit.CurrentBetAmount);
        }
    }

    function isCombinatorMode(mode) {
        return typeof CombinatorSPSlipMode != "undefined" && mode instanceof CombinatorSPSlipMode;
    }

    function isComboNoCombinationSingleMode(mode) {
        mode = mode || BetSlip.CurrentMode;
        return typeof ComboNoCombinationSPSlipMode != "undefined" && mode instanceof ComboNoCombinationSPSlipMode && BetSlip.config.AllowPlaceSinglesUsingComboNoCombination;
    }

    function setBanker(viewKey) {
        if (!Selections[viewKey].IsBanker && !isNewBankerAllowed(viewKey)) {
            return;
        }

        if (BetSlip.CurrentMode.SystemDeposits && BetSlip.CurrentMode.SystemKeys) {
            for (var key in BetSlip.CurrentMode.SystemDeposits) {
                BetSlip.CurrentMode.SystemDeposits[key] = 0;
                BetSlip.CurrentMode.SystemKeys[key] = false;
            }
        }

        Selections[viewKey].IsBanker = !Selections[viewKey].IsBanker;
        setRelatedBankers(Selections[viewKey].IsBanker, Selections[viewKey].getComboGroupKey());

        var bankersCount = BetSlip.CurrentMode.getBankersCount(BetSlip.CurrentMode.getSelectionGroups(true));
        var hasBankers = bankersCount > 0;
        if (!hasBankers && BetSlip.CurrentMode.VariationsDeposits && BetSlip.CurrentMode.VariationsDeposits[1]) {
            if (!BetSlip.CurrentMode.VariationsDeposits[2]) {
                BetSlip.CurrentMode.VariationsDeposits[2] = BetSlip.CurrentMode.VariationsDeposits[1];
            }
            delete BetSlip.CurrentMode.VariationsDeposits[1];
        }
        if (hasBankers && BetSlip.CurrentMode.VariationsDeposits) {
            var selectionsCount = Array.getLength(BetSlip.CurrentMode.getSelectionGroups(true));
            delete BetSlip.CurrentMode.VariationsDeposits[selectionsCount - bankersCount];
        }
        updateCartBets();
        updateView();
    }

    function isNewBankerAllowed(viewKey) {
        if (!Selections[viewKey].isNoComboAllowed && typeof BetSlip.CurrentMode.getBankersCount === "function" && typeof BetSlip.CurrentMode.getSelectionGroups === "function" && Selections[viewKey].SystemBetIsEnabled) {
            return BetSlip.CurrentMode.getBankersCount(BetSlip.CurrentMode.getSelectionGroups(true)) + 1 <= Array.getLength(BetSlip.CurrentMode.getSelectionGroups(true)) - 2;
        }
        return false;
    }

    function isBankerLimitReached() {
        if (typeof BetSlip.CurrentMode.getBankersCount === "function" && typeof BetSlip.CurrentMode.getSelectionGroups === "function") {
            return BetSlip.CurrentMode.getBankersCount(BetSlip.CurrentMode.getSelectionGroups(true)) >= Array.getLength(BetSlip.CurrentMode.getSelectionGroups(true)) - 2;
        }
        return true;
    }

    function isBankersResetNeeded() {
        if (typeof BetSlip.CurrentMode.getBankersCount === "function" && typeof BetSlip.CurrentMode.getSelectionGroups === "function") {
            return BetSlip.CurrentMode.getBankersCount(BetSlip.CurrentMode.getSelectionGroups(true)) > Array.getLength(BetSlip.CurrentMode.getSelectionGroups(true)) - 2;
        }
        return false;
    }

    function resetAllBankers() {
        if (typeof BetSlip.CurrentMode.resetAllBankers === "function") {
            BetSlip.CurrentMode.resetAllBankers(Selections, SelectionsMasterGroups);
        }
    }

    function setRelatedBankers(isEnabled, groupKey) {
        if (typeof BetSlip.CurrentMode.getSelectionGroups !== "function") {
            return;
        }
        var selectionGroups = BetSlip.CurrentMode.getSelectionGroups(true);
        if (isEnabled === undefined || !groupKey) {
            setAllRelatedBankers(selectionGroups);
        } else {
            setRelatedBankerByKey(selectionGroups, isEnabled, groupKey);
        }
    }

    function setAllRelatedBankers(selectionGroups) {
        for (var groupIdx in selectionGroups) {
            var group = selectionGroups[groupIdx];
            if (!group) {
                continue;
            }
            var selectionsCount = Array.getLength(group);
            if (selectionsCount <= 1 || !group[0].IsBanker) {
                continue;
            }

            for (var i = 1; i < selectionsCount; i++) {
                group[i].IsBanker = true;
            }
        }
    }

    function setRelatedBankerByKey(selectionGroups, isEnabled, groupKey) {
        var group = selectionGroups[groupKey];
        if (!group) {
            return;
        }
        var selectionsCount = Array.getLength(group);
        if (selectionsCount > 1) {
            for (var i = 0; i < selectionsCount; i++) {
                group[i].IsBanker = isEnabled;
            }
        }
    }

    function hasMultiplyRelatedBankers() {
        if (typeof BetSlip.CurrentMode.getSelectionGroups !== "function") {
            return false;
        }
        var selectionGroups = BetSlip.CurrentMode.getSelectionGroups(true);
        return selectionGroups.any(function (group) {
            if (Array.getLength(group) > 1 && group.any(function (selection) {
                return selection.IsBanker
            })) {
                return true;
            }
            return false;
        });

    }

    function hasSelectionsWithWaitingAllowed() {
        var isWaitingAllowedForSelections = false;

        for (var key in BetSlip.Selections) {
            if (BetSlip.Selections[key].waitingAllowed) {
                isWaitingAllowedForSelections = true;
                break;
            }
        }
        return isWaitingAllowedForSelections;
    }

    function setBankersForGroup(groupViewKey) {
        var group = BetSlip.SelectionsMasterGroups[groupViewKey];
        group.IsBanker = !group.IsBanker;
        var selectionsViewKeys = group.getInitializedSelectionsViewKeys();
        for (var viewKey in selectionsViewKeys) {
            setBanker(viewKey);
            return;
        }
    }

    function removeSelectionFromGroupByViewKey(viewKey) {
        for (var idx in BetSlip.SelectionsMasterGroups) {
            var group = BetSlip.SelectionsMasterGroups[idx];

            if (viewKey in group.getUnInitializedSelectionsViewKeys()) {
                removeUninitializedSelectionFromMasterGroup(group, viewKey);
            } else if (viewKey in group.getInitializedSelectionsViewKeys()) {
                removeInitializedSelectionFromMasterGroup(group.ViewKey, viewKey);
            }
        }
    }

    function removeUninitializedSelectionFromMasterGroup(group, selectionViewKey) {
        group.removeUninitializedSelection(selectionViewKey);
        if (group.isEmpty()) {
            delete BetSlip.SelectionsMasterGroups[group.ViewKey];
        }
    }

    function removeInitializedSelectionFromMasterGroup(groupViewKey, selectionViewKey) {
        var group = BetSlip.SelectionsMasterGroups[groupViewKey];
        var selectionsCount = group.getInitializedSelectionsCount();
        var marketGroupsCount = group.getMarketGroupsCount();
        if (selectionsCount === 1 && marketGroupsCount === 1) {
            delete BetSlip.SelectionsMasterGroups[groupViewKey];
        } else {
            group.removeBetInsideGroup(selectionViewKey);
        }
    }

    function removeSelectionsMasterGroup(groupViewKey) {
        var group = BetSlip.SelectionsMasterGroups[groupViewKey];
        var selectionsViewKeys = group.getAllSelectionsViewKeys();

        for (var idx in selectionsViewKeys) {
            var viewKey = selectionsViewKeys[idx];
            removeBet(viewKey, false, true);
        }
    }

    function addSelectionToMasterGroup(selection) {
        var masterEventID = (selection.MasterEventID || 0).toString();
        var currentGroupIndex = getMasterGroupViewKey(masterEventID);
        var currentGroup = BetSlip.SelectionsMasterGroups[currentGroupIndex];
        if (!currentGroup) {
            var groupViewKey = generateMasterGroupViewKey();
            currentGroup = new SelectionsMasterGroup(masterEventID, groupViewKey);
            BetSlip.SelectionsMasterGroups[groupViewKey] = currentGroup;
        }

        if (!selection.Initialized) {
            currentGroup.addUninitializedSelection(selection.ViewKey);
        } else {
            currentGroup.initialize(selection);
        }
    }

    function generateMasterGroupViewKey() {
        return masterGroupViewKey++;
    }

    function getMasterGroupViewKey(masterEventID) {
        for (var idx in BetSlip.SelectionsMasterGroups) {
            var group = BetSlip.SelectionsMasterGroups[idx];
            if (group.GroupKey === masterEventID) {
                return group.ViewKey;
            }
        }
        return null;
    }

    function hasUnInitializedSelections() {
        for (var idx in BetSlip.Selections) {
            var selection = BetSlip.Selections[idx];
            if (!selection.Initialized) {
                return true;
            }
        }
        return false;
    }

    function showConfiguration() {
        if (!UserInfo.current || !BetSlip.EnableAcceptChangingOdds) return;

        BetSlip.IsInConfigMode = true;
        if (typeof UniSlipBlock != "undefined" && UniSlipBlock.ParentBlock) {
            if (!UniSlipBlock.IsShown) {
                UniSlipBlock.ParentBlock.toggleTabs();
            }

            UniSlipBlock.ParentBlock.showHideTabsLine(false);
        }

        var wholePanel = document.getElementsByClassName(wholePanelClassName)[0];
        if (wholePanel) {
            wholePanel.classList.add(panelHasSettings);
        }

        BetSlip.updateConfiguration();
    }

    function hideConfiguration() {
        BetSlip.IsInConfigMode = false;
        typeof UniSlipBlock != "undefined" && UniSlipBlock.ParentBlock && UniSlipBlock.ParentBlock.showHideTabsLine(true);
        var wholePanel = document.getElementsByClassName(wholePanelClassName)[0];
        if (wholePanel) {
            wholePanel.classList.remove(panelHasSettings);
        }
    }

    function updateConfiguration() {
        var itemAlways = document.getElementById(IDs.configItemAlways);
        var itemBetter = document.getElementById(IDs.configItemBetter);
        var itemNever = document.getElementById(IDs.configItemNever);
        if (!itemAlways || !itemBetter || !itemNever || !UserInfo.current) {
            return;
        }

        itemAlways.classList[UserInfo.current.AcceptChangingOdds === AcceptChangingOddsModes.AcceptChangedOddsAlways ? "add" : "remove"]("betslip-config__item--active");
        itemBetter.classList[UserInfo.current.AcceptChangingOdds === AcceptChangingOddsModes.AcceptChangedOddsBetter ? "add" : "remove"]("betslip-config__item--active");
        itemNever.classList[UserInfo.current.AcceptChangingOdds === AcceptChangingOddsModes.AcceptChangedOddsNever ? "add" : "remove"]("betslip-config__item--active");
    }

    function setActiveItemConfig(event, newAcceptOddsMode) {
        if (!UserInfo.current || !BetSlip.EnableAcceptChangingOdds || UserInfo.current.AcceptChangingOdds === newAcceptOddsMode) {
            BetSlip.hideConfiguration();
            return;
        }
        UserInfo.setAcceptChangingOdds(newAcceptOddsMode);

        var loader = document.getElementById(IDs.acceptOddsLoader);
        if (loader) {
            loader.classList.remove('isHidden');
        }
        BettingPageMethods.UpdateCustomerAcceptOddsType(
            newAcceptOddsMode,
            function (res) {
                if (loader) {
                    loader.classList.add('isHidden');
                }
                BetSlip.hideConfiguration();
            },
            function (res) {
                if (loader) {
                    loader.classList.add('isHidden');
                }
                UI.message.show({
                    header: '',
                    body: $dict.bs('AcceptChangingOddsSaveError')
                });
            }
        );
    }

    function getSelectedPulseFreeBets() {
        return BetSlip.Modes["pulse"] && BetSlip.Modes["pulse"].getSelectedFreeBets();
    }

    function formatMoney(value) {
        if (typeof value === "string") {
            return value;
        }
        if(isNaN(value)){
            return "N/A";
        }
        return MoneyFormat.format(value);
    }

    function getSDEventId (selection) {
        if (selection.MasterEventID) {
            return selection.MasterEventID.toString();
        }
        else if (selection.RaceCardEventID) {
            return selection.RaceCardEventID.toString();
        }

        return selection.EventID.toString();
    }

    function getSDMarketId(selection) {
        if (((selection.BetTypeID === 1 || selection.BetTypeID === 2 || selection.BetTypeID === 3) && !(selection.IsQA || (selection instanceof QASlipSelection || selection instanceof QALiveSlipSelection)))
            || selection.EventTypeID === 769) {
            return `${selection.BetTypeID}_${selection.EventID}`
        }

        return selection.EventID.toString();
    };

    function getSDSelectionId (selection) {
        return selection.IsRegular ? getSDRegularSelectionId(selection) : getSDQASelectionId(selection);
    };

    function getSDRegularSelectionId (selection) {
        var points = selection.Points;
        var pointsTransform = transformPoints(Math.abs(points * 100));
        switch (selection.LineTypeID) {
            case 1:
                return "0ML{0}_{1}".format(selection.EventID, selection.BetSide);
            case 2:
                return "0HC{0}{1}{2}_{3}".format(selection.EventID, points < 0.0 ? "N" : "P", pointsTransform, selection.BetSide);
            case 3:
                return "0OU{0}{1}{2}_{3}".format(selection.EventID, selection.BetSide === 3 ? "U" : "O", pointsTransform, selection.BetSide);
            default:
                return "0UNK{0}{1}{2}_{3}".format(selection.EventID, points < 0.0 ? "N" : "P", pointsTransform, selection.BetSide);
        }
    };

    function getSDQASelectionId (selection) {
        return createSDQASelectionId(
            selection.EventID,
            selection.LineID,
            selection.LineTypeID,
            selection.LeagueID,
            getQAParam(selection, "1"),
            getQAParam(selection, "2")
        );
    };

    function createSDQASelectionId(eventId, lineId, lineTypeId, leagueId, qaTypeParameter1, qaTypeParameter2) {
        return "0QA{0}#{1}_{2}L{3}Q1{4}Q2{5}".format(
            eventId,
            lineId,
            lineTypeId,
            leagueId,
            qaTypeParameter1,
            qaTypeParameter2
        );
    };

    function getQAParam (selection, paramSuffix) {
        if (selection["QAParam" + paramSuffix] !== undefined) {
            return selection["QAParam" + paramSuffix];
        }

        if (selection["QAParameter" + paramSuffix] !== undefined) {
            return selection["QAParameter" + paramSuffix];
        }

        return -1
    }

	function transformPoints (points) {
		return points < 0 ? Math.ceil(points) : Math.floor(points);
    };
    
    function formatMoneyWithRounding(value) {
        return MoneyFormat.format(Math.floor((value * 100).toFixed(4)) / 100);
    }

    return {
        IDs: IDs,
        Selections: Selections,
        Modes: Modes,
        Purchases: Purchases,
        CurrentMode: CurrentMode,
        //ComboBonuses: ComboBonuses,
        //RecalculatedComboBonuses: RecalculatedComboBonuses,
        //BonusMessages: BonusMessages,

        get ActiveView() {
            return activeView;
        },

        get MyBets() {
            return MyBets
        },
        get ShowBetCheckBoxes() {
            return showCheckBoxes
        },
        maxSelectionsNumber: maxSelectionsNumber,
        isAsianMode: isAsianMode,
        isBetInProcess: isBetInProcess,
        IsInConfigMode: IsInConfigMode,
        OnAdd: OnAdd,
        OnItemUpdate: OnItemUpdate,
        OnLineClosedUpdate: OnLineClosedUpdate,
        OnViewUpdated: OnViewUpdated,
        OnBetSend: OnBetSend,
        OnBetStateChanged: OnBetStateChanged,
        OnDelete: OnDelete,
        OnSelectionRemoved: OnSelectionRemoved,
        OnPurchaseAdded: OnPurchaseAdded,
        OnPurhaseRemoved: OnPurhaseRemoved,
        OnPurchaseUpdated: OnPurchaseUpdated,
        OnModeChanged: OnModeChanged,
        OnBetInComboMarked: OnBetInComboMarked,
        OnBetSlipStateSaved: OnBetSlipStateSaved,
        get IsShowingOnlyCashOutBets() {
            return isShowingOnlyCashOutBets;
        },
        set IsShowingOnlyCashOutBets(val) {
            isShowingOnlyCashOutBets = val;
            BetSlip.saveState();
        },

        OnDepositChanges: OnDepositChanges,
        OnClear: OnClear,

        UserCurrencyCode: "", // This is used in the purchse if the user logs out while the purchase is still present

        // for compatibility
        betAdded: OnAdd,
        betOddsChanged: OnOddsChanged,
        betRemoved: OnDelete,
        getBetSlipSelections: getBetSlipSelections,

        registerMode: registerMode,
        getViewKey: getViewKey,
        getLastUsedViewKey: getLastUsedViewKey,
        init: init,
        initMyBets: initMyBets,
        clearMyBets: clearMyBets,
        shouldShowMyBetsTab: shouldShowMyBetsTab,
        isMyBetsViewActive: isMyBetsViewActive,
        add: add,
        clear: clear,
        removeBet: removeBet,
        addPurchase: addPurchase,
        removePurchase: removePurchase,
        disablePlaceBetButton: disablePlaceBetButton,
        togglePlaceBetButton: togglePlaceBetButton,
        toggleBetReceiptBanner: toggleBetReceiptBanner,
        getFooterTotals: getFooterTotals,
        placeBets: placeBets,
        PlaceUNIBets: PlaceUNIBets,
        cashOutBet: cashOutBet,
        saveState: saveState,
        restoreState: restoreState,
        setState: setState,
        startWaitingMonitor: startWaitingMonitor,
        openPurchaseDetails: openPurchaseDetails,
        acceptOffer: acceptOffer,
        declineOffer: declineOffer,
        acceptChangedOdds: acceptChangedOdds,
        buyPointsAdd: buyPointsAdd,
        restorePurchaseSelections: restorePurchaseSelections,
        setConfig: setConfig,
        EnablePrinting: EnablePrinting,
        hasToUpdateToWinSelections: hasToUpdateToWinSelections,
        SkipClientUserBalanceCheckForSingles: SkipClientUserBalanceCheckForSingles,
        SkipClientUserBalanceCheckForCombos: SkipClientUserBalanceCheckForCombos,
        EnableCashOut: EnableCashOut,
        ShowBetHistoryLink: ShowBetHistoryLink,
        ShowCurrency: ShowCurrency,
        getCurrencyCodeForBetSlip: getCurrencyCodeForBetSlip,
        getCurrencyWithStakeValues: getCurrencyWithStakeValues,
        isDefaultStakeAndStakeValuesAvailable: isDefaultStakeAndStakeValuesAvailable,
        addValueToStakeDropDownSeries: addValueToStakeDropDownSeries,
        getDefaultCurrency: getDefaultCurrency,
        getUpdateCartBetsRunning: getUpdateCartBetsRunning,
        checkForMultiLineItem: checkForMultiLineItem,
        createPurchaseReadyState: createPurchaseReadyState,
        restoreCurrentPurchase: restoreCurrentPurchase,
        restoreComplexPurchases: restoreComplexPurchases,
        getSelectionsCount: getSelectionsCount,
        RoundToStakeRounding: RoundToStakeRounding,
        formatMoneyWithRounding: formatMoneyWithRounding,
        declineBetsWithDifferentTypesForTheSameEvent: declineBetsWithDifferentTypesForTheSameEvent,
        getCurrencyCode: getCurrencyCode,

        selectSlipMode: function (id, isForce) {
            if (selectSlipMode(id, false, isForce)) {
                preselected_state = true;

                // Save the state in order to save the pre-selected state
                BetSlip.saveState();
            }
        },
        getAlternativeOddsStyle: getAlternativeOddsStyle,
        CheckForEachWayIncluded: CheckForEachWayIncluded,
        CheckForEachWayEnabled: CheckForEachWayEnabled,
        recalcEachWay: recalcEachWay,
        getTotalGain: getTotalGain,
        clearEW: clearEW,
        CheckForSPIncluded: CheckForSPIncluded,
        CheckForNoOddsSelectionIncluded: CheckForNoOddsSelectionIncluded,
        CheckForCastSelectionIncluded: CheckForCastSelectionIncluded,
        ShouldReturnNAAsTotalGain: ShouldReturnNAAsTotalGain,
        showAlternateLine: showAlternateLine,
        StakeRoundingMethods: StakeRoundingMethods,
        StakeRounding: StakeRounding,
        MyBetsEnabled: MyBetsEnabled,
        onViewChanged: onViewChanged,
        OnOfferAccepted: OnOfferAccepted,
        OnOfferDeclined: OnOfferDeclined,
        OnAjaxDataServiceLoaded: OnAjaxDataServiceLoaded,
        SanitizeNumberString: SanitizeNumberString,
        getStakeBoxValue: getStakeBoxValue,
        restoreSelections: restoreSelections,
        autoSelectMode: autoSelectMode,
        shouldRebuildVariantStake: shouldRebuildVariantStake,
        performPlaceBets: performPlaceBets,
        placeBetsInMode: placeBetsInMode,
        afterBetSuccessfullyPlaces: afterBetSuccessfullyPlaces,
        subscribeToPush: subscribeToPush,
        updateCartBets: updateCartBets,
        OnSelectionsUpdated: OnSelectionsUpdated,
        validatePurchaseWithCountryTaxes: validatePurchaseWithCountryTaxes,
        taxesApplier: taxesApplier,
        sendToJSApi: sendToJSApi,
        sendBetLimitsErrorToJSApi: sendBetLimitsErrorToJSApi,
        isCombinatorMode: isCombinatorMode,
        isComboNoCombinationSingleMode: isComboNoCombinationSingleMode,
        initSlipMode: selectSlipMode,
        getSPPurchases: getSPPurchases,
        getPurchases: getPurchases,
        restoreSlipMode: restoreSlipMode,
        cleanPurchases: cleanPurchases,
        getUserCurrency: getUserCurrency,
        getPreloadedLines: getPreloadedLines,
        exitFromEditMode: exitFromEditMode,
        setBanker: setBanker,
        isBankerLimitReached: isBankerLimitReached,
        isNewBankerAllowed: isNewBankerAllowed,
        hasMultiplyRelatedBankers: hasMultiplyRelatedBankers,
        isNotLoggedInMessage: isNotLoggedInMessage,
        setTaxTooltip: setTaxTooltip,
        hasSelectionsWithWaitingAllowed: hasSelectionsWithWaitingAllowed,
        isAutoSelectModeAllowed: isAutoSelectModeAllowed,
        createModeInstance: createModeInstance,
        SelectionsMasterGroups: SelectionsMasterGroups,
        removeBetMasterGroup: removeSelectionsMasterGroup,
        removeBetInsideGroup: removeInitializedSelectionFromMasterGroup,
        setBankersForGroup: setBankersForGroup,
        hasUnInitializedSelections: hasUnInitializedSelections,
        showDisabledBetPlacementMessage: showDisabledBetPlacementMessage,
        showConfiguration: showConfiguration,
        hideConfiguration: hideConfiguration,
        updateConfiguration: updateConfiguration,
        setActiveItemConfig: setActiveItemConfig,
        getSelectedPulseFreeBets: getSelectedPulseFreeBets,
        setMaxSelections: setMaxSelections,
        formatMoney: formatMoney,
        OnSelectionAdded: BetSlip.OnSelectionAdded || {},
        getSDEventId: getSDEventId,
        getSDMarketId: getSDMarketId,
        getSDSelectionId: getSDSelectionId,
        createSDQASelectionId: createSDQASelectionId
    };

})());

includeExtension("/JSComponents/Data/UniSlip/UniSlip.ext.js");

window.addEventListener("load", function () {
    setTimeout(function () {
        executeEvents(BetSlip.OnLoaded, BetSlip);
    }, 0)
});
