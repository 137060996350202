function UKSPSlipMode()
{
    SingleSPSlipMode.call(this);
    SystemSPSlipMode.call(this);
    ComboSPSlipMode.call(this);
    TeaserSPSlipMode.call(this);

    this.Order = 5;
    this.ID = "ukslip";
    this.Name = $dict.bs("ComboBetTab");
    this.TabClass = "betting_slip_nbs ukSlip";
    this.isTeaserOn = false;

    this.PurchaseTypeID = SPPurchaseType.UKPurchase;

    this.SystemDeposits = {};
    this.SystemKeys = {};
    this.SystemMinSelections = 3;
    this.ShowMultiples = BetSlip.config ? !BetSlip.config.IsMultiplesSectionCollapsedInUkSlipMode : true;
    this.StakeUpdatedByUser = [];
    this.TeaserStakeUpdatedByUser = false;
    this.SystemStakesUpdatedByUser = [];
    this.ActivationRank = 100;
    this.HasPreviewMode = false;
    this.SelectionDrawType = "UKLine";
    this.IsUKBetSlip = 1;
}

$.extend(UKSPSlipMode.prototype, SingleSPSlipMode.prototype, SystemSPSlipMode.prototype, ComboSPSlipMode.prototype, TeaserSPSlipMode.prototype,
{
    activate: function ()
    {
        this.Active = true;
		this.HasBeenActivated = true;
     },

    deactivate: function ()
    {
		this.Active = false;
    },

    canActivate: function ()
    {
        return this.ActivationRank;
    },

    check: function ()
    {
        var checkResult,
            hasComboBets = this.VariationsDeposits.any(function (elem) { return !!elem }),
            systemBetsTotalDeposit = this.getSystemTotalDeposit();

        if (this.isTeaserOn)
        {
            checkResult = TeaserSPSlipMode.prototype.check.call(this);
        }
        else
        {
            checkResult = hasComboBets ? ComboSPSlipMode.prototype.check.call(this) : true;
        }

        if (checkResult && !this.isTeaserOn && systemBetsTotalDeposit > 0)
        {
            checkResult = this.checkSystemBets();
        }

        return checkResult;
    },

    checkSystemBets: function ()
    {
        var Lines = this.getSelectionsInCombo();

        if (Array.getLength(Lines) < this.SystemMinSelections) 
        {
            this.setError($dict.bs("SelectionsInSystem"));
            return false;
        }

        if (Array.getLength(Lines) > this.MaxSelections)
        {
            return false;
        }

        // get min bet
        var mx = this.getComboMinBet(Lines);
        var sysDepsKeys = Object.keys(this.SystemDeposits);

        for (var key = 0; key < sysDepsKeys.length; key = key + 1)
        {
            if (typeof this.SystemDeposits[sysDepsKeys[key]] != "undefined" && this.SystemDeposits[sysDepsKeys[key]] > 0)
            {
                if (this.SystemDeposits[sysDepsKeys[key]] > 0 && this.SystemDeposits[sysDepsKeys[key]] < mx) // check min bet
                {
                    this.setError($dict.bs("MinimalBet").concat(" " + MoneyFormat.format(mx)));
                    return false;
                }
            }
        }

        var fd = this.getTotalDeposit();

        if (!BetSlip.SkipClientUserBalanceCheckForCombos && fd > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setError($dict.bs("NotEnoughFunds"), true);
            return false;
        }

        return true;
    },

    isComboSystemOrTeaserBet: function ()
    {
        if (this.getSystemTotalDeposit() > 0 || ComboSPSlipMode.prototype.getTotalDeposit.call(this) > 0 || TeaserSPSlipMode.prototype.getTotalDeposit.call(this) > 0)
        {
            return true;
        }

        return false;
    },

    canPlaceBet: function ()
    {
        return SingleSPSlipMode.prototype.canPlaceBet.call(this) || ComboSPSlipMode.prototype.canPlaceBet.call(this);
    },

    getSelections: function ()
    {
        if (this.isTeaserOn)
        {
            return this.getSelectionsInTeaser();
        }

        return this.getSelectionsInCombo();
    },

    deselectStakeDropdown: function (key)
    {
        if (!BetSlip.isDefaultStakeAndStakeValuesAvailable()) return;

        for (var idx in BetSlip.Selections)
        {
            var singleDropDown = document.getElementById("dropdown" + BetSlip.Selections[idx].ViewKey);

            if (singleDropDown && singleDropDown.selectedIndex != -1)
            {
                singleDropDown.options[singleDropDown.selectedIndex].selected = false;
                singleDropDown.selectedIndex = -1;
                singleDropDown.value = 0;
            }
        }

        if (this.isTeaserOn)
        {
            var teaserDropDown = document.getElementById("stakedropdown_teaser");

            if (teaserDropDown && teaserDropDown.selectedIndex != -1)
            {
                teaserDropDown.options[teaserDropDown.selectedIndex].selected = false;
                teaserDropDown.value = 0;
                teaserDropDown.selectedIndex = -1;
            }
        }
        else
        {
            var dropDownForAll = document.getElementById("dropdownForAllBets");

            if (dropDownForAll && dropDownForAll.selectedIndex != -1)
            {
                dropDownForAll.options[dropDownForAll.selectedIndex].selected = false;
                dropDownForAll.value = 0;
                dropDownForAll.selectedIndex = -1;
            }
            
            for (var i = 2 ; i <= this.Variants.length; i++)
            {
                var comboDropDown = document.getElementById("dropdown_combo_" + i);
                if (comboDropDown && comboDropDown.selectedIndex != -1)
                {
                    comboDropDown.options[comboDropDown.selectedIndex].selected = false;
                    comboDropDown.value = 0;
                    comboDropDown.selectedIndex = -1;
                }
            }

            for (var systemKey in this.SystemKeys)
            {
                var systemDropDown = document.getElementById("dropdown_system_" + systemKey);
                if (systemDropDown && systemDropDown.selectedIndex != -1)
                {
                    systemDropDown.options[systemDropDown.selectedIndex].selected = false;
                    systemDropDown.value = 0;
                    systemDropDown.selectedIndex = -1;
                }
            }
        }
    },

    selectionAdded: function (item)
    {
        SingleSPSlipMode.prototype.selectionAdded.call(this, item);
        ComboSPSlipMode.prototype.selectionAdded.call(this, item);
        TeaserSPSlipMode.prototype.selectionAdded.call(this, item);

        var teaserTogglerElem = document.getElementById("teaserToggler");

        if (this.hasTeaserSelections() && teaserTogglerElem)
        {
            teaserTogglerElem.classList.remove("hidden");
        }

        this.clearSystemDeposits();
        this.clearSystemEWVariants();

        this.updateView();
    },

    selectionUpdated: function (item, initcall)
    {
        var err = this.getComboAddError(item);
        if (!err)
        {
            if (!this.SelectionsInCombo.any(function (array) { return array.any(function (element) { return element == item.viewKey; }); }) && initcall)
            {
                var groupKey = item.getComboGroupKey();
                var arr = this.SelectionsInCombo[groupKey];
                if (!arr) arr = this.SelectionsInCombo[groupKey] = [];
                if (!arr.contains(item.ViewKey)) arr.push(item.ViewKey);

                this.removeSelectionCountError();
            }
        }
        else
        {
            this.ExcludedSelections[item.ViewKey] = true;
        }

        if (item.TeaserBetIsEnabled && !this.getTeaserAddError(item))
        {
            if (Array.indexOf(this.SelectionsInTeaser, item.ViewKey) == -1 && initcall)
            {
                this.SelectionsInTeaser.push(item.ViewKey);

                // Remove message "You need to make at least 2 selections applicable for teaser to place a bet!" if now there are two
                if (this.CurrentError == $dict.bs("SelectionsInTeaser") && Array.getLength(this.SelectionsInTeaser) >= 2)
                {
                    this.CurrentError = false;
                }
            }
        }

        if (!this.Active) return;

        if (BetSlip.CheckForEachWayIncluded() && item.BetType == 7 && ComboSPSlipMode.isEachWayEnabled(item))
        {
            item.EachWayIncluded = true;
            this.recalcEachWay();
        }

        if (initcall)
        {
            this.updateView();
        }
        else if (item.Updated || !item.Valid || item.AlternateLineChanged)
        {
            // updateSelection() redraws the selection so if the selection is updated we need to redraw it
            // otherwise old odds or points would remain no changed on the screen. Then updateSelectionData()
            // would make those odds or points highlighted and blinking.
            this.updateSelection(item);
            this.updateSelectionData(item);
        }
        else
            this.updateSelectionData(item);

        this.updateViewOptions();
        BetSlip.updateBSFooter();

        var teaserTogglerElem = document.getElementById("teaserToggler");

        if (this.hasTeaserSelections() && teaserTogglerElem)
        {
            teaserTogglerElem.classList.remove("hidden");
        }
    },

    updateSelection: function (selection)
    {
        if (!this.Active) return;

        selection.IsMultiLine ? BaseSPSlipMode.prototype.updateMultipleSelection.call(this, selection) : BaseSPSlipMode.prototype.updateSelection.call(this, selection);

        if (this.isTeaserOn)
        {
            TeaserSPSlipMode.prototype.updateSelection.call(this, selection);
        }
    },


    focusOnStakeBox: function (selection)
    {
        SingleSPSlipMode.prototype.focusOnStakeBox.call(this, selection);
        ComboSPSlipMode.prototype.focusOnStakeBox.call(this);
    },

    selectionRemoved: function (item)
    {
        SingleSPSlipMode.prototype.selectionRemoved.call(this, item);
        ComboSPSlipMode.prototype.selectionRemoved.call(this, item);
        SystemSPSlipMode.prototype.selectionRemoved.call(this, item);
        this.teaserSelectionRemoved(item);

        var teaserTogglerElem = document.getElementById("teaserToggler");
        
        if (!this.hasTeaserSelections())
        {
            this.isTeaserOn = false;
            if (teaserTogglerElem)
            {
                teaserTogglerElem.classList.add("hidden");
            }
        }

        this.clearSystemDeposits();
        this.clearSystemEWVariants();

        this.updateView();
    },

    teaserSelectionRemoved: function(item)
    {
        Array.removeAll(this.SelectionsInTeaser, function (el) { return el == item.ViewKey; });

        // Remove message "One of the events you have selected is suspended or invalid!" if all remaining selections are valid
        if (this.CurrentError == $dict.bs("TeaserEventDanger") && Array.find(this.getSelectionsInTeaser(), function (sel) { return !(sel.Valid && !sel.Error); }) == null)
        {
            this.CurrentError = false;
        }

        // If the user removes the last selection from the slip in the slip act as if the tab has never been opened, i.e. as if the slip was cleared,
        // remove current FreeBet and reset TeaserStakeUpdatedByUser flag
        if (Array.getLength(BetSlip.Selections) == 0)
        {
            this.HasBeenActivated = false;
            this.TeaserStakeUpdatedByUser = false;
            this.FreeBet = null;
        }

        if (!this.Active) return;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    getBetNames: function ()
    {
        var betNames;

        if (this.isTeaserOn)
        {
            betNames = TeaserSPSlipMode.prototype.getBetNames.call(this);
        }
        else
        {
            betNames = ComboSPSlipMode.prototype.getBetNames.call(this);
        }

        return betNames;
    },

    clear: function (item)
    {
        SingleSPSlipMode.prototype.clear.call(this, item);
        ComboSPSlipMode.prototype.clear.call(this, item);
        this.clearSystems();
        this.clearTeaser();
    },

    clearTeaser: function ()
    {
        this.Deposit = 0;
        this.SelectionsInTeaser = [];
        this.TeaserTypeID = false;
        this.CurrentError = false;
        this.IsBetInProcess = false;
        this.HasBeenActivated = false;
        this.TeaserStakeUpdatedByUser = false;
        this.FreeBet = null;

        this.updateView();
        BetSlip.updateBSFooter();
    },

    clearSystems: function ()
    {
        this.SystemDeposits = {};
        this.SystemKeys = {};
        this.SelectedSystemEWVariants = [];
        this.SystemStakesUpdatedByUser = [];

        this.updateView();
        BetSlip.updateBSFooter();
    },

    getNumberOfBets: function (varKey)
    {
        var d = SingleSPSlipMode.prototype.getNumberOfBets.call(this);

        if (this.isTeaserOn)
        {
            d = d + TeaserSPSlipMode.prototype.getNumberOfBets.call(this);
        }
        else
        {
            d = d + ComboSPSlipMode.prototype.getNumberOfBets.call(this, varKey) + this.getSystemTotalNumberOfBets();
        }

        return d;
    },

    getTotalDeposit: function ()
    {
        var totalDeposit = 0;

        if (this.isTeaserOn)
        {
            totalDeposit = SingleSPSlipMode.prototype.getTotalDeposit.call(this) + TeaserSPSlipMode.prototype.getTotalDeposit.call(this)
        }
        else
        {
            totalDeposit = SingleSPSlipMode.prototype.getTotalDeposit.call(this) + ComboSPSlipMode.prototype.getTotalDeposit.call(this) + this.getSystemTotalDeposit();
        }

        return totalDeposit;
    },

    getTotalGain: function ()
    {
        BetSlip.lastUpdate = new Date();

        var totalGain = 0;

        if (this.isTeaserOn)
        {
            totalGain = SingleSPSlipMode.prototype.getTotalGain.call(this) + TeaserSPSlipMode.prototype.getTotalGain.call(this);
        }
        else
        {
            totalGain = SingleSPSlipMode.prototype.getTotalGain.call(this) + ComboSPSlipMode.prototype.getTotalGain.call(this) + this.getSystemTotalGain();
        }

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGain, roundingMode);

        return roundedGain;
    },

    getTotalGainWithBonuses: function ()
    {
        BetSlip.lastUpdate = new Date();

        var totalGainWithBonuses = 0;

        if (this.isTeaserOn )
        {
            totalGainWithBonuses = SingleSPSlipMode.prototype.getTotalGain.call(this) + TeaserSPSlipMode.prototype.getTotalGain.call(this);
        }
        else
        {
            totalGainWithBonuses = SingleSPSlipMode.prototype.getTotalGain.call(this) + ComboSPSlipMode.prototype.getTotalGainWithBonuses.call(this) + this.getSystemTotalGain();
        }
       
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGainWithBonuses, roundingMode);

        return roundedGain;
    },

    updateView: function (optionChanged)
    {
        if (!this.Active) return;

        var __html = [], containerElement = document.getElementById("bet-slip-container"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;
        if (containerElement)
        {
            this.buildInnerView(__html);
            containerElement.innerHTML = __html.join("");
        }

        this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);
        // update options
        this.updateViewOptions(optionChanged);

        BetSlip.updateScrollbar();
    },

    updateViewOptions: function (optionChanged)
    {
        var optionsHtml = "", optionsElement = document.getElementById("jsBetslipMultiples"),
            activeElementID = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.id : "",
            activeElementValue = (document.activeElement && document.activeElement.tagName && document.activeElement.tagName.toLowerCase() === 'input') ? document.activeElement.value : "";
        var shouldFocusElement = document.activeElement && typeof (document.activeElement.getBoundingClientRect) == 'function' ? document.activeElement.getBoundingClientRect() : null;

        if (optionsElement)
        {
            if (typeof this.buildOptions == "function")
            {
                optionsHtml = this.buildOptions(Array.getValues(BetSlip.Selections));
                if (optionsHtml) {
                    optionsElement.outerHTML = optionsHtml;
                }
            }
            
            if (!optionChanged && this.isTeaserOn)
            {
                this.teaserOptionChanged();
            }
        }

        this.setFocusOnStakeBox(activeElementID, activeElementValue, shouldFocusElement);

        this.deselectStakeDropdown();
    },

    updateSelectionData: function (selection)
    {
        //We use only the functionality from SingleSPSlipMode because in uk slip we show them as in the Single tab
        SingleSPSlipMode.prototype.updateSelectionData.call(this, selection);
    },

    serialize: function ()
    {
        return {
            ID: this.ID,
            singles: SingleSPSlipMode.prototype.serialize.call(this),
            combos: ComboSPSlipMode.prototype.serialize.call(this),
            teaser: TeaserSPSlipMode.prototype.serialize.call(this),
            system: {
                systemDeposits : this.SystemDeposits,
                systemKeys: this.SystemKeys,
                stakesUpdatedByUser: this.SystemStakesUpdatedByUser,
                minSelections: this.SystemMinSelections,
                SelectedSystemEWVariants: this.SelectedSystemEWVariants
            },
            ukdata: {
                teaserOn: this.isTeaserOn,
                showMultiples: BetSlip.config ? !BetSlip.config.IsMultiplesSectionCollapsedInUkSlipMode : this.ShowMultiples,
                teaserUpdateByUser: this.TeaserStakeUpdatedByUser
            }
        };
    },

    deserialize: function (data)
    {
        SingleSPSlipMode.prototype.deserialize.call(this, data.singles);
        ComboSPSlipMode.prototype.deserialize.call(this, data.combos);
        TeaserSPSlipMode.prototype.deserialize.call(this, data.teaser);
        this.SystemDeposits = data.system.systemDeposits;
        this.SystemKeys = data.system.systemKeys;
        this.SystemStakesUpdatedByUser = data.system.stakesUpdatedByUser;
        this.SystemMinSelections = data.system.minSelections;
        this.SelectedSystemEWVariants = data.system.SelectedSystemEWVariants;
        this.isTeaserOn = data.ukdata.teaserOn;
        this.ShowMultiples = BetSlip.config ? !BetSlip.config.IsMultiplesSectionCollapsedInUkSlipMode : this.ShowMultiples;
        this.TeaserStakeUpdatedByUser = data.ukdata.teaserUpdateByUser;
    },

    initBetNames: function ()
    {
        SingleSPSlipMode.prototype.initBetNames.call(this);
        ComboSPSlipMode.prototype.initBetNames.call(this);
    },

    toggleComboSelection: function (viewKey, cbox)
    {
        ComboSPSlipMode.prototype.toggleComboSelection.call(this, viewKey, cbox);

        this.clearSystemDeposits();
        this.clearSystemEWVariants();

        this.updateView();
    },

    getSplitCount: function (basket)
    {
        var res = 0;
        var Lines = basket ? basket : this.getSelectionsInCombo();

        for (var key in Lines)
        {
            if (Lines[key].isSplit()) res++;
        }

        return res;
    },

    placeBets: function ()
    {
		if (BaseSPSlipMode.prototype.isGeolocationInProgress.call(this))
		{
			return;
		}

        if (this.IsBetInProcess) return;

        this.setBetInProcess(true, true);

        if (!this.check())
        {
            this.setBetInProcess(false, true);
            return;
        }

        var totals = BetSlip.getFooterTotals();
        if (UserInfo.TaxProvider.isUserUnderTax() && BetSlip.validatePurchaseWithCountryTaxes(totals.Taxes, this.getTotalDeposit()))
        {
            this.setBetInProcess(false, true);
            this.setError($dict.bs("CountryTaxInvalidOdds"));
            return;
        }

        if (this.SetFromComboPromotion)
        {
            Data.sendToGoogleAnalytics("Combo of The Day", "Bet Slip", this.Deposit);
            this.SetFromComboPromotion = 0;
        }

        var fd = this.getTotalDeposit();

        if (fd <= 0)
        {
            this.setError($dict.bs('InvalidBet'));
            this.setBetInProcess(false, true);
            return;
        }
        
        SingleSPSlipMode.prototype.clearErrors.call(this);

        var lines = [],
            comboBonuses = {},
            isLiveCombo = false,

            selectionsInCombo = this.getSelectionsInCombo(),
            oddStyleIDFromClient = BetSlip.getAlternativeOddsStyle() || currentOddStyle,
            ewIncluded = this.CheckForEachWayIncluded(),
            lines = [],
            deposits = [],
            multiLineData = [],
            freeBets = [],
            depsForCombo = Array.findAll(this.VariationsDeposits, function (v) { return (v && v != 0); }),
            systemBetsTotalDeposit = this.getSystemTotalDeposit(),
            totalRealMoneyDeposit = 0; // the total deposit, without any free bets

        for (var key in BetSlip.Selections)
        {
            var item = BetSlip.Selections[key];
            var deposit = this.Deposits[key];
            var isInTeaser = this.isSelectionInTeaser(item);
            var isInCombo = this.IsSelectionInCombo(item);
            var addLineForMultiples = false;

            item.IsInMultiples = false;

            if (this.isTeaserOn && isInTeaser && this.Deposit > 0)
            {
                if (!item.Valid || item.Danger)
                {
                    this.setError($dict.bs("TeaserEventDanger"));
                    this.setBetInProcess(false, true);
                    return;
                }

                this.removeBuyPoints(item);

                item.IsInMultiples = true;
                addLineForMultiples = true;
            }
            else if (!this.isTeaserOn && isInCombo && (depsForCombo.length > 0 || systemBetsTotalDeposit > 0))
            {
                if (!item.Valid || item.Danger)
                {
                    this.setError($dict.bs('ComboEventDanger'));
                    this.setBetInProcess(false, true);
                    return;
                }

                item.IsInMultiples = true;
                addLineForMultiples = true;
            }

            if (!deposit)
            {
                if (addLineForMultiples)
                {
                    lines.push(item);
                    deposits.push(0);
                }
                continue;
            }

            if (!item.Valid)
            {
                continue;
            }

            if (typeof item.EachWayIncluded != "undefined" && typeof item.EachWaySingleIncluded != "undefined")
            {
                item.EachWayIncluded = item.EachWaySingleIncluded;
            }

            var input = document.getElementById('stake_' + item.ViewKey);
            var inputValue = StakeInputParser.parse(input.value);
            var minBet = (item.EachWayIncluded && item.EachWayMinBet) ? item.EachWayMinBet : item.minBet;

            if (!BetSlipValidation.validateMin(minBet, deposit, inputValue, BetSlip.EnableAsianStyleMinMax))
            {
                var minAmount = item.isToWin() && !BetSlip.EnableAsianStyleMinMax ? item.getToWinFromDeposit(item.minBet) : item.minBet;
                this.Errors[key] = $dict.bs('MinimalBet').concat(' ' + MoneyFormat.format(Bets.roundMin(minAmount)));
                continue;
            }

            if (item.FreeBet)
            {
                var realMoneyDeposit = deposit - item.FreeBet.Amount;
                if (realMoneyDeposit > 0 && realMoneyDeposit < minBet)
                {
                    var minAmount = item.isToWin() ? item.getToWinFromDeposit(item.minBet) : item.minBet;
                    this.Errors[key] = $dict.bs('MinimalBetOverFree').concat(' ' + MoneyFormat.format(Bets.roundMin(minAmount)));
                    continue;
                }

                totalRealMoneyDeposit += Math.max(realMoneyDeposit, 0);
                freeBets[item.ViewKey] = item.FreeBet;
            }
            else
            {
                totalRealMoneyDeposit += deposit;
            }

            if (item instanceof MultiLineItem)
            {
                if (deposit > 0)
                {
                    multiLineData.push(item.formatPurchaseRequest(deposit));
                }
            }
            else
            {
                lines.push(item);
                deposits.push(deposit);
            }
        }

        if (!BetSlip.SkipClientUserBalanceCheckForSingles && totalRealMoneyDeposit > UserInfo.current.getBalance())
        {
            if (typeof (QuickDepositBlock) != "undefined")
            {
                QuickDepositBlock.showDepositPopupIfNeed();
            }
            this.setBetInProcess(false, true);
            this.setError($dict.bs("NotEnoughFunds"), true);
            return;
        }

        if (ComboBonusProvider.ComboBonuses != null)
        {
            comboBonuses = ComboBonusProvider.ComboBonuses;
        }

        if (lines.length == 0 && multiLineData.length == 0)
        {
            this.setBetInProcess(false);
            this.updateView();
            return;
        }

        if (multiLineData.length > 0)
        {
            //We always need cast selections, even without deposits
            for (var key in BetSlip.Selections)
            {
                var item = BetSlip.Selections[key];
                var deposit = this.Deposits[key];

                if (!deposit && item.CastSelection)
                {
                    var deps = this.Deposits;
                    var isInValidMultiLineItem = BetSlip.Selections.any(function (sel) { return (sel instanceof MultiLineItem && sel.Selections.contains(item.ViewKey) && deps[sel.ViewKey] > 0); });
                    if (isInValidMultiLineItem)
                    {
                        lines.push(item);
                        deposits.push(0);
                    }
                }
            }
        }

        var comboDeposits = null;
        var teaserTypeID = null;
        var teaserDeposit = null;
        var systemDeposits = this.isTeaserOn ? null : this.SystemDeposits;
        var systemKeys = this.isTeaserOn ? null : this.SystemKeys;

        if (!this.isTeaserOn)
        {
            comboDeposits = [];
            for (var i = 2; i < depsForCombo.length; i++)
                comboDeposits[i] = depsForCombo[i] ? depsForCombo[i] : null;
        }
        else if(this.Deposit > 0)
        {
            teaserTypeID = this.TeaserTypeID;
            teaserDeposit = this.Deposit;
        }

        BetSlip.disablePlaceBetButton();

        var ref = this;
        if (BetSlip.PlaceSingleBetsWithMultiplePurchases || BetSlip.PlaceComboBetsWithMultiplePurchases)
        {
            var selections = BetSlip.Selections.slice();
            
            BettingPageMethods.placeMultiPurchase(this.formatUKMultiPurchaseRequest(lines, deposits, comboDeposits, teaserDeposit, teaserTypeID, systemDeposits, systemKeys, multiLineData, freeBets, comboBonuses),
                   function (res) {
                       var purchases = eval(res);
                       ref.placePurchaseCallBack(purchases, selections);
                   },
                   this.placePurchaseCallBackError,
                   this
               );
        }
        else
        {
            BettingPageMethods.placeSinglePurchase(this.formatPurchaseRequest(lines, deposits, comboDeposits, teaserDeposit, teaserTypeID, systemDeposits, systemKeys, multiLineData, freeBets, comboBonuses),
                        function (result)
                        {
                            var purchases = [eval(result)];
                            ref.placePurchaseCallBack(purchases);
                        },
                        this.placePurchaseCallBackError,
                        this
                    );
        }
        

        // Suppress an unexpected JS error on updateView() so that it cannot stop the "bet in process" state
        try { this.updateView(); } catch (e) { if (console && console.log) console.log(e); }
    },

    clearMultiplesErrors: function ()
    {
        ComboSPSlipMode.prototype.clearErrors.call(this);
        TeaserSPSlipMode.prototype.clearErrors.call(this);
    },

    parsePlaceBets: function (result, keepPreviousPurchase, betSlipSelections)
    {
        // ugly fix for system bets multiple purchases
        this.CachedSystemKeys = Object.keys(this.SystemKeys).length > 0 || !this.CachedSystemKeys ? this.SystemKeys : this.CachedSystemKeys;

        this.clearMultiplesErrors();

        var bets = [];
        var selections = [];

        ComboBonusProvider.addRecalculatedComboBonuses(result.ComboBonuses);

        betSlipSelections = betSlipSelections || BetSlip.Selections;

        switch (result.Status)
        {
            case 1:
                {
                    this.StakesUpdatedByUserForDropDown = [];
                    this.TeaserStakeUpdatedByUser = false;
                    this.SystemStakesUpdatedByUser = [];
                    this.StakeUpdatedByUser = [];
                }//Accepted
            case 3: //Waiting
                {
                    for (var k in result.Bets)
                    {
                        this.SaveReservedFreeBetsInStorage(result.Bets[k].FreeBetID);
                    }
                    this.StakesUpdatedByUserForDropDown = [];
                    var altOddStyle = this.getAlternativeOddsStyle();

                    SPPurchaseBuilder.createUKPurchase(result, betSlipSelections, keepPreviousPurchase, false, altOddStyle, this.CachedSystemKeys)

                    this.Deposit = 0;
                    this.VariationsDeposits = [];

                    UserInfo.updateBalance();

                    break;
                }
            case 2: //Rejected
            case -10:
                {
                    this.setPurchaseError(result);
                    break;
                }
            case -1: //Selections Error
                {
                    var errorsHelper = new BetSlipErrorMessagesHelper();
                    for (var sIdx in result.Selections)
                    {
                        var line = result.Selections[sIdx];
                        var selection = Array.find(betSlipSelections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        if (line.Closed == 1)
                        {
                            line.ErrorText = $dict.bs("LineClosed");
                            this.CurrentError = $dict.bs("ComboEventDanger");
                            if (selection.Live)
                            {
                                selection.Valid = false;
                                selection.LineClosed = true;
                            }
                        }

                        var showError = (!BetSlip.isAlertMessageEnabled || (BetSlip.isAlertMessageEnabled && !selection.UpdatedOdds && !selection.UpdatedPoints))
                        if (showError)
                        {
                            selection.UpdatedOdds && (line.ErrorText = $dict.bs("OddsChanged"));
                            selection.UpdatedPoints && (line.ErrorText = $dict.bs("PointsChanged"));
                            selection.UpdatedOdds && selection.UpdatedPoints && (line.ErrorText = $dict.bs("AllChanged"));
                            selection.Error = this.translateBetSlipError(line, selection, false);

                            var translatedError = this.translateBetSlipError(line, selection, false);
                            this.Errors[selection.ViewKey] = translatedError;

                            // if this is a QASelection, the error should be set on related MultiLineItems as well
                            if (selection instanceof QASlipSelection)
                            {
                                var mitems = selection.findRelatedMultiLineItems();
                                for (var i in mitems)
                                    this.Errors[mitems[i].ViewKey] = translatedError;
                            }
                        }

                        if (selection.Live && (line.Status == -2))
                        {
                            selection.Valid = false;
                            selection.LineClosed = true;
                            selection.Error = $dict.bs("LineClosed");
                        }

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);

                        errorsHelper.AlertIfSelectionUpdated(selection);
                    }

                    break;
                }
            case -2: //Bets Error
                {
                    for (var bIdx in result.Bets)
                    {
                        var bet = result.Bets[bIdx];
                        if (bet.BetTypeID == BetTypes.Combo || bet.BetTypeID == BetTypes.VirtualCombo ||
                            bet.BetTypeID == BetTypes.System || bet.BetTypeID == BetTypes.VirtualSystem ||
                            bet.BetTypeID == BetTypes.Teaser || bet.BetTypeID == BetTypes.VirtualTeaser)
                        {
                            this.CurrentError = this.translateBetSlipError(bet, null, true);
                            continue;
                        }
                        var selID = bet.SelectionsMapped[0].SelectionID;
                        var line = Array.find(result.Selections, function (item) { return item.ID == selID; });
                        if (!line) continue;

                        var selection = Array.find(betSlipSelections, function (item) { return item.ViewKey == line.ViewKey; });
                        if (!selection) continue;

                        this.Errors[selection.ViewKey] = null;

                        if (!line.IsEachWaySelection) selection.processBetUpdate(line);

                        this.updateSelection(selection);
                        this.updateSelectionData(selection);

                        if (selection.UpdatedOdds) line.ErrorText = $dict.bs("OddsChanged");
                        if (selection.UpdatedPoints) line.ErrorText = $dict.bs("PointsChanged");
                        if (selection.UpdatedOdds && selection.UpdatedPoints) line.ErrorText = $dict.bs("AllChanged");

                        this.Errors[selection.ViewKey] = this.translateBetSlipError(bet, selection, false);
                        this.setError(this.Errors[selection.ViewKey]);

                        if (selection.Live && (line.Status == -2))
                        {
                            selection.Valid = false;
                            selection.LineClosed = true;
                            selection.Error = $dict.bs("LineClosed");
                        }
                    }

                    break;
                }
            case -3: //Free bet error
                {
                    for (var idx in result.FreeBets)
                    {
                        var fBet = result.FreeBets[idx];
                        var viewKey = fBet.MappingKey;
                        var selection = betSlipSelections[viewKey];
                        if (!selection) continue;

                        this.Errors[selection.ViewKey] = this.translateBetSlipError(bet, selection, false);
                        selection.FreeBet = null;
                    }

                    break;
                }
            case -4: //Betting limit error
                {
                    var errorText = $string('General').BettingLimitHit;
                    errorText = errorText.replace('$VALUE$', UserInfo.current.toUserFormatedCurrencyString(result.RemainingBettingLimit));
                    this.setError(errorText);
                    BetSlip.sendBetLimitsErrorToJSApi(result);
                    break;
                }
            case -18:
                {
                    var errorText = this.getVerificationErrorMessage(result.ErrorCode);
                    this.setError(errorText);
                    break;
                }
        }
    },

    CheckForUKEachWayIncluded: function (key)
    {
        for (var idx in BetSlip.Selections)
        {
            var item = BetSlip.Selections[idx];
            if (item.BetType == 7 && item.EachWaySingleIncluded)
            {
                return true;
            }
        }

        for (var idx in this.SelectedEWVariants)
        {
            if (this.SelectedEWVariants[idx] == true)
                return true;
        }

        for (var idx in this.SelectedSystemEWVariants)
        {
            if (this.SelectedSystemEWVariants[idx] == true)
                return true;
        }

        return false;
    },

    updateEachWay: function (mode, viewKey)
    {
        switch (mode)
        {
            case UKSlipModeSubModes.Single:
                SingleSPSlipMode.prototype.updateEachWay.call(this, viewKey);
                break;
            case UKSlipModeSubModes.Combo:
                ComboSPSlipMode.prototype.updateEachWay.call(this, viewKey);
                break;
            case UKSlipModeSubModes.System:
                if (viewKey)
                {
                    if (this.SelectedSystemEWVariants[viewKey])
                    {
                        this.SelectedSystemEWVariants[viewKey] = false;
                    }
                    else
                    {
                        this.SelectedSystemEWVariants[viewKey] = true;
                    }
                }

                this.toggleEachWayIncluded();

                BetSlip.updateView();
                BetSlip.updateBSFooter();
                break;
        }
    },

    CheckForEachWayIncluded: function(key, useComboPrototype)
    {
        if (typeof (BetSlipRegulations) !== "undefined" && BetSlipRegulations.hasOwnProperty('HideEachWay') && BetSlipRegulations.HideEachWay)
        {
            return false;
        }

        if (useComboPrototype)
        {
            return ComboSPSlipMode.prototype.CheckForEachWayIncluded.call(this, key);
        }

        if (key)
        {
            return !!this.SelectedEWVariants[key] || !!this.SelectedSystemEWVariants[key];
        }

        for (var idx in this.SelectedEWVariants)
        {
            if (this.SelectedEWVariants[idx] == true)
                return true;
        }

        for (var idx in this.SelectedSystemEWVariants)
        {
            if (this.SelectedSystemEWVariants[idx] == true)
                return true;
        }

        return false;
    },

    getEachWayTotalGain: function ()
    {
        BetSlip.lastUpdate = new Date();

        var totalEachWayGain = SingleSPSlipMode.prototype.getEachWayTotalGain.call(this),
            roundedGain;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;

        if (this.isTeaserOn)
        {
            totalEachWayGain = totalEachWayGain + TeaserSPSlipMode.prototype.getEachWayTotalGain.call(this);
            roundedGain = GainUtil.round(totalEachWayGain, roundingMode);
            return roundedGain;
        }

        totalEachWayGain = totalEachWayGain + ComboSPSlipMode.prototype.getEachWayTotalGain.call(this);

        var ewGain = 0;
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        for (var key in this.SystemDeposits)
        {
            if (!this.SelectedSystemEWVariants || !this.SelectedSystemEWVariants[key]) continue;

            ewGain += this.calcNamedSystemGain(parseInt(key), this.SystemDeposits[key], true, grpCount);
        }

        ewGain = GainUtil.round(ewGain, roundingMode);

        totalEachWayGain = totalEachWayGain + ewGain;

        roundedGain = GainUtil.round(totalEachWayGain, roundingMode);

        return roundedGain;
    },

    setStakeToAll: function ()
    {
        var setStakeForAllElement = document.getElementById("setStakeForAll");
        var toReturnForAllElement = document.getElementById("singlesAll_return_value");
        var deposit = new Number(setStakeForAllElement ? BetSlip.SanitizeNumberString(setStakeForAllElement.value) : 0);
        if (isNaN(deposit))
        {
            deposit = 0;
        }

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            for (var selection in BetSlip.Selections)
            {
                var dropDown = document.getElementById("dropdown" + BetSlip.Selections[selection].ViewKey);
                if (dropDown && dropDown.selectedIndex != -1)
                {
                    dropDown.options[dropDown.selectedIndex].selected = false;
                    dropDown.selectedIndex = -1;
                    dropDown.value = 0;
                }
            }
        }

        deposit = BetSlip.RoundToStakeRounding(deposit, setStakeForAllElement);

        var depositElements = document.querySelectorAll(".singleDepositField"), i, len, k;

        if (!deposit)
        {
            for (i = 0, len = depositElements.length; i < len; i += 1)
            {
                depositElements[i].value = "";
            }
            for (var k in this.Deposits)
            {
                SingleSPSlipMode.prototype.setSingleReturnValue.call(this, k);
                
                this.Deposits[k] = 0;
                this.StakeUpdatedByUser[k] = true;
            }
            for (var i in BetSlip.Selections) BetSlip.Selections[i].StakeUpdatedByUser = false;
            this.AllBetsDeposit = 0;

            if (toReturnForAllElement)
            {
                toReturnForAllElement.innerHTML = this.getSinglesAllReturn();
            }
        }
        else
        {
            for (i = 0, len = depositElements.length; i < len; i += 1)
            {
                depositElements[i].value = deposit;
            }

            for (k in this.Deposits)
            {
                var sel = BetSlip.Selections[k];
                if (!sel || (sel.CastSelection && !sel.VisibleCastSelection))  continue;

                if (sel.isToWin())
                {
                    // If the selection is actually a showing a "to win" box, then calculate the deposit from the 
                    // value entered in the "set stake for all" field
                    var toWin = deposit;
                    this.Deposits[k] = sel.getDepositFromToWin(toWin);
                    this.ToWins[k] = toWin;
                }
                else
                {
                    this.Deposits[k] = deposit;
                }
                SingleSPSlipMode.prototype.recalcEachWay.call(this, k);
                this.StakeUpdatedByUser[sel.ViewKey] = true;
                sel.StakeUpdatedByUser = true;
                
                SingleSPSlipMode.prototype.setSingleReturnValue.call(this, k, this.getSingleReturn(k));
            }
            var dropDown = document.getElementById("dropdownForAllBets");
            if (dropDown && dropDown.selectedIndex != -1)
            {
                dropDown.options[dropDown.selectedIndex].selected = false;
                dropDown.value = 0;
                dropDown.selectedIndex = -1;
            }
            this.deselectStakeDropdown();

            this.AllBetsDeposit = deposit;

            if (toReturnForAllElement)
            {
                toReturnForAllElement.innerHTML = this.getSinglesAllReturn();
            }
        }
        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    recalcEachWay: function (viewKey)
    {
        SingleSPSlipMode.prototype.recalcEachWay.call(this, viewKey);
        ComboSPSlipMode.prototype.recalcEachWay.call(this);
    },

    getAvailableFreeBets: function (mode, selection, comboSelections, comboKey, teaserSelections)
    {
        var freeBets = [];
        var balance = 0;
        if (UserInfo.current)
        {
            freeBets = UserInfo.current.FreeBets;
            balance = UserInfo.current.getBalance();
        }
        else
        {
            freeBets = UserInfo.getFreeBetsFromStorage();
        }

        if (!comboSelections) comboSelections = this.getSelectionsInCombo();

        if (!teaserSelections) teaserSelections = this.getSelectionsInTeaser();
        
        var tokens = [];

        for (var i in freeBets)
        {
            var freeBet = freeBets[i];
            var reservedFreeBets = this.getReservedFreeBets();
            var notAvailableFreeBets = Array.find(reservedFreeBets.ReservedFreeBets, function (e)
            {
                return e == freeBet.BonusID;
            });
            if (notAvailableFreeBets) continue;

            //Avoid duplicating free bets in selection
            if (selection && Array.find(BetSlip.Selections, function (sel) { return (sel.FreeBet && sel.ViewKey != selection.ViewKey && sel.FreeBet.BonusID == freeBet.BonusID); })) continue;
            if (mode != UKSlipModeSubModes.Single && BetSlip.Selections.any(function (sel) { return sel.FreeBet && sel.FreeBet.BonusID == freeBet.BonusID; })) continue;

            //Avoid duplicating free bets in variants
            var allowed = true;

            for (var idx in this.FreeBets)
            {
                if (idx == comboKey || !this.FreeBets[idx]) continue;
                if (this.FreeBets[idx].BonusID == freeBet.BonusID)
                {
                    allowed = false;
                    break;
                }
            }

            if (!allowed) continue;

            if (!BetSlip.SkipClientUserBalanceCheckForSingles && freeBet.IsRiskFreeBet && balance < freeBet.Amount)
            {
                continue;
            }

            if (mode == UKSlipModeSubModes.Single && freeBet.IsAllowedForLine(selection, selection.BetType))
            {
                if (this.isTeaserOn && this.FreeBet && this.FreeBet.BonusID == freeBet.BonusID) continue;
                tokens.push(freeBet);
            }

            if (!this.isTeaserOn && mode == UKSlipModeSubModes.Combo && freeBet.IsAllowedForComboBet(comboSelections, this.Odds[comboKey]))
                tokens.push(freeBet);

            if (this.isTeaserOn && mode == UKSlipModeSubModes.Teaser && freeBet.IsAllowedForTeaserBet(teaserSelections, this.getOdds()))
                tokens.push(freeBet);
        }

        return tokens;
    },

    getFreeBetDeposit: function ()
    {
        var fbd = SingleSPSlipMode.prototype.getFreeBetDeposit.call(this);

        if (this.isTeaserOn)
        {
            fbd = fbd + TeaserSPSlipMode.prototype.getFreeBetDeposit.call(this);
        }
        else
        {
            fbd = fbd + ComboSPSlipMode.prototype.getFreeBetDeposit.call(this);
        }
        return fbd;
    },

    getRiskFreeBetDeposit: function ()
    {
        var fbd = 0;

        if (this.isTeaserOn)
        {
            fbd = SingleSPSlipMode.prototype.getRiskFreeBetDeposit.call(this) + TeaserSPSlipMode.prototype.getRiskFreeBetDeposit.call(this);
        }
        else
        {
            fbd = ComboSPSlipMode.prototype.getRiskFreeBetDeposit.call(this);
        }

        return fbd;
    },

    CheckForSPIncluded: function ()
    {
        var spIncluded = SingleSPSlipMode.prototype.CheckForSPIncluded.call(this) || ComboSPSlipMode.prototype.CheckForSPIncluded.call(this);
        return spIncluded;
    },

    CheckForNoOddsSelectionIncluded: function ()
    {
        var noOddsSelectionIncluded = SingleSPSlipMode.prototype.CheckForNoOddsSelectionIncluded.call(this) || ComboSPSlipMode.prototype.CheckForNoOddsSelectionIncluded.call(this);
        return noOddsSelectionIncluded;
    },

    // fills combo stake with free bet amount
    setVariantFreeBetStake: function (key, freeBet)
    {
        this.StakesUpdatedByUser[key] = true;

        var amount = freeBet ? freeBet.Amount : 0;

        if (amount && amount > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints && amount.toString().indexOf('.') > -1)
        {
            amount = Math.floor(amount);
            var stakeElement = document.getElementById("stakebox_combo_" + key);
            if (stakeElement)
            {
                stakeElement.value = BetSlip.getStakeBoxValue(amount);
            }
        }

        this.setDeposit(key, amount);
    },
    removeBuyPoints : function(selection)
    {
    
     if(selection.IsBuyPoint)
     {
        selection.IsBuyPoint = false;

        selection.BuyPointsOdds = -1;
        selection.BuyPointsPoints = -1;
        selection.BuyPointSelectedIndex = null;
     }
    },
    toggleTeaser: function ()
    {
        this.isTeaserOn = !this.isTeaserOn;

        if(this.isTeaserOn)
        {
            for(var k in this.Deposits)
            {
              this.Deposits[k] = 0;
              this.removeBuyPoints(BetSlip.Selections[k]);
            }
        }
       

        this.updateView();
        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    setTeaserMaxBet: function ()
    {
        if (this.isTeaserOn)
        {
            var teaserMaxBet = this.getTeaserMaxBet(),
                teaserbetInputFields = document.querySelectorAll('div#jsBetslipMultiples input[rel=teaserbetInputFields]'), i, len;

            for (i = 0, len = teaserbetInputFields.length; i < len; i += 1)
            {
                teaserbetInputFields[i].value = BetSlip.getStakeBoxValue(teaserMaxBet);
            }

            TeaserSPSlipMode.prototype.setDeposit.call(this, teaserMaxBet);

            var toReturnValue = this.getTeaserReturn() || '',
                returnBox = document.getElementById("teaser_return_value");

            if (returnBox)
            {
                returnBox.innerHTML = toReturnValue;
            }
        }
    },

    setMaxBet: function (viewKey)
    {
        SingleSPSlipMode.prototype.setMaxBet.call(this, viewKey)

        var toReturnForAllElement = document.getElementById("singlesAll_return_value");
        
        if (toReturnForAllElement)
        {
            toReturnForAllElement.innerHTML = '';
        }
    },

    teaserOptionChanged: function (element)
    {
        TeaserSPSlipMode.OptionChanged(element);
    },

    isSelectionInTeaser: function (element)
    {
        var selections = this.getSelectionsInTeaser()
        if (Array.find(selections, function (sel) { return sel.ViewKey == element.ViewKey; }))
            return true;
        return false;

    },

    //Changed to use setTeaserStake in slip to have different methods for singles
    setTeaserStake: function (element)
    {
        var st = BetSlip.SanitizeNumberString(element.value);
        if (isNaN(st))
        {
            st = 0;
        }

        st = BetSlip.RoundToStakeRounding(st, element);

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            var defaultStake = BetSlip.getCurrencyWithStakeValues().DefaultStake;
            this.TeaserStakeUpdatedByUser = st != defaultStake;
            this.deselectStakeDropdown();
        }

        this.Deposit = st;
        
        var toReturnValue = this.getTeaserReturn() || '',
            returnBox = document.getElementById("teaser_return_value");

        if (returnBox)
        {
            returnBox.innerHTML = toReturnValue;
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    //Override since it is used in both teaser and single bets
    setStake: function (viewKey, element, deposit)
    {
        SingleSPSlipMode.prototype.setStake.call(this, viewKey, element, deposit);

        var toReturnForAllElement = document.getElementById("singlesAll_return_value");

        if (toReturnForAllElement)
        {
            toReturnForAllElement.innerHTML = '';
        }
    },

    setTeaserDeposit: function (deposit)
    {
        this.Deposit = typeof(deposit) == 'string' ? StakeInputParser.parse(deposit) : deposit;
        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    setDeposit: function (viewKey, deposit)
    {
        ComboSPSlipMode.prototype.setDeposit.call(this, viewKey, deposit);
    },

    GetEachWayData: function ()
    {
        var res = new Object();
        res["Odds"] = this.EWOdds;
        res["SelectedVariants"] = this.SelectedEWVariants;
        return res;
    },

    calcSystemVariants: function ()
    {
        SystemSPSlipMode.prototype.calcVariants.call(this);

        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);

        this.NamedSystemEWOdds = [];
        this.NamedSystemEWOdds[grpCount] = 0;
        this.NamedSystemEWOdds[grpCount + 1] = 0;
        this.SystemKeys[grpCount] = this.SystemDeposits[grpCount] > 0 ? true : false;
        this.SystemKeys[grpCount + 1] = this.SystemDeposits[grpCount + 1] > 0 ? true : false;

        for (var vidx in this.NamedSystemVariants)
        {
            var variant = this.NamedSystemVariants[vidx];

            if (this.CheckForSystemEachWayIncluded())
            {
                var ewOdds = this.calcSystemVariantOdds(variant, true);

                var totalEWOdds = 0;

                for (var i = 2; i < Array.getLength(ewOdds) ; i++)
                {
                    totalEWOdds += ewOdds[i];
                }

                this.NamedSystemEWOdds[grpCount] = totalEWOdds;
                this.NamedSystemEWOdds[grpCount + 1] = totalEWOdds + ewOdds[1];
            }
        }
    },

    CheckForSystemEachWayIncluded: function ()
    {
        for (var idx in this.SelectedSystemEWVariants)
        {
            if (this.SelectedSystemEWVariants[idx] == true)
                return true;
        }
        return false;
    },

    CheckForSystemEachWayEnabled: function ()
    {
        var selections = this.getSelectionsInCombo();
        for (var idx in selections)
        {
            var sel = selections[idx];
            if (ComboSPSlipMode.isEachWayEnabled(sel))
                return true;
        }
        return false;
    },

    rebuildSystemStake: function (fromStakeBox,key)
    {
        if (this.IsBetInProcess) return;

        var element = document.getElementById("stakebox_system_" + key), st;

        if (fromStakeBox && element)
        {
            st = BetSlip.SanitizeNumberString(element.value);
            if (isNaN(st))
            {
                st = 0;
            }

            this.SystemKeys[key] = st == 0 ? false : true;
            this.SystemDeposits[key] = st;
        }

        var toReturnValue = this.getSystemVariantReturn(key) || '',
            returnBox = document.getElementById("system_return_value_{0}".format(key));

        if (returnBox)
        {
            returnBox.innerHTML = toReturnValue;
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    getSystemNumberOfBets: function (systemKey)
    {
        var ComboGroups = this.getSelectionGroups();
        var groupCount = Array.getLength(ComboGroups);

        var bets = 0;

        var hasSingles = this.isSystemWithSingleBets(systemKey);

        if (systemKey < groupCount)
        {   //One variant (doubles, trebles, 4folds etc)
            bets += this.Variants[systemKey];
        }
        else
        {   //Named system - all variants, possibly with singles      
            bets += this.getNamedSystemSize(ComboGroups, systemKey);
        }

        if (this.SelectedSystemEWVariants[systemKey])
            bets *= 2;

        return bets;
    },

    getSystemTotalNumberOfBets : function()
    {
        var bets = 0;

        for (var systemKey in this.SystemKeys)
        {                    
            var currentBets = this.getSystemNumberOfBets(systemKey);
            if (this.SystemDeposits[systemKey] > 0)
            {
                bets += currentBets;
            }
        }

        return bets;       
    },

    getSystemTotalGain : function()
    {
        BetSlip.lastUpdate = new Date();

        var totalGain = 0;
        var roundedGain = 0;
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);
        
        for (var systemKey in this.SystemKeys)
        {
            if (!this.SystemKeys[systemKey])
            {
                continue;
            }
                 
            totalGain += this.calcNamedSystemGain(parseInt(systemKey), this.SystemDeposits[systemKey], false, grpCount);
        }

        roundedGain = GainUtil.round(totalGain, roundingMode);
        return roundedGain;
    },

    GetSystemUKEachWayData: function ()
    {
        var res = new Object();
        res["Odds"] = this.NamedSystemEWOdds;
        res["SelectedVariants"] = this.SelectedSystemEWVariants;
        return res;
    },

    getSystemTotalDeposit : function()
    {
        var totalDeposit = 0;
        for (var systemKey in this.SystemKeys)
        {
            if (!this.SystemDeposits[systemKey])
            {
                continue;
            }

            totalDeposit += this.SystemDeposits[systemKey] * this.getSystemNumberOfBets(systemKey);
        }

        return totalDeposit;
    },

    canActivateSystem: function ()
    {
        if (BetSlip.CheckForCastSelectionIncluded())
            return 0;

        var ComboGroups = this.getSelectionGroups(false);
        var grpCount = Array.getLength(ComboGroups);

        if (grpCount >= this.SystemMinSelections)
        {
            return true;
        }
        else
        {
            return false;
        }
    },
    
    getSystemBetNames: function ()
    {
        return this.SystemNames;
    },

    clearSystemDeposits:function()
    {
        this.SystemDeposits = {};
        this.SystemKeys = {};
    },

    clearSystemEWVariants: function ()
    {
        this.SelectedSystemEWVariants = [];
    },

    getTeaserFreeBets: function ()
    {
        var freeBets = [];
        if (this.FreeBet && this.isTeaserOn)
        {
            freeBets.push(this.FreeBet);
            return freeBets;
        }

        return null;
    },

    getComboFreeBets: function ()
    {
        if (this.isTeaserOn)
        {
            return null;
        }
        //Filter out null/undefined members
        var freeBets = Array.findAll(this.FreeBets, function (fb) { return (fb); });

        return freeBets;
    },

    toggleMultiples: function (className)
    {
        var toggleElem = document.getElementById('jsUkToggleableHeader');

        toggleElem.classList.toggle(className);
        this.ShowMultiples = !this.ShowMultiples;
    },

    setTeaserStakeDropDown: function (element)
    {
        var stakebox = document.getElementById("stakebox_teaser");
        if (element.selectedIndex != -1)
        {
            stakebox.value = element.value;
            element.options[element.selectedIndex].selected = false;
            element.value = 0;
            element.selectedIndex = -1;
        }
        BetSlip.CurrentMode.setTeaserStake(stakebox);
    },

    setSystemDeposit: function (viewKey, deposit)
    {
        if (this.IsBetInProcess) return;

        this.SystemDeposits[viewKey] =  typeof(deposit) == 'string' ? StakeInputParser.parse(deposit) : deposit;

        var toReturnValue = this.getSystemVariantReturn(viewKey) || '',
            returnBox = document.getElementById("system_return_value_{0}".format(viewKey));

        if (returnBox)
        {
            returnBox.innerHTML = toReturnValue;
        }


        BetSlip.updateBSFooter();
        BetSlip.saveState();
    },

    setComboMaxBet: function (comboKey)
    {
        if (this.IsBetInProcess) return;

        var comboMaxBet = this.getComboMaxBet(this.getSelectionsInCombo(), comboKey),
            element = document.getElementById("stakebox_combo_{0}".format(comboKey));

        if (!element)
        {
            return;
        }

        element.value = BetSlip.getStakeBoxValue(comboMaxBet);
        comboMaxBet = BetSlip.RoundToStakeRounding(comboMaxBet, element);
        this.setDeposit(comboKey, comboMaxBet);

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            this.StakesUpdatedByUserForDropDown[comboKey] = true;

            this.deselectStakeDropdown(comboKey);
        }

        // add a flag for the current combo key that the user updated the combo amount
        this.StakesUpdatedByUser[comboKey] = true;
    },

    setSystemMaxBet: function (sysKey)
    {
        if (this.IsBetInProcess) return;

        var systemMaxBet = this.getSystemMaxBet(this.getSelections(), sysKey),
            element = document.getElementById("stakebox_system_{0}".format(sysKey));

        if (!element)
        {
            return;
        }

        element.value = BetSlip.getStakeBoxValue(systemMaxBet);
        systemMaxBet = BetSlip.RoundToStakeRounding(systemMaxBet, element);
        this.setSystemDeposit(sysKey, systemMaxBet);
        this.SystemKeys[sysKey] = this.SystemDeposits[sysKey] <= 0 ? false : true;

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            this.SystemStakesUpdatedByUser[sysKey] = true;

            this.deselectStakeDropdown(sysKey);
        }
    },

    getOdds: function (oddStyle)
    {
        var odds;

        if (this.isTeaserOn)
        {
            odds = TeaserSPSlipMode.prototype.getOdds.call(this);
        }
        else
        {
            odds = ComboSPSlipMode.prototype.getOdds.call(this, oddStyle)
        }

        return odds;
    },

    getRate: function (lines, out)
    {
        var rate;

        if (this.isTeaserOn)
        {
            rate = TeaserSPSlipMode.prototype.getRate.call(this);
        }
        else
        {
            rate = ComboSPSlipMode.prototype.getRate.call(this, lines, out)
        }

        return rate;
    },

    setTeaserFreeBetStake: function (freeBet)
    {
        if (!this.TeaserStakeUpdatedByUser)
        {
            var amount = freeBet ? freeBet.Amount : 0;
            if (amount > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints && amount.toString().indexOf('.') > -1)
            {
                amount = Math.floor(amount);
                document.getElementById('stakebox_teaser').value = BetSlip.getStakeBoxValue(amount);
            }

            this.Deposit = amount;

            BetSlip.updateBSFooter();
        }
    },

    hasTeaserSelections: function()
    {
        var teaserEnabledSelections = Array.findAll(BetSlip.Selections, function (sel) { return sel.TeaserBetIsEnabled; });

        return Array.getLength(teaserEnabledSelections) >= this.MinSelections ? true : false;
    },
  
    getSinglesAllReturn: function ()
    {
        if (BetSlip.Selections.any(function (sel) { return sel.isSPSelection(); }))
        {
            return $dict.bs("NotAvailable");
        }
        
        var totalGain = SingleSPSlipMode.prototype.getTotalGain.call(this) + SingleSPSlipMode.prototype.getEachWayTotalGain.call(this) - SingleSPSlipMode.prototype.getFreeBetDeposit.call(this);
        totalGain = totalGain.toFixed(12);
        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var roundedGain = GainUtil.round(totalGain, roundingMode);

        return Bets.formatCurrencyStake(roundedGain, UserInfo.current ? BetSlip.UserCurrencyCode: "");
    },
       
    getSystemVariantReturn: function (sysKey)
    {
        var variantSelections = this.getSelectionsInCombo();

        if (variantSelections.any(function (sel) { return sel.isSPSelection(); }) && this.SystemDeposits[sysKey] > 0)
        {
            return $dict.bs("NotAvailable");
        }
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);

        var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
        var totalGain = this.calcNamedSystemGain(parseInt(sysKey), this.SystemDeposits[sysKey], false, grpCount);

        if (this.SelectedSystemEWVariants[sysKey])
        {
            totalGain += this.calcNamedSystemGain(parseInt(sysKey), this.SystemDeposits[sysKey], true, grpCount);
        }

        totalGain = totalGain.toFixed(12);
        var roundedGain = GainUtil.round(totalGain, roundingMode);

        return Bets.formatCurrencyStake(roundedGain, UserInfo.current ? BetSlip.UserCurrencyCode: "");;
    },

    getTeaserReturn: function (sysKey)
    {
        var roundedGain = TeaserSPSlipMode.prototype.getTotalGain.call(this) - TeaserSPSlipMode.prototype.getFreeBetDeposit.call(this);

        if (roundedGain)
        {
            var roundingMode = typeof BetSlip != "undefined" && BetSlip.GainRoundingMode || 0;
            roundedGain = GainUtil.round(roundedGain, roundingMode);
        }

        return Bets.formatCurrencyStake(roundedGain, UserInfo.current ? BetSlip.UserCurrencyCode: "");;
    },

    getOddsSum: function ()
    {
        var lines = [];
        var result = 0;

        if (this.isTeaserOn)
        {
            lines = this.getSelectionsInTeaser();
            for (var lIdx in lines)
            {
                var line = lines[lIdx];
                result += line.getRealOdd() - 1;
            }
        }
        else
        {
            lines = this.getSelectionsInCombo();
            for (var lIdx in lines)
            {
                var line = lines[lIdx];
                var EUodds = this.CheckForEachWayIncluded() && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW
                    ? line.getRealOddEW()
                    : line.getRealOdd();

                result += EUodds - 1;
            }
        }
        
        return result;
    },

    getSystemMaxBet: function (lines, sysKey)
    {
        if (!lines) 
        {
            lines = this.getSelections();
        }

        var maxBetComboFromRisk = this.getSystemMaxBetFromMaxRisk(lines, sysKey);

        var maxBetCombo;
        var singlesMaxBet; // used in case there are single in the currently selected system option

        var totalOddsSum = this.getSystemOddsSum(sysKey);
        var ewIncluded = this.SelectedSystemEWVariants[sysKey];

        var getRealOdds = function (line)
        {
            return ewIncluded && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW ? line.getRealOddEW() : line.getRealOdd();
        };

        var maxSystemBetOts = 0;

        for (var key in lines)
        {
            var line = lines[key];
            var currMaxBetCombo = ewIncluded ? line.MaxStakeComboEachWay : line.MaxBetCombo;
            if (!maxBetCombo)
            {
                maxBetCombo = currMaxBetCombo;
            }
            else if (line.MaxBetCombo < maxBetCombo)
            {
                maxBetCombo = currMaxBetCombo;
            }

            if (maxSystemBetOts == 0 || line.MaxBetCombo < maxSystemBetOts)
            {
                maxSystemBetOts = line.MaxBetCombo;
            }

            if (!singlesMaxBet || line.maxBet * 1 < singlesMaxBet)
            {
                singlesMaxBet = line.maxBet * 1;
            }

            if (totalOddsSum > 0 && line.SingleSelectionRisk > 0 && line.MaxRisk > 0 && getRealOdds(line) > 0)
            {
                var maxRiskComboFromSSR = line.SingleSelectionRisk * line.MaxRisk / ((getRealOdds(line) - 1) / totalOddsSum);

                var divider = this.getSystemMaxGainCoef(sysKey);
                var maxBetFromSSR = divider > 0 ? Math.floor(maxRiskComboFromSSR / divider) : 0;
                if ((maxBetFromSSR > 0 && maxBetFromSSR < maxBetCombo) || maxBetCombo == 0) {
                    maxBetCombo = maxBetFromSSR;
                }
            }
        }

        var maxSystemBet = maxBetCombo;

        //  If there are single bets in the currently selected system option
        if (this.isSystemWithSingleBets(sysKey))
        {
            maxSystemBet = (maxBetCombo <= singlesMaxBet ? maxBetCombo : singlesMaxBet);
        }

        if ((maxBetComboFromRisk > 0 && maxBetComboFromRisk < maxSystemBet) || !maxSystemBet)
        {
            maxSystemBet = maxBetComboFromRisk;
        }

        if (!maxSystemBet)
        {
            // fallback ots
            maxSystemBet = maxSystemBetOts;
        }

        if (maxSystemBet > 0 && BetSlip.StakeRounding == BetSlip.StakeRoundingMethods.ZeroDecimalPoints)
        {
            maxSystemBet = Math.floor(maxSystemBet);
        }
        else
        {
            maxSystemBet = Math.floor(maxSystemBet * 100) / 100;
        }

        return maxSystemBet;
    },

    getSystemMaxBetFromMaxRisk: function (linesSafe, sysKey)
    {
        var maxRiskCombo = this.SelectedSystemEWVariants[sysKey]
            ? Array.getMinValue(linesSafe, function (line) { return line.MaxRiskComboEachWay > 0; }, function (line) { return line.MaxRiskComboEachWay; })
            : Array.getMinValue(linesSafe, function (line) { return line.MaxRiskCombo > 0; }, function (line) { return line.MaxRiskCombo; });

        var gainDivider = this.getSystemMaxGainCoef(sysKey);
        var maxBetComboFromRisk = maxRiskCombo && gainDivider > 0
            ? (maxRiskCombo / gainDivider)/* * (1 - ewGainPercentage) */
            : 0;

        return Math.floor(maxBetComboFromRisk * 10) / 10;
    },

    getSystemMaxGainCoef: function (sysKey)
    {
        var ComboGroups = this.getSelectionGroups();
        var grpCount = Array.getLength(ComboGroups);

        var totalOdds = 0;

        totalOdds = this.calcGain(this.NamedSystemOdds[sysKey], 1);

        var numberOfBets = this.SelectedSystemEWVariants[sysKey] ? this.getSystemNumberOfBets(sysKey) / 2 : this.getSystemNumberOfBets(sysKey);
        return totalOdds - numberOfBets;
    },

    getSystemOddsSum: function (sysKey)
    {
        var lines = this.getSelections();
        var result = 0;
        for (var lIdx in lines)
        {
            var line = lines[lIdx];
            var EUodds = this.SelectedSystemEWVariants[sysKey] && ComboSPSlipMode.isEachWayEnabled(line) && line.getRealOddEW
                ? line.getRealOddEW()
                : line.getRealOdd();

            result += EUodds - 1;
        }

        return result;
    },

}, TUKSPSlipMode);


function rebuildUKVariantStakeSP(key)
{
    var mode = BetSlip.CurrentMode;
    var element = document.getElementById("stakebox_combo_" + key), st;

    if ((mode instanceof UKSPSlipMode) && element)
    {
        st = BetSlip.SanitizeNumberString(element.value);
        if (isNaN(st))
        {
            st = 0;
        }

        st = BetSlip.RoundToStakeRounding(st, element);

        mode.setDeposit(key, st);

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            mode.StakesUpdatedByUserForDropDown[key] = true;

            mode.deselectStakeDropdown(key);
        }
    }

    // add a flag for the current combo key that the user updated the combo amount
    mode.StakesUpdatedByUser[key] = true;
};

function rebuildUKVariantStakeDropDown(key)
{
    var dropdown = document.getElementById("dropdown_combo_" + key);
    var element = document.getElementById("stakebox_combo_" + key);
    element.value = dropdown.options[dropdown.selectedIndex].text;

    rebuildUKVariantStakeSP(key);
};

function rebuildUKSystemStake(fromStakeBox, key)
{
    if (BetSlip.CurrentMode instanceof UKSPSlipMode)
    {
        BetSlip.CurrentMode.rebuildSystemStake(fromStakeBox, key);

        if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
        {
            setUKSystemStakeUpdatedByUser(key);
        }
    }
};

function setUKSystemStakeUpdatedByUser(key)
{
    var defaultStake = BetSlip.getCurrencyWithStakeValues().DefaultStake;
    BetSlip.CurrentMode.SystemStakesUpdatedByUser[key] = BetSlip.CurrentMode.SystemDeposits[key] != defaultStake;
    BetSlip.CurrentMode.deselectStakeDropdown();
};

function rebuildUKSystemVariantStakeDropDown(fromStakeBox, key)
{
    var dropdown = document.getElementById("dropdown_system_" + key);
    var element = document.getElementById("stakebox_system_" + key);
    element.value = dropdown.options[dropdown.selectedIndex].text;

    rebuildUKSystemStake(fromStakeBox, key);

    if (BetSlip.isDefaultStakeAndStakeValuesAvailable())
    {
        setUKSystemStakeUpdatedByUser(key);
    }
};

UKSPSlipMode.setFreeBet = function (subMode, select, viewKey)
{
    var mode = BetSlip.CurrentMode;

    if (typeof UKSPSlipMode != "undefined" && !(mode instanceof UKSPSlipMode)) return;

    switch (subMode)
    {
        case UKSlipModeSubModes.Single:
            var bonusID = select.value * 1;

            var selection = BetSlip.Selections[viewKey];

            if (!selection) return;

            var freeBet = UserInfo.current.FreeBets[bonusID];
            if (freeBet)
            {
                if (Array.find(BetSlip.Selections, function (sel) { return (sel.FreeBet != null && sel.ViewKey != selection.ViewKey && sel.FreeBet.BonusID == freeBet.BonusID); })) return;

                if (freeBet.IsAllowedForLine(selection, selection.BetType))
                {
                    selection.FreeBet = freeBet;
                    SingleSPSlipMode.prototype.setFreeBetStake.call(BetSlip.CurrentMode, viewKey, freeBet);
                }
            }
            else if (bonusID == 0)
            {
                selection.FreeBet = null;
                SingleSPSlipMode.prototype.setFreeBetStake.call(BetSlip.CurrentMode, viewKey);
            }
            break;
        case UKSlipModeSubModes.Combo:
            if (mode.Variants[viewKey] != 1)
            {   //Only allow for combos with 1 permutation
                mode.FreeBets[viewKey] = null;
                BetSlip.updateView();
                return;
            }

            var bonusID = select.value * 1;

            var freeBet = UserInfo.current.FreeBets[bonusID];
            if (freeBet)
            {
                for (var idx in mode.FreeBets)
                {
                    if (idx == viewKey || !mode.FreeBets[idx]) continue;
                    if (mode.FreeBets[idx].BonusID == freeBet.BonusID) return;
                }

                if (freeBet.IsAllowedForComboBet(mode.getSelectionsInCombo(), mode.Odds[viewKey]))
                {
                    mode.FreeBets[viewKey] = freeBet;
                    mode.setVariantFreeBetStake(viewKey, freeBet);
                }
            }
            else if (bonusID == 0)
            {
                mode.FreeBets[viewKey] = null;
                mode.setVariantFreeBetStake(viewKey);
            }
            break;
        case UKSlipModeSubModes.Teaser:
            var bonusID = select.value * 1;

            var freeBet = UserInfo.current.FreeBets[bonusID];
            if (freeBet)
            {
                if (freeBet.IsAllowedForTeaserBet(mode.getSelectionsInTeaser(), mode.getOdds()))
                {
                    mode.FreeBet = freeBet;
                    mode.setTeaserFreeBetStake(freeBet);
                }
            }
            else if (bonusID == 0)
            {
                mode.FreeBet = null;
                mode.setTeaserFreeBetStake();
            }
            break;
    }

    BetSlip.updateView();
    BetSlip.updateBSFooter();
    BetSlip.saveState();
};

UKSPSlipMode.setStakeDropDown = function (viewKey, select)
{
    var element = document.getElementById("stake_" + viewKey);
    element.value = select.value * 1;
    BetSlip.CurrentMode.setStake(viewKey, element);
};

UKSPSlipMode.CheckForTeaser = function (viewKey, cbox)
{
    if (BetSlip.CurrentMode instanceof UKSPSlipMode)
    {
        var teaserMode = BetSlip.CurrentMode;

        if (cbox.checked)
        {
            var err = BetSlip.CurrentMode.getTeaserAddError(BetSlip.Selections[viewKey]);
            if (err)
            {
                Array.removeAll(teaserMode.SelectionsInTeaser, function (el) { el == viewKey; });

                cbox.checked = false;
                teaserMode.updateView();
                return;
            }

            if (Array.indexOf(teaserMode.SelectionsInTeaser, viewKey) == -1)
            {
                teaserMode.SelectionsInTeaser.push(viewKey);

                // Remove message "You need to make at least 2 selections applicable for teaser to place a bet!" if now there are two
                if (teaserMode.CurrentError == $dict.bs("SelectionsInTeaser") && Array.getLength(teaserMode.SelectionsInTeaser) >= 2) {
                    teaserMode.CurrentError = false;
                }
            }

            teaserMode.updateView();
        }
        else
        {
            Array.removeAll(teaserMode.SelectionsInTeaser, function (el) { return el == viewKey; });
        }

        BetSlip.updateBSFooter();
        BetSlip.saveState();
        teaserMode.updateView();
    }

    return false;
};

window.UKSPSlipMode = UKSPSlipMode;
window.rebuildUKVariantStakeSP = rebuildUKVariantStakeSP;
window.rebuildUKVariantStakeDropDown = rebuildUKVariantStakeDropDown;
window.rebuildUKSystemStake = rebuildUKSystemStake;
window.setUKSystemStakeUpdatedByUser = setUKSystemStakeUpdatedByUser;
window.rebuildUKSystemVariantStakeDropDown = rebuildUKSystemVariantStakeDropDown;