﻿var Selections = (function (uniSlip)
{
    "use strict";
    var api = {};

    // TSlip.js - buildSelections
    api.isHidden = function (selection)
    {
        return selection.IsEachWaySelection || (selection.CastSelection && !selection.VisibleCastSelection);
    };

    // UniSlipBlock.js - showMaxAmount
    api.hasMaxAmount = function (mode, selection)
    {
        var result = false;

        if (selection)
        {
            result = selection.Valid && selection.maxBet && !selection.Live;
        }
        if (mode && mode.isLiveCombo)
        {
            result = !mode.isLiveCombo();
        }

        if (mode && mode.getTeaserMaxBet)
        {
            result = mode.getTeaserMaxBet();
        }
        // TODO other modes

        return result && api.getMaxAmount(mode, selection) > 0;
    };

    api.getMaxAmount = function (mode, selection)
    {
        if (selection)
        {
            var result = Math.floor(+(selection.getMaxBet ? selection.getMaxBet() : selection.maxBet));
            if (selection.isToWin())
            {
                // Calculate profit win from the max bet (profit = gain - stake)
                result = selection.getToWinFromDeposit(result);
                return Math.floor((result * 100).toFixed(4)) / 100;
            }

            return result;
        }
        if (mode && mode.getComboMaxBet)
        {
            return mode.getComboMaxBet();
        }

        if (mode && mode.getTeaserMaxBet)
        {
            return mode.getTeaserMaxBet();
        }
        // TODO other modes
    };

    // TSlipShared.js - createMultiSelectionBox
    /*globals IsUKBetSlip*/
    api.isInCombo = function (mode, selection)
    {
        return (mode.IsSelectionInCombo && mode.IsSelectionInCombo(selection)) ||
            (mode.SelectionsInTeaser && mode.SelectionsInTeaser.indexOf(selection.ViewKey) > -1 && !IsUKBetSlip);
    };

    // TRegularSelections.js - createBuyPointsSelect
    api.formatBuyPointOdds = function (buyPoint)
    {
        var alternativeOddStyle = uniSlip.getAlternativeOddsStyle();

        return Odds.format(
            Odds.convert(
                buyPoint.Odds,
                undefined, // eventType
                undefined, // lineType
                true,      // clear
                alternativeOddStyle),
           alternativeOddStyle);
    };

    // TRegularSelections.js - createBuyPointsSelect
    api.isBuyPointSelected = function (selection, buyPoint)
    {
        return buyPoint.Points == selection.BuyPointSelectedIndex || 
            (!selection.BuyPointSelectedIndex && selection.Points == buyPoint.Points && selection.Odds == buyPoint.Odds);
    };

    // TRegularSelections.js - buildDescription
    api.teamSwapSelection = function (selection)
    {
        if (selection.IsTeamSwapEnabled)
        {
            return {
                Team1Name: selection.Team2Name,
                Team2Name: selection.Team1Name,
                Score1: selection.Score2,
                Score2: selection.Score1,
                ScoreSetsPlayer1: selection.ScoreSetsPlayer2,
                ScoreSetsPlayer2: selection.ScoreSetsPlayer1
            };
        }

        return selection;
    };

    // TRegularSelections.js - createRegularPickLine
    api.getFullPickLineValue = function (selection, value)
    {       
        return value;
    };

    // TRegularSelections.js - createRegularPickLine
    api.getPickLinePoints = function (mode, selection, context)
    {
        var points = selection.Points;

        /*globals Modes */
        if (context.pointsToReduce)
        {
            points -= selection.BetTypeID == 3 && selection.BetSide == 1 ?
                context.pointsToReduce :
                -context.pointsToReduce;
        }
        else if (selection.IsBuyPoint && !Modes.isTeaserMode(mode))
        {
            points = selection.BuyPointsPoints;
        }

        return points;
    };

    // TRegularSelections.js - createRegularPickLine
    api.showRegularPickLinePoints = function (mode, selection, context)
    {
        return selection.BetTypeID != 1 &&
            selection.SplitType != 4 &&
            (context.isPurchase || (!selection.BuyPoints || (selection.BuyPoints && !(selection.BuyPoints.length > 0))) || Modes.isTeaserMode(mode));
        // TODO resolve what the check for grouped combo, system, teaser will be
    };

    // TRegularSelections.js - createRegularMLPickLine
    api.showRegularMLPickLinePoints = function (selection)
    {
        return selection.EventTypeID == 206 || selection.EventTypeID == 207;
    };

    function getRegularTeamBetSides(selection)
    {
        var team1Name = api.getFullPickLineValue(selection, selection.Team1Name),
            team2Name = api.getFullPickLineValue(selection, selection.Team2Name);

        return [team1Name, undefined, team2Name];
    }

    // TRegularSelections.js - createRegularMLPickLine
    api.getRegularMLBetSide = function (selection)
    {
        var betSides = getRegularTeamBetSides(selection);

        /*globals $string */
        if (api.showRegularMLPickLinePoints(selection))
        {
            betSides[1] = $string("General").Tie;
        }
        else if (selection.EventTypeID == 19 || selection.EventTypeID == 20)
        {
            betSides[1] = $string("General").NoGoals;
        }
        else
        {
            betSides[1] = $string("General").Draw;
        }

        return betSides[selection.BetSide - 1];
    };

    // TRegularSelections.js - createRegularHCPickLine
    api.getRegularHCBetSide = function (selection)
    {
        return getRegularTeamBetSides(selection)[selection.BetSide - 1];
    };

    // TRegularSelections.js - createRegularHCPickLine
    api.showRegularHCPickLinePoints = function (selection)
    {
        return selection.SplitType != 8 && selection.SplitType != 9 && selection.EventTypeID != 195;
    };

    // TRegularSelections.js - createRegularOUPickLine
    api.getRegularOUBetSide = function (selection)
    {
        return selection.BetSide == 1 ? $string("General").Over : $string("General").Under;
    };

    // TSlipShared.js - createPickLineOdds
    api.getFormattedOdds = function (mode, selection)
    {
        if (selection.isSPSelection())
        {
            return $dict.bs("SP");
        }
       
        if (mode && typeof mode.isComboSystemOrTeaserBet == "function" && typeof mode.getAlternativeOddsStyle == "function")
        {
            var oddstyle = mode.getAlternativeOddsStyle();
            return selection.getFormatedOdds(oddstyle);
        }

        return selection.getFormatedOdds();
    };

    // TSlipShared.js - createPickLineOdds
    api.getFormattedOddsEW = function (mode, selection, context)
    {
        if (selection.isSPSelection()) { return $dict.bs("SP"); }

        return api.isEachwayIncluded(mode, selection, context) ?
            selection.getFormatedOddsEW() :
            selection.getFormatedOdds();
    };

    // TSlipShared.js - createPickLineOdds
    api.showPickLineOdds = function (selection) {
        var betTypesWithoutPickLineOdds = [11, 12, 21];
        return betTypesWithoutPickLineOdds.indexOf(selection.BetType) < 0;
    };

    // TRegularSelections.js - createRegularDescription
    api.getLineEventTypeName = function (selection)
    {
        /* globals SplitTypeFactory */
        var selectionBuilderClass = SplitTypeFactory.Create(selection.SplitType);
        var result = selectionBuilderClass.getLineEventTypeName(selection);

        if (selection.Live && selection.EventTypeName != "Live Betting" && selection.LeagueName != "Live Betting")
        {
            result += " " + $string("General").Live;
        }

        return result;
    };

    // TSlipShared.js - createRegularDescription
    api.getFormattedScores = function (selection, tsSelection)
    {
        tsSelection = tsSelection || api.teamSwapSelection(selection);
        var score1 = tsSelection.Score1.toString(),
            score2 = tsSelection.Score2.toString(),
            tennisScore;

        if (selection.BranchID == 6)
        {
            /*global TennisScore */
            tennisScore = new TennisScore(score1, score2);
            score1 = tennisScore.ScoreSetsPlayer1;
            score2 = tennisScore.ScoreSetsPlayer2;
        }

        return { Score1: score1, Score2: score2 };
    };

    // TQASelections.js - createQAPickLine
    api.getQAPickLine = function (selection)
    {
        var team1Name = selection.Team1Name,
            team2Name = selection.Team2Name,
            reversedLineName, fastMarket, result = "";

        if (selection.SplitType == 6 || selection.SplitType == 24)
        {
            // "TeamName, [...]"
            result += (selection.LineTypeID == 13 ? team1Name : team2Name) + ", ";
        }

        if (selection.EventTypeID == 161)
        {
            if (selection.LineTypeID == 1 || selection.LineTypeID == 3)
            {
                // "TeamName[ (Virtual)]"
                return api.getFullPickLineValue(selection, selection.LineTypeID == 1 ? team1Name : team2Name);
            }

            // "[TeamName, ]LineName"
            return result + selection.LineName;
        }

        if (selection.IsTeamSwapEnabled && (selection.EventTypeID == 60 || selection.EventTypeID == 310))
        {
            reversedLineName = selection.LineName.split(":").reverse().join(":");
            // "[TeamName, ]Line2:Line1"
            return result + reversedLineName;
        }

        /*globals isFastMarket, isTennisFastMarket, SplitTypeFactory */
        if (isFastMarket(selection.SplitType) || isTennisFastMarket(selection.SplitType))
        {
            fastMarket = SplitTypeFactory.Create(selection.SplitType); //new FastMarket(this.SplitType, this.EventTypeName);
            // "[TeamName, ]FastMarketName"
            return result + fastMarket.buildPickLine(selection, selection.EventTypeName);
        }    
        // "[TeamName, ]LineName[ (Virtual)]"
        return result + api.getFullPickLineValue(selection, selection.LineName);
    };

    api.get3WayHandicapPickLine = function (selection)
    {
        var index = selection.LineName.indexOf('(');

        return index >= 0 ? selection.LineName.substring(0, index) : this.LineName;
    }

    api.getGoalscorerPickLine = function (selection)
    {
        return selection.LineName + " - " + selection.LineTypeName;
    }

    // TQASelections.js - createQAOddLine
    function getFormattedAlernateLineOdds(selection)
    {
        /*globals Lines */
        if (Lines.isTypeSP(selection.AlternateLineTypeID)) { return $dict.bs("SP"); }

        /*globals currentOddStyle */
        var alternativeOddsStyle = uniSlip.getAlternativeOddsStyle() || currentOddStyle;

        return Odds.convertFormat(
            selection.AlternateLineOdds,
            selection.EventTypeID,
            selection.BetTypeID,
            undefined, // no clear
            alternativeOddsStyle,
            selection.BetType,
            selection.BranchID,
            selection.AlternateLineFractOdds);
    }

    // TQASelections.js - createQAOddLine
    api.hasAlternateLine = function (selection)
    {
        return selection.AlternateLineID && selection.AlternateLineTypeID && !uniSlip.isBetInProcess;
    };

    // TQASelections.js - createQAOddLine
    api.getSPOptions = function (selection)
    {
        var formattedOdds = selection.getFormatedOdds();
        if (selection.isSPSelection() || (selection.Odds === 0 && selection.isToteEvent()))
        {
            formattedOdds = selection.getOddsAbbreviation();
        }
        var alternateOdds = getFormattedAlernateLineOdds(selection);

        if (selection.isSPSelection())
        {
            return {
                spSelected: true,
                first: alternateOdds,
                second: formattedOdds
            };
        }

        return {
            spSelected: false,
            first: formattedOdds,
            second: alternateOdds
        };
    };

    // TQASelections.js - createQADescription
    api.teamSwapQASelection = api.teamSwapSelection;

    // TQASelections.js - createOutrightQADescription
    api.getOutrightQADescription = function (selection)
    {
        if (selection.EventName.indexOf($string("shoppingCart").LineType) === -1)
        {
            if (selection.EventName.indexOf(selection.LeagueName) === -1)
            {
                return selection.LeagueName + " - " + (selection.EventName || selection.LineTypeName);
            }

            return selection.EventName;
        }

        return selection.LeagueName;
    };

    // TQASelections.js - createQADescription
    /* jshint maxcomplexity: 23*/
    function getPreliveQALineEventTypeName(selection, team1Name, team2Name)
    {
        if (selection.EventTypeID == 8) { return ""; }

        if (isFastMarket(selection.SplitType) || isTennisFastMarket(selection.SplitType)) {
            var fastMarket = SplitTypeFactory.Create(selection.SplitType);
            return fastMarket.buildEventNameForBet(selection.LineTypeName, selection.EventTypeName, selection);
        }

        /*globals RacingEventTypes */
        if (selection.EventTypeID == RacingEventTypes.RaceCard)
        {
        	var toReturn = "";
			
        	if (selection.LineTypeID == 18 || selection.LineTypeID == 19)
        	{
        		toReturn = $string("General").Antepost + " ";
        	}

        	toReturn += $dict.bs("Win");
        	return toReturn;
        }

        if (selection.EventTypeID == RacingEventTypes.BettingWithoutFav || selection.EventTypeID == RacingEventTypes.BettingWithoutTwoFav || 
            selection.EventTypeID == RacingEventTypes.PlaceOnly || selection.EventTypeID == RacingEventTypes.MatchBetting)
        {
            return "{0} - {1}".format(selection.EventTypeName, selection.LineTypeName);
        }

        if (selection.EventTypeID == 396)
        {
            if (selection.LineTypeID == 22) { return $dict.bs("Win"); }

            /*globals Bets */
            if (selection.LineTypeID == 23) { return Bets.getPlaceString(selection.PlaceTermsID); }
        }

        if (selection.EventTypeID == 89 ||
           (selection.EventTypeID == 341 && (selection.LineTypeID == 18 || selection.LineTypeID == 20))) { return selection.LineTypeName; }

        var eventTypeNameIDs = [82, 154, 158, 214, 215, 216, 217, 218, 341, 760], swapTeamEventTypeIDs;
        if (eventTypeNameIDs.indexOf(+selection.EventTypeID) !== -1) { return selection.EventTypeName; }

        if (selection.EventTypeID === RacingEventTypes.AUHRTote) {
            return $dict.bs("Win");
        }
        if (selection.EventTypeID === RacingEventTypes.AUHRTotePlaceOnly) {
            return $dict.bs("Place");
        }

        if (selection.LineTypeName !== selection.EventTypeName && selection.LineTypeName.indexOf($string("shoppingCart").LineType) === -1)
        {
            return selection.LineTypeName + " " + selection.EventTypeName;
        }

        swapTeamEventTypeIDs = [251, 253, 255, 260, 263, 265, 268];
        if (swapTeamEventTypeIDs.indexOf(selection.EventTypeID) !== -1) { return selection.EventTypeName.replace("Team A", team1Name); }

        swapTeamEventTypeIDs = [252, 254, 256, 261, 264, 266, 269];
        if (swapTeamEventTypeIDs.indexOf(selection.EventTypeID) !== -1) { return selection.EventTypeName.replace("Team B", team2Name); }

        return selection.EventTypeName;
    }

    // TQASelections.js - createQADescription
    api.getQALineEventTypeName = function (selection, team1Name, team2Name)
    {
        var result = getPreliveQALineEventTypeName(selection, team1Name, team2Name);

        if (isTennisFastMarket(selection.SplitType)) {
            return result;
        }

        if (selection.Live && selection.EventTypeName != "Live Betting" && selection.LeagueName != "Live Betting")
        {
            result += " " + $string("General").Live;
        }

        return result;
    };

    api.getSelectionInlineMessage = function (mode, selection)
    {
        if (this.isValidBetInProcess(mode, selection))
        {
            return { Class: "positive", Text: $dict.bs("BetInProcess") };
        }

        // if the selection is MultiLineItem, get the error from its first sub selection.
        if (!mode.Errors[selection.ViewKey] && selection instanceof MultiLineItem && (selection.ViewKey in mode.Errors) && selection.Selections)
        {
            var errorText = "";
            var selectionKeys = Object.keys(selection.Selections);
            for (var key = 0; key < selectionKeys.length; key = key + 1)
            {
                var subSelectionID = selection.Selections[selectionKeys[key]];
                if (mode.Errors[subSelectionID])
                {
                    errorText = mode.Errors[subSelectionID];
                    return {
                        Class: "negative",
                        Text: errorText
                    };
                }
            }
        }

        return {
            Class: "negative",
            Text: selection.Error || (mode.Errors && mode.Errors[selection.ViewKey])
        };
    };

    api.isValidBetInProcess = function (mode, selection)
    {
        return !mode.IsUKBetSlip && mode.IsBetInProcess && selection.Valid && !selection.Error &&
            (mode.Errors && !mode.Errors[selection.ViewKey]) &&
            (mode.Deposits && mode.Deposits[selection.ViewKey] > 0);
    };

    // TQASelections.js - createQADescription
    api.getEachWayString = function (selection)
    {
        return Bets.getEachWayString(selection.PlaceTermsID, selection.OddsTermsID, false);
    };

    // T*Selections.js - buildView
    api.getWrapperClasses = function (mode, selection, addBranchClass) {
        var classes = [];

        addBranchClass && selection.BranchID && classes.push('b_' + selection.BranchID);

        if ((!Modes.isSingleMode(mode) || IsUKBetSlip) && selection.isNoComboAllowed) {
            classes.push("same__event");
        }

        // TODO - add case where "show_odds_msg" is added - Monov

        return classes.join(" ");
    };

    api.isEachwayIncluded = function (mode, selection, context)
    {
        if (!Modes.isSingleMode(mode) && context.isPurchase)
        {
            return selection.IsEachWaySelection;
        }
      
        return IsUKBetSlip ? selection.EachWaySingleIncluded : selection.EachWayIncluded;
    };
    
    api.getMinSelectionsMessage = function (mode)
    {
        if (Modes.isComboNoCombinationSingleMode(mode))
        {
            return "";
        }

        return $dict.bs("ComboMinSelections");
    };

    api.displaySingleToReturnValue = function (mode)
    {
        return typeof SingleSPSlipMode !== 'undefined' && mode instanceof SingleSPSlipMode ||
               typeof UKSPSlipMode !== 'undefined' && mode instanceof UKSPSlipMode;
    };
    // private
    api._getFormattedAlernateLineOdds = getFormattedAlernateLineOdds;

    /*global BetSlip */
    return api;
}(BetSlip));

// TODO - Fix this insane global definition/usage on website's side
/*exported getTeamNameExtension */
var getTeamNameExtension = function (ref)
{
    "use strict";
    return Selections.getFullPickLineValue(ref, "");
};

window.Selections = Selections;
window.getTeamNameExtension = getTeamNameExtension;
